import { stagePointError } from '.'
import { UnitPointType } from '../../../../../store/Itinerary'
import { AdjacentStagePointRule } from '../../../../../store/StageFile'

export const pointFollowingRestrictionStartIsValid: AdjacentStagePointRule = (currentPoint, nextPoint) => {
    // RS – can be followed by RF, WP
    if (currentPoint.type.unitPointType == UnitPointType.RestrictionStart) {
        switch (nextPoint.type.unitPointType) {
            case UnitPointType.RestrictionFinish:
            case UnitPointType.Waypoint:
                break
            default:
                return stagePointError(nextPoint, `RS must be followed by RF/WP - ${nextPoint.type.code} is invalid`)
        }
    }
}