import { stagePointError } from '.'
import { UnitPointType } from '../../../../../store/Itinerary'
import { AdjacentStagePointRule } from '../../../../../store/StageFile'

export const pointFollowingTimeControlIsValid: AdjacentStagePointRule = (currentPoint, nextPoint, currentStage, stages) => {
    // TC – can be followed by TF, QS, WP, TC, SS
    if (currentPoint.type.unitPointType == UnitPointType.TimeControl) {
        switch (nextPoint.type.unitPointType) {
            case UnitPointType.TransitFinish:
            case UnitPointType.QuietZoneStart:
            case UnitPointType.Waypoint:
            case UnitPointType.TimeControl:
            case UnitPointType.StageStart:
                break
            default:
                // Can be followed by Other point if last stage
                if (!(currentStage?.order == stages?.sort((a, b) => b.order - a.order)[0].order && nextPoint.type.unitPointType == UnitPointType.Other)) {
                    return stagePointError(nextPoint, `TC must be followed by TF/QS/WP/TC/SS - ${nextPoint.type.code} is invalid`)
                }
        }
    }
}