import CloseIcon from '@mui/icons-material/Close'
import { CircularProgress, IconButton, Paper, Stack, Typography } from '@mui/material'
import { useEffect, useLayoutEffect, useState } from 'react'
import { Rnd } from 'react-rnd'
import { useWindowSize } from '../../helper/useWindowSize'

interface Props {
    onClose: () => void
    height?: number
    heightChanged?: (height: number) => void
    loading?: boolean
    title: string
    minWidth: number
    uniqueId: string
    initialPosition?: number[]
    onPositionChange?: (p: number[]) => void
    zIndex?: number
    headerContent?: React.ReactElement
    children?: React.ReactNode
}

interface Size {
    width: number
    height: number
}

interface Position {
    x: number
    y: number
}

let timeout: NodeJS.Timeout

export const Window: React.FunctionComponent<Props> = (props) => {
    const { onClose, children, height, heightChanged, loading, title, minWidth, uniqueId, initialPosition, onPositionChange, zIndex, headerContent } = props

    const [size, setSize] = useState<Size>({ width: minWidth, height: height ?? 432 })
    const [position, setPosition] = useState<Position>({ x: initialPosition ? initialPosition[0] : -250, y: initialPosition ? initialPosition[1] : 64 })
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const windowSize = useWindowSize()

    useLayoutEffect(() => {
        //trigger a fake drag event here so that the dialog will stay in the window on window resize
        const targetNode = document.querySelector(`.${uniqueId}-header`)
      //  setPosition({ x: initialPosition ? initialPosition[0] : -250, y: initialPosition ? initialPosition[1] : 64 })
        // if (targetNode) {
        //     //--- Simulate a natural mouse-click sequence.
        //     triggerMouseEvent(targetNode, "mouseover")
        //     triggerMouseEvent(targetNode, "mousedown")
        //     triggerMouseEvent(document, "mousemove")
        //     triggerMouseEvent(targetNode, "mouseup")
        //     triggerMouseEvent(targetNode, "click")
        // } else console.log("*** Target node not found!")

        // function triggerMouseEvent(node, eventType) {
        //     const clickEvent = document.createEvent("MouseEvents")
        //     clickEvent.initEvent(eventType, true, true)
        //     node.dispatchEvent(clickEvent)
        // }
    }, [windowSize])

    useEffect(() => {
        if (height && heightChanged && height != size.height) {
            heightChanged(size.height)
        }
        if (onPositionChange) {
            onPositionChange([position.x, position.y])
        }
    }, [size, position])

    useEffect(() => {
        if (loading != undefined && loading == false) {
            timeout = setTimeout(() => {
                setIsLoading(loading)
            }, 800)
        } else if (loading != undefined) {
            setIsLoading(loading)
        }
        return () => clearTimeout(timeout)
    }, [loading])

    return (
        <Rnd default={{
            x: position.x,
            y: position.y,
            width: size.width,
            height: size.height,
        }}
            size={{ width: size.width, height: size.height, }}
            position={{ x: position.x, y: position.y }}
            onResizeStop={(e, direction, ref, delta, position) => {
                setSize({ width: ref.offsetWidth, height: ref.offsetHeight })
                setPosition({ x: position.x, y: position.y })
            }}
            onDragStop={(e, d) => {
                setPosition({ x: d.x, y: d.y })
            }}
            dragHandleClassName={`${uniqueId}-header`}
            bounds='#root'
            minWidth={minWidth}
            minHeight={245}
            style={{ zIndex: zIndex ?? 20 }}
        >
            <Paper elevation={4} sx={{
                zIndex: 9,
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                width: '100%',
                borderRadius: 1,
                background: `rgba(18,18,18,${CSS.supports('backdrop-filter', 'blur(40px)') ? '80%' : '95%'})`,
                px: '3px'
            }}>
                <div style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    height: '100%',
                    width: '100%',
                    backdropFilter: 'blur(40px)',
                    zIndex: -1
                }} />
                <div className={`${uniqueId}-header`} style={{
                    height: 36,
                    zIndex: 10,
                    borderRadius: '8px 8px 0 0',
                    display: 'flex',
                    justifyContent: 'space-between',
                    color: '#bdbdbd'
                }}>
                    <Stack sx={{ minWidth: '200px' }}>
                        {headerContent}
                    </Stack>
                    <Typography variant={'body1'} style={{ cursor: 'default', alignSelf: 'center' }}>{title}</Typography>
                    <Stack direction={'row'} justifyContent={'flex-end'} sx={{ minWidth: '200px' }}>
                        <div style={{ width: 32, height: 32, display: 'flex', justifyContent: 'center' }}>
                            {isLoading && <CircularProgress size={20} style={{ alignSelf: 'center' }} />}
                        </div>
                        <IconButton
                            onClick={onClose}
                            style={{ width: 32, height: 32, margin: '2px 8px 2px 2px' }}
                            size="large">
                            <CloseIcon style={{ color: '#bdbdbd' }} />
                        </IconButton>
                    </Stack>
                </div>
                <div style={{ borderBottom: 'hsl(0deg 0% 42%) 1px solid', marginLeft: -3, marginRight: -3 }} />
                {children}
            </Paper>
        </Rnd>
    )
}