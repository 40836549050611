import * as Core from '.'
import { Domain } from '../store/Event'
import { Person } from '../store/People'

export function requestPeople(skip: number, take: number, searchText: string, order: string, domain?: Domain): Promise<Person[]> {
    return Core.get<Person[]>(`/person/table?skip=${skip}&take=${take}&search=${searchText}&order=${order}&domain=${domain}`)
}

export function requestPersonList(domain?: Domain): Promise<Person[]> {
    return Core.get<Person[]>(`/person/list?domain=${domain}`)
}

export function savePerson(person: Person): Promise<number> {
    return Core.post<Person>(`/person/savePerson`, person)
}

export function requestPerson(personId: number): Promise<Response> {
    return Core.post<number>(`/person/get`, personId)
}