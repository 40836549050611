import { stagePointError } from '.'
import { UnitPointType } from '../../../../../store/Itinerary'
import { GroupedStagePointRule, SFStagePoint, StagePointError } from '../../../../../store/StageFile'

export const pointsBetweenStartAndFinishAreValid: GroupedStagePointRule = (stagePoints) => {
    // The following points can only occur between a start and finish of the same stage number – SP, CS, CF, RS, RF, ZP
    let stageStart: SFStagePoint | undefined = undefined
    let flyingFinish: SFStagePoint | undefined = undefined
    let releventPoints: SFStagePoint[] = []
    let stagePointErrors: StagePointError[] = []

    stagePoints.forEach(stagePoint => {
        switch (stagePoint.type.unitPointType) {
            case UnitPointType.StageStart:
                stageStart = stagePoint
                break
            case UnitPointType.FlyingFinish:
                flyingFinish = stagePoint
                break
            case UnitPointType.SplitPoint:
            case UnitPointType.ChicaneStart:
            case UnitPointType.ChicaneFinish:
            case UnitPointType.RestrictionStart:
            case UnitPointType.RestrictionFinish:
            case UnitPointType.ZeroSpeedPoint:
                releventPoints.push(stagePoint)
                break
        }
    })

    releventPoints.forEach(releventPoint => {
        if (!stageStart || !flyingFinish || (stageStart && flyingFinish && (stageStart.order >= releventPoint.order || flyingFinish.order <= releventPoint.order))) {
            stagePointErrors.push(stagePointError(releventPoint, `${releventPoint.type.code} must occur between a start and finish - Stage ${releventPoint.stageNumber}`))
        }
    })

    return stagePointErrors.length > 0 ? stagePointErrors : undefined
}