import { Box, SortDirection, SxProps, Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel, Theme, Tooltip } from '@mui/material'
import { styled } from '@mui/material/styles'
import * as React from 'react'

const PREFIX = 'BaseTable'

const classes = {
    altBg: `${PREFIX}-altBg`,
    cell: `${PREFIX}-cell`,
    headCell: `${PREFIX}-headCell`,
    headCellRight: `${PREFIX}-headCellRight`,
    headCellRightFinal: `${PREFIX}-headCellRightFinal`
}

const StyledTable = styled(Table)({
    [`& .${classes.altBg}`]: {
        background: '#2b2b2b'
    },
    [`& .${classes.cell}`]: {
        '& td': {
            whiteSpace: 'nowrap',
            padding: '2px 24px 2px 16px',
            borderBottomColor: 'rgb(81,81,81)',
        },
        '& .MuiTypography-body1': {
            fontSize: '0.875rem'
        },
        '& .MuiTableCell-alignRight': {
            padding: '2px 16px 2px 24px'
        }
    },
    [`& .${classes.headCell}`]: {
        background: '#151515',
        padding: `6px 24px 6px 16px`,
        whiteSpace: 'nowrap'
    },
    [`& .${classes.headCellRight}`]: {
        padding: `6px 16px 6px 24px`,
    },
    [`& .${classes.headCellRightFinal}`]: {
        paddingRight: `28px`,
    }
})

interface Props {
    headCells: Column[]
    bodyRows: BodyRow[]
    orderBy?: string
    orderByDirection?: SortDirection
    onChangeOrder?: (id: string) => void
    hoverBackgroundColour?: string
}

export interface BodyRow {
    key: string
    content: JSX.Element[]
    onClick?: () => void
    className?: string
    style?: SxProps<Theme>
    customBackground?: boolean
    selected?: boolean
    tooltip?: string
    disableHover?: boolean
}

export interface Column {
    order: number
    label: string
    id: string
    alignRight?: boolean
    sort?: boolean
    display: boolean | null
    hidden?: boolean
}

export const BaseTable: React.FunctionComponent<Props> = ({
    bodyRows, headCells, orderBy, orderByDirection, onChangeOrder, hoverBackgroundColour
}) => {
    const onSortClick = (id?: string) => {
        if (id && onChangeOrder)
            onChangeOrder(id)
    }

    return (
        <StyledTable
            sx={{
                background: '#202020',
                '.MuiTableRow-hover:hover': {
                    background: `${hoverBackgroundColour ?? '#050505'} !important`
                }
            }}
            size='small'
            stickyHeader
        >
            <TableHead>
                <TableRow>
                    {headCells.sort((a, b) => a.order > b.order ? 1 : -1).map((headCell, i) => (
                        <TableCell
                            key={headCell.id ?? headCell.label}
                            className={`${classes.headCell} ${headCell.alignRight ? classes.headCellRight : ''} ${headCell.alignRight && i == headCells.length - 1 ? classes.headCellRightFinal : ''}`}
                            sortDirection={orderBy === headCell.id ? orderByDirection : false}
                            align={headCell.alignRight ? 'right' : 'inherit'}
                        >
                            {headCell.sort ? <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderByDirection ? orderByDirection : undefined}
                                onClick={() => onSortClick(headCell.id)}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <span style={{
                                        border: 0,
                                        clip: 'rect(0 0 0 0)',
                                        height: 1,
                                        margin: -1,
                                        overflow: 'hidden',
                                        padding: 0,
                                        position: 'absolute',
                                        top: 20,
                                        width: 1
                                    }}>
                                        {orderByDirection === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </span>
                                ) : null}
                            </TableSortLabel>
                                : <Box mr={headCell.alignRight ? '-12px' : ''}>{headCell.label}</Box>
                            }
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {bodyRows.map((r, i) => {
                    return <Tooltip placement='left' title={r.tooltip ? r.tooltip : ''}>
                        <TableRow
                            key={r.key}
                            hover={!r.disableHover}
                            className={`${classes.cell} ${(i % 2 || r.customBackground || r.selected) ? '' : classes.altBg} ${r.className}`}
                            onClick={r.onClick}
                            sx={r.selected
                                ? { background: '#03a9f45e', ...r.style }
                                : r.style
                            }
                        >
                            {r.content.map((x, i) => {
                                if (headCells.find(y => y.id == x.key)) {
                                    return x
                                }
                            })}
                        </TableRow>
                    </Tooltip>
                })}
            </TableBody>
        </StyledTable>
    )
}
