import { Stack, Tooltip, Typography } from "@mui/material"
import { DateTime } from "luxon"
import { Coordinate } from 'ol/coordinate'
import { toLonLat } from "ol/proj"
import { CurrentResponse } from "openweathermap-ts/dist/types"
import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useInterval } from "../../helper/useInterval"
import { ApplicationState } from "../../store"

export const Weather: React.FC = () => {
    const latLon = useSelector((state: ApplicationState) => state.map.latLon)
    const ll = toLonLat(latLon ?? [0])
    const [weatherData, setWeatherData] = useState<CurrentResponse | null>(null)
    const [lastUpdated, setLastUpdated] = useState<string | null>(null)
    const [lastLocation, setLastLocation] = useState<Coordinate | undefined>(undefined)

    const onUpdate = () => {
        setLastLocation(latLon)
        setLastUpdated(DateTime.local().toISO())
        fetch(`https://api.openweathermap.org/data/2.5/weather?lat=${ll[1]}&lon=${ll[0]}&units=metric&appid=d71fcf3412674707690ac4b37b0b8884`)
            .then(response => response.json())
            .then(data => {
                console.log(data)
                if (data.cod != 400)
                    setWeatherData(data)
            })
    }

    useEffect(() => {
        if (latLon != undefined && weatherData == null)
            onUpdate()
    }, [latLon])

    useInterval(() => {
        if (latLon == undefined) return
        if (weatherData == null || lastUpdated == null || DateTime.fromISO(lastUpdated).diffNow('minutes').minutes < -1) {
            console.log('Updating weather because of lastUpdated')
            onUpdate()
        } else if (lastLocation != null && latLon != null && Math.abs(lastLocation[0] - latLon[0]) > 0.05 && Math.abs(lastLocation[1] - latLon[1]) > 0.05) {
            console.log('Updating weather because of location')
            onUpdate()
        }
    }, 10000)

    return (
        weatherData != null && weatherData != undefined ? <Tooltip title={weatherData.name} placement='right'>
            <Stack direction="row" ml={2} spacing={1} alignItems='center'>
                <img src={`http://openweathermap.org/img/w/${weatherData.weather[0]?.icon}.png`} />
                <Stack direction="column">
                    <Typography variant='h6' sx={{ lineHeight: 1.2, fontSize: '1.2rem' }}>{`${weatherData.main.temp.toFixed(1)}°C`}</Typography>
                    <Typography variant='caption' color='textSecondary'>{weatherData.weather[0]?.main}</Typography>
                </Stack>
            </Stack>
        </Tooltip>
            : null
    )
}
