import { stagePointError } from '.'
import { UnitPointType } from '../../../../../store/Itinerary'
import { AdjacentStagePointRule } from '../../../../../store/StageFile'

export const pointFollowingQuietZoneFinishIsValid: AdjacentStagePointRule = (currentPoint, nextPoint) => {
    // QF – can be followed by TC, TF, WP, SP, SS, QS   (SP is for Challenge and Winter Trial)
    if (currentPoint.type.unitPointType == UnitPointType.QuietZoneFinish) {
        switch (nextPoint.type.unitPointType) {
            case UnitPointType.TimeControl:
            case UnitPointType.TransitFinish:
            case UnitPointType.Waypoint:
            case UnitPointType.SplitPoint:
            case UnitPointType.StageStart:
            case UnitPointType.QuietZoneStart:
                break
            default:
                return stagePointError(nextPoint, `QS must be followed by QF/WP/SP/TC - ${nextPoint.type.code} is invalid`)
        }
    }
}