import { Box, useMediaQuery, useTheme } from '@mui/material'
import { useSelector } from 'react-redux'
import { ApplicationState } from '../../store'


export const SmallEventImageDisplay: React.FunctionComponent = () => {
    const { selectedEvent } = useSelector((state: ApplicationState) => state.event)
    const { username } = useSelector((state: ApplicationState) => state.identity)

    const theme = useTheme()
    const screenExtraSmall = useMediaQuery(theme.breakpoints.down('sm'))

    const isPublic = username == 'PublicUser'
    const url = selectedEvent?.smallImage

    if (!isPublic || !url || screenExtraSmall)
        return null

    return <Box sx={{ height: '64px', mr: 1 }}>
        <img src={url} height={64} style={{ objectFit: 'contain' }} />
    </Box>
}