import { stagePointError } from '.'
import { UnitPointType, GetLocationPointCode } from '../../../../../store/Itinerary'
import { GroupedStagePointRule, SFStagePoint, StagePointError } from '../../../../../store/StageFile'

export const pointsCannotBeBetweenStageStartAndFlyingFinish: GroupedStagePointRule = (stagePoints) => {
    // The following points can not occur between an SS and FF – TC, QS, QF
    let stageStart: SFStagePoint | undefined = undefined
    let flyingFinish: SFStagePoint | undefined = undefined
    let releventPoints: SFStagePoint[] = []
    let stagePointErrors: StagePointError[] = []

    stagePoints.forEach(stagePoint => {
        switch (stagePoint.type.unitPointType) {
            case UnitPointType.StageStart:
                stageStart = stagePoint
                break
            case UnitPointType.FlyingFinish:
                flyingFinish = stagePoint
                break
            case UnitPointType.TimeControl:
            case UnitPointType.QuietZoneStart:
            case UnitPointType.QuietZoneFinish:
                releventPoints.push(stagePoint)
                break
        }
    })

    releventPoints.forEach(releventPoint => {
        if (!stageStart || !flyingFinish || (stageStart && flyingFinish && (stageStart.order <= releventPoint.order && flyingFinish.order >= releventPoint.order))) {
            stagePointErrors.push(stagePointError(releventPoint, `${releventPoint.type.code} must not occur between a start and finish - Stage ${releventPoint.stageNumber}`))
        }
    })

    return stagePointErrors.length > 0 ? stagePointErrors : undefined
}