import { Typography } from "@mui/material"
import { FlexBox } from "../components/@Global/FlexBox"
import { useStickyState } from "./useStickyState"
import { } from 'ol'

type Props = {
    lat: number
    long: number
}

type DisplayType =
    'decimalDegrees' |
    'degreesDecimalMinutes' |
    'degreesMinutesSeconds' |
    'whatThreeWords'

export const LocationDisplay: React.FunctionComponent<Props> = ({ lat, long }) => {

    const [displayType, setDisplayType] = useStickyState<DisplayType>('decimalDegrees', `locationDisplay`)

    let latValue = lat.toString()
    let longValue = long.toString()

    if (displayType == 'degreesMinutesSeconds') {
        latValue = convertDDToDMS(lat, false)
        longValue = convertDDToDMS(long, true)
    }

    return <FlexBox style={{ alignItems: 'center' }}>
        <Typography variant='subtitle1' color='primary' style={{ marginRight: 4 }}>Location:</Typography>
        <Typography variant='subtitle1'>{latValue}, {longValue}</Typography>
    </FlexBox>
}

const convertDDToDMS = (dd, isLng) => {
    var dir = dd < 0
        ? isLng ? 'W' : 'S'
        : isLng ? 'E' : 'N'

    var absDd = Math.abs(dd)
    var deg = absDd | 0
    var frac = absDd - deg
    var min = (frac * 60) | 0
    var sec = frac * 3600 - min * 60
    // Round it to 2 decimal points.
    sec = Math.round(sec * 100) / 100
    return deg + "°" + min + "'" + sec + '"' + dir
}