import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import { Box, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, PaletteColor, PaletteMode, Stack } from '@mui/material'
import { lightBlue, red } from '@mui/material/colors'
import CssBaseline from '@mui/material/CssBaseline'
import Drawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import { createTheme, StyledEngineProvider, Theme, ThemeProvider } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import clsx from 'clsx'
import { SnackbarProvider, useSnackbar } from 'notistack'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { SASLogo } from '../../assets/Logos/SASLogo'
import { SASLogoText } from '../../assets/Logos/SASLogoText'
import { useStickyState } from '../../helper/useStickyState'
import { globalActionCreators } from '../../store/Global'
import Header from './Header'
import { divStyle } from './Images/Background'
import { SideMenu } from './SideMenu'
import { Dispatch } from 'redux'
import { BeamTabletTimeNotification } from './Notifications/BeamTabletTimeNotification'
import { PubNubProvider } from 'pubnub-react'
import { getPubnubUuid } from '../../helper/getPubnubUuid'
import PubNub from 'pubnub'
import { isMobile } from "react-device-detect"
import { ApplicationState } from '../../store'
import { SideMenuLink } from './SideMenuLink'

import { HelpDialog } from './HelpDialog'



declare module '@mui/material/styles' {
    interface BreakpointOverrides {
        xs: true
        xs2: true
        sm: true
        sm2: true
        sm3: true
        md: true
        md2: true
        lg: true
        lg2: true
        xl: true
        xl2: true
    }
    interface Palette {
        disabled: Palette['primary']
    }
    interface PaletteOptions {
        disabled?: PaletteOptions['primary']
    }
}
declare module '@mui/material/IconButton' {
    interface IconButtonPropsColorOverrides {
        disabled: true
    }
}

type Props = {
    children?: React.ReactNode
    isAuthenticated: boolean | null
}

const Layout: React.FunctionComponent<Props> = ({ children, isAuthenticated }) => {
    const [open, setOpen] = useStickyState<boolean>(true, 'drawerOpen')
    const [mobileOpen, setMobileOpen] = useState<boolean>(false)
    const [mode, setMode] = useState<PaletteMode>('dark')
    const hideFrame = useSelector((state: ApplicationState) => state.global.hideFrame)

    const dispatch: Dispatch<any> = useDispatch()
    const location = useLocation()

    const drawerWidth = 240

    const identityPages = ['/login', '/forgotpassword', '/resetpassword'].includes(location.pathname) || location.pathname.includes('pre-event-form')
    const popout = location.pathname.includes('popout')

    const getDesignTokens = (mode: PaletteMode) => ({
        palette: {
            mode,
            ...(mode === 'light'
                ? {
                    // palette values for light mode
                    primary: { main: lightBlue[700] },
                    secondary: { main: 'rgb(24, 24, 24)' },
                    disabled: {
                        main: '#646464'
                    },
                    divider: 'rgb(65,65,65)',
                    background: {
                        default: 'rgb(222, 222, 222)',
                        paper: 'rgb(244, 244, 244)',
                    },
                    text: {
                        primary: '#000',
                        secondary: 'rgb(24,24,24)'
                    },
                    error: {
                        main: red[600]
                    }
                }
                : {
                    // palette values for dark mode
                    //    primary: { main: 'rgb(65,174,246)' },
                    primary: { main: lightBlue[500] },
                    secondary: { main: 'rgb(255,255,255)' },
                    disabled: {
                        main: '#646464'
                    },
                    divider: 'rgb(65,65,65)',
                    background: {
                        default: 'rgb(18, 18, 18)',
                        paper: 'rgb(24, 24, 24)',
                    },
                    text: {
                        primary: '#fff',
                        secondary: 'rgb(190,190,190)'
                    },
                    error: {
                        main: red[600]
                    }
                }),
        },
    })

    const theme = React.useMemo(() => createTheme({
        ...getDesignTokens(mode),
        breakpoints: {
            values: {
                xs: 0,
                xs2: 384,
                sm: 540,
                sm2: 700,
                sm3: 876,
                md: 960,
                md2: 1070,
                lg: 1280,
                lg2: 1450,
                xl: 1920,
                xl2: 2400
            },
        },
        typography: {
            fontFamily: 'Roboto',
            body2: {
                fontSize: '0.875rem'
            },
            h4: {
                fontSize: '1.85rem'
            },
        },
        components: {
            MuiCssBaseline: {
                styleOverrides: {
                    '@global': {
                        '*::-webkit-scrollbar': {
                            width: 12,
                            height: 12
                        },
                        '*::-webkit-scrollbar-track': {
                            '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.3)',
                            borderRadius: 10,
                            backgroundColor: '#1d1d1d'
                        },
                        '*::-webkit-scrollbar-thumb': {
                            borderRadius: 10,
                            backgroundColor: '#656565',
                            '-webkit-box-shadow': 'inset 0 0 3px rgba(0,0,0,0.3)'
                        },
                        '*::-webkit-scrollbar-corner': {
                            backgroundColor: 'transparent'
                        }
                    }
                }
            },
            MuiContainer: {
                styleOverrides: {
                    root: {
                        padding: '16px',
                        height: '100%'
                    }
                },
                defaultProps: {
                    disableGutters: true
                }
            },
            MuiAppBar: {
                defaultProps: {
                    // elevation: 8
                },
                styleOverrides: {
                    root: {
                        background: 'rgb(33,33,33)'
                    }
                }
            },
            MuiPaper: {
                defaultProps: {
                    //    elevation: 2
                },
                styleOverrides: {
                    root: {
                        margin: '0 auto',
                        padding: 'inherit',
                        display: 'inline-block',
                        width: 'inherit'
                    }
                }
            },
            MuiCard: {
                styleOverrides: {
                    root: {
                        //      margin: '6px 10px 0 0',
                        //      verticalAlign: 'top',
                        padding: 0,
                        //       width: 318
                    }
                },
                defaultProps: {
                    elevation: 10
                }
            },
            MuiCardHeader: {
                styleOverrides: {
                    root: {
                        padding: '12px 16px 0'
                    }
                }
            },
            MuiCardContent: {
                styleOverrides: {
                    root: {
                        // paddingTop: 8
                    }
                }
            },
            MuiListItemIcon: {
                styleOverrides: {
                    root: {
                        paddingLeft: 4,
                        color: 'inherit'
                    }
                }
            },
            MuiTableCell: {
                styleOverrides: {
                    sizeSmall: {
                        //     padding: '5px 24px 5px 16px'
                    }
                }
            },
            MuiListSubheader: {
                styleOverrides: {
                    root: {
                        background: 'inherit'
                    }
                }
            },
            MuiInputLabel: {
                styleOverrides: {
                    root: {
                        '&.Mui-focused': {
                            color: 'rgb(0,170,244)',
                        }
                    }
                }
            },
            MuiInputBase: {
                styleOverrides: {
                    root: {
                        '&.Mui-focused:before': {
                            borderBottom: '2px solid rgb(0,170,244)',
                        },
                        '&.Mui-focused:after': {
                            borderBottom: '2px solid rgb(0,170,244) !important',
                        }
                    }
                }
            },
            MuiTabs: {
                defaultProps: {
                    variant: 'scrollable',
                    scrollButtons: "auto"
                },
                styleOverrides: {
                    root: {
                        minHeight: '40px'
                    }

                }
            },
            MuiTab: {
                styleOverrides: {
                    root: {
                        padding: '14px 22px',
                        minHeight: '40px'
                    }
                }
            },
            MuiSelect: {
                defaultProps: {
                    size: 'small'
                }
            },
            MuiFormControl: {
                defaultProps: {
                    size: 'small'
                }
            },
            MuiTooltip: {
                styleOverrides: {
                    tooltip: {
                        background: '#333',
                        color: '#fff',
                        fontSize: '12px',
                        border: '1px solid #555'
                    }
                }
            }
        }
    }), [mode])

    const fullSize = useMediaQuery(theme.breakpoints.up('md'))

    // const useStyles = makeStyles({

    //     appBarSpacer: theme.mixins.toolbar,

    //     paper: {
    //         padding: theme.spacing(2),
    //         display: 'flex',
    //         overflow: 'auto',
    //         flexDirection: 'column',
    //     },
    //     fixedHeight: {
    //         height: 240,
    //     },
    // })

    useEffect(() => {
        dispatch(globalActionCreators.setDrawerOpen(open))
    }, [])

    useEffect(() => {
        onMobileDrawerClose()
    }, [location])

    const handleDrawerOpen = () => {
        dispatch(globalActionCreators.setDrawerOpen(true))
        setOpen(true)
    }
    const handleDrawerClose = () => {
        dispatch(globalActionCreators.setDrawerOpen(false))
        setOpen(false)
    }
    const onMobileDrawerOpen = () => {
        setMobileOpen(true)
    }
    const onMobileDrawerClose = () => {
        setMobileOpen(false)
    }

    if (popout)
        return <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <Box id={'mainContent'}
                    sx={{
                        flexGrow: 1,
                        height: isMobile ? '93vh' : '100vh',
                        overflow: 'auto',
                        display: 'flex',
                        flexDirection: 'column',
                        margin: -1
                    }}
                >
                    {children}
                </Box>
            </ThemeProvider>
        </StyledEngineProvider>

    const header = !identityPages
        ? fullSize
            ? <Header
                onDrawerOpen={handleDrawerOpen}
                isOpen={open}
                drawerWidth={drawerWidth}
            />
            : <Header
                onDrawerOpen={onMobileDrawerOpen}
                isOpen={mobileOpen}
                drawerWidth={drawerWidth}
            />
        : null

    const drawer = !identityPages
        ? fullSize
            ? <Drawer
                variant="permanent"
                sx={{
                    display: { md: 'block', xs: 'none' },
                    width: open ? drawerWidth : theme.spacing(8),
                    flexShrink: 0,
                    whiteSpace: 'nowrap',
                    transition: theme.transitions.create('width', {
                        easing: theme.transitions.easing.sharp,
                        duration: theme.transitions.duration.enteringScreen,
                    }),
                    height: '100vh',
                    '& .MuiDrawer-paper': {
                        width: open ? drawerWidth : theme.spacing(8),
                        boxSizing: 'border-box',
                        overflowX: fullSize && !open ? 'hidden' : 'auto',
                        backgroundColor: '#222',
                        transition: theme.transitions.create('width', {
                            easing: theme.transitions.easing.sharp,
                            duration: theme.transitions.duration.shorter,
                        }),
                    }
                }}
                open={fullSize && open}
            >
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    padding: '0 8px',
                    ...theme.mixins.toolbar,
                }}>
                    <SASLogo height={32} style={{ paddingLeft: 12 }} />
                    <SASLogoText height={32} />
                    <IconButton onClick={handleDrawerClose} size="large">
                        <ChevronLeftIcon />
                    </IconButton>
                </Box>
                <SideMenu open={open} />
                <HelpDialog />
            </Drawer>
            : <Drawer
                variant="temporary"
                onClose={onMobileDrawerClose}
                open={!fullSize && mobileOpen}
                sx={{
                    width: mobileOpen ? drawerWidth : theme.spacing(8),
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        background: 'rgb(34,34,34)',
                        width: mobileOpen ? drawerWidth : theme.spacing(8),
                        boxSizing: 'border-box',
                    },
                }}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
            >
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    padding: '0 8px',
                    ...theme.mixins.toolbar,
                }}>
                    <IconButton onClick={onMobileDrawerClose} size="large">
                        <ChevronLeftIcon />
                    </IconButton>
                </Box>
                <SideMenu open={open} />
            </Drawer>
        : null

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <CssBaseline />
               
                    <SnackbarProvider
                        Components={{
                            beamTabletTime: BeamTabletTimeNotification
                        }}>
                        <Box sx={{ display: 'flex' }}>
                            {!hideFrame && header}
                            {!hideFrame && drawer}
                            <div style={{
                                flexGrow: 1,
                                width: 'calc(100% - 240px)'
                            }}>
                                <Box id={'mainContent'}
                                    sx={{
                                        flexGrow: 1,
                                        //  height: '100%',
                                        height: isMobile ? '93vh' : '100vh',
                                        overflow: 'auto',
                                        display: 'flex',
                                        flexDirection: 'column'
                                    }}
                                    style={identityPages ? divStyle : {}}
                                >
                                    {!hideFrame && <Box
                                        sx={theme.mixins.toolbar}
                                    />}
                                    {/* <ToggleButton
                                    value="check"
                                    selected={mode == 'dark'}
                                    onChange={() => {
                                        setMode(mode == 'dark' ? 'light' : 'dark')
                                    }}
                                >
                                    <CheckIcon />
                                </ToggleButton>  */}
                                    {children}
                                </Box>
                            </div>
                        </Box>
                    </SnackbarProvider>
            
            </ThemeProvider>
        </StyledEngineProvider>
    )
}

export default React.memo(Layout)
