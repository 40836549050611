import * as Core from '.'
import { SendPolyline } from '../components/Events/Itinerary/Polylines/PolylineDialog'
import { Polyline, PolylineBasic, Route, Stage, StageMap, StagePoint, UnitStagePoint } from '../store/Itinerary'
import { SFStage } from '../store/StageFile'

export function requestStages(eventId: number, includePolyline?: boolean): Promise<Stage[]> {
    return Core.get<Stage[]>(`/itinerary/stages?eventId=${eventId}${includePolyline ? '&includePolyline=' + includePolyline : ''}`)
}

export function createStage(stage: Stage): Promise<Response> {
    return Core.post(`/itinerary/createStage`, stage)
}

export function updateStage(stage: Stage): Promise<Response> {
    return Core.post<Stage>(`/itinerary/updateStage`, stage)
}

export function deleteStage(stage: Stage): Promise<Response> {
    return Core.del(`/itinerary/deleteStage`, stage.locationGroupId)
}

export function requestStagePoints(eventId: number): Promise<StagePoint[]> {
    return Core.get<StagePoint[]>(`/itinerary/stagePoints?eventId=${eventId}`)
}

export function requestStageMaps(eventId?: number, search?: string): Promise<StageMap[]> {
    if (eventId && !search) {
        return Core.get<StageMap[]>(`/itinerary/eventStageMaps${eventId ? '?eventId=' + eventId : ''}`)
    }
    return Core.get<StageMap[]>(`/itinerary/stageMaps${search ? '?search=' + search : ''}`)
}

export function requestUnitStagePoints(stageMapId: number): Promise<UnitStagePoint[]> {
    return Core.get<UnitStagePoint[]>(`/itinerary/unitStagePoints?stageMapId=${stageMapId}`)
}

export function saveEventClassStageMap(eventId: number, classId: number, stageMapId: number): Promise<any> {
    return Core.postEmpty(`/itinerary/saveEventClassStageMap?eventId=${eventId}&classId=${classId}&stageMapId=${stageMapId}`)
}

export function deleteEventClassStageMap(eventId: number, classId: number, stageMapId: number): Promise<any> {
    return Core.postEmpty(`/itinerary/deleteEventClassStageMap?eventId=${eventId}&classId=${classId}&stageMapId=${stageMapId}`)
}

export function changeDefaultEventStageMap(eventId: number, stageMapId: number): Promise<any> {
    return Core.postEmpty(`/itinerary/changeDefaultEventStageMap?eventId=${eventId}&stageMapId=${stageMapId}`)
}

export function requestPolylines(): Promise<PolylineBasic[]> {
    return Core.get<PolylineBasic[]>(`/itinerary/polylines`)
}

export function requestPolylinesTable(skip: number, take: number, searchText: string): Promise<PolylineBasic[]> {
    return Core.get<PolylineBasic[]>(`/itinerary/polylines-table?skip=${skip}&take=${take}&search=${searchText}`)
}

export function requestSuggestedPolylines(eventId: number): Promise<PolylineBasic[]> {
    return Core.get<PolylineBasic[]>(`/itinerary/suggested-polylines?eventId=${eventId}`)
}

export function requestPolyline(polylineId: number): Promise<Polyline> {
    return Core.get<Polyline>(`/itinerary/polyline?polylineId=${polylineId}`)
}

export function createPolyline(model: SendPolyline): Promise<Response> {
    return Core.post<SendPolyline>(`/itinerary/create-polyline`, model)
}

export function updatePolyline(model: SendPolyline): Promise<Response> {
    return Core.post<SendPolyline>(`/itinerary/update-polyline`, model)
}

export function createRoute(model: SendPolyline): Promise<Response> {
    return Core.post<SendPolyline>(`/itinerary/create-route`, model)
}

export function updateRoute(model: SendPolyline): Promise<Response> {
    return Core.post<SendPolyline>(`/itinerary/update-route`, model)
}

export function deletePolyline(model: Polyline): Promise<Response> {
    return Core.postEmpty(`/itinerary/delete-polyline?polylineId=${model.polyLineId}`)
}

export function deleteRoute(model: Polyline): Promise<Response> {
    return Core.postEmpty(`/itinerary/delete-route?routeId=${model.polyLineId}`)
}

export function requestRoutes(eventId: number): Promise<Route[]> {
    return Core.get<Route[]>(`/itinerary/routes?eventId=${eventId}`)
}

export function requestRoutesTable(eventId: number): Promise<Polyline[]> {
    return Core.get<Polyline[]>(`/itinerary/routes-table?eventId=${eventId}`)
}

export function updateItinerary(eventId: number, stages: SFStage[], wipeStages: boolean): Promise<Response> {
    return Core.post<SFStage[]>(`/itinerary/${eventId}/update-itinerary?wipeStages=${wipeStages}`, stages)
}

export function createStageMap(eventId: number, stages: SFStage[], name: string, assign: boolean, includeTcs: boolean): Promise<Response> {
    return Core.post<SFStage[]>(`/itinerary/${eventId}/create-stage-map?name=${name}&assign=${assign}&includeTcs=${includeTcs}`, stages)
}

export function wipeStagePoints(eventId: number): Promise<Response> {
    return Core.get(`/itinerary/${eventId}/wipe-stage-points`)
}
