import { Box, Button, Container, FormHelperText, Link, Paper, TextField, Typography } from '@mui/material'
import * as React from 'react'
import { connect } from 'react-redux'
import { resetPassword } from '../../api/Identity'
import { ApplicationState } from '../../store'
import * as IdentityStore from '../../store/Identity'
import { Link as RouterLink } from 'react-router-dom'

type LoginProps =
    IdentityStore.IdentityState
    & typeof IdentityStore.identityActionCreators

interface LoginState {
    userId: string | null
    token: string | null
    password: string
    errorText: string
    successfullText: string
}

class ResetPassword extends React.Component<LoginProps, LoginState> {
    constructor(props: LoginProps) {
        super(props)

        this.state = {
            userId: new URLSearchParams(window.location.search).get('userId'),
            token: new URLSearchParams(window.location.search).get('token'),
            password: '',
            errorText: '',
            successfullText: ''
        }
    }

    onPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ password: event.currentTarget.value })
    }

    onSubmit = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault()
        const { userId, token, password } = this.state
        if (password !== '' && userId && token) {
            resetPassword(userId, token, password)
                .then(result => {
                    result.json()
                        .then(result => {
                            this.setState({ successfullText: result.message })
                        })
                })
                .catch(err => {
                    this.setState({ errorText: err.message })
                })
        }
    }

    public render(): JSX.Element {
        const { errorText, successfullText } = this.state
        return <Container maxWidth='sm'>
            <Paper
                elevation={3}
                style={{
                    paddingBottom: 36,
                    margin: '0 auto',
                    textAlign: 'center'
                }}>
                <form style={{
                    maxWidth: 250,
                    margin: '0px auto',
                    lineHeight: 5,
                    textAlign: 'left'
                }}>
                    <Typography variant="h4" color="inherit" noWrap style={{ marginBottom: 20, marginTop: 10, fontSize: '1.8rem' }}>
                        Reset Password
                    </Typography>
                    {successfullText === ''
                        ? [
                            <TextField
                                label="Password"
                                required
                                placeholder='Enter new password'
                                type="password"
                                onChange={this.onPasswordChange}
                                fullWidth={true}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />,
                            <FormHelperText error={errorText != ''}>{errorText}</FormHelperText>,
                            <Button
                                onClick={this.onSubmit}
                                fullWidth={true}
                                variant='contained'
                                color='primary'
                                type='submit'
                            >
                                Submit
                            </Button>
                        ]
                        : <Box color="success.main" marginBottom={5}>
                            <Typography>
                                {successfullText}
                            </Typography>
                        </Box>
                    }
                </form>
                <Link
                    to='/login'
                    component={RouterLink}
                >
                    Back to log in
                </Link>
            </Paper>
        </Container>
    }
}

export default connect(
    (state: ApplicationState) => state.identity,
    IdentityStore.identityActionCreators
)(ResetPassword as any)
