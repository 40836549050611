import { Coordinate } from 'ol/coordinate'
import { Action, Reducer } from 'redux'
import { AppThunkAction } from '.'
import { Polyline, UnitPointType, WebPointType } from './Itinerary'
import * as Extent from 'ol/extent'
import { fromLonLat } from 'ol/proj'
import { cloneDeep, isEqual } from 'lodash'
import { deepEqual } from 'assert'
import { detectErrors } from '../components/Events/Itinerary/StageFile/Rules'

export enum RuleType {
    Error,
    Warning
}

export const getTriggerDistance = (type?: UnitPointType): number => {
    switch (type) {
        case UnitPointType.StageStart:
            return 20
        case UnitPointType.FlyingFinish:
            // boat: 270
            return 70
        case UnitPointType.TransitPoint:
            return 90
        case UnitPointType.TransitStart:
            return 90
        case UnitPointType.TransitFinish:
            return 90
        case UnitPointType.ZeroSpeedPoint:
            return 200
        case UnitPointType.ChicaneStart:
            return 95
        case UnitPointType.ChicaneFinish:
            return 95
        case UnitPointType.RestrictionStart:
            return 95
        case UnitPointType.RestrictionFinish:
            return 95
        case UnitPointType.QuietZoneStart:
            return 70
        case UnitPointType.QuietZoneFinish:
            return 95
        case UnitPointType.Waypoint:
            return 75
        case UnitPointType.SplitPoint:
            return 70
        case UnitPointType.TimeControl:
            return 20
        case UnitPointType.BuoyPoint:
            return 250
        case UnitPointType.SplitBoat:
            return 245
        case UnitPointType.BoatStart:
            return 250
        default:
            return 0
    }
}

export interface PointType {
    unitPointType?: UnitPointType
    code: string
}

export interface SFStagePoint {
    order: number
    stageNumber: number
    type: PointType
    latitude: number
    longitude: number
    data: number
    comment: string
    tcId?: number
}

export interface SFStage {
    number: number
    name: string
    order: number
    length: number
    stagePoints: SFStagePoint[]
    polyLine?: Polyline
    duplicateOfStage?: number
}

export const blankSFStage: SFStage = {
    number: 0,
    name: '',
    order: 0,
    length: 0,
    stagePoints: []
}

export interface StagePointError {
    stagePoint: SFStagePoint,
    ruleType: RuleType,
    ruleText: string
}

export interface PointState {
    order: number
    isVisible: boolean
    isSelected: boolean
}

export interface IndividualStagePointRule {
    (stagePoint: SFStagePoint): StagePointError | undefined
}

export interface GroupedStagePointRule {
    (stagePoints: SFStagePoint[], hasTimeControls?: boolean): StagePointError[] | undefined
}

export interface AdjacentStagePointRule {
    (currentPoint: SFStagePoint, nextPoint: SFStagePoint, currentStage?: SFStage, stages?: SFStage[]): StagePointError | undefined
}

export interface AllPointsRule {
    (stagePoints: SFStagePoint[]): StagePointError[] | undefined
}

export interface AdjacentStageRule {
    (currentStage: SFStage, nextStage: SFStage): StagePointError | undefined
}

export interface DuplicateStageRule {
    (firstStage: SFStage, secondStage: SFStage): StagePointError[] | undefined
}

export interface AllStagesRule {
    (stages: SFStage[]): StagePointError | undefined
}

export interface StageFileState {
    stages: SFStage[]
    previousStates: SFStage[][]
    nextStates: SFStage[][]
    extent?: Extent.Extent
    editMode: boolean
    pointStates: PointState[]
    toggleAllPointStates: boolean
    allStagePanelsExpanded: boolean
    showAutoPolylines: boolean
    showPolylines: boolean
    stagePointErrors: StagePointError[]
    loading: boolean
    editBoxOpen: boolean
}

interface UpdateStages { type: 'UPDATE_STAGES', stages: SFStage[], isImport: boolean }
interface UpdateExtent { type: 'UPDATE_EXTENT', extent: Extent.Extent }
interface ToggleEditMode { type: 'TOGGLE_EDIT_MODE' }
interface ToggleAutoPolylines { type: 'TOGGLE_AUTO_POLYLINES' }
interface TogglePolylines { type: 'TOGGLE_POLYLINES' }
interface UpdatePoint { type: 'UPDATE_POINT', point: SFStagePoint }
interface UpdateStage { type: 'UPDATE_STAGE', stage: SFStage }
interface UpdatePointStates { type: 'UPDATE_POINT_STATES', pointStates: PointState[] }
interface ToggleAllPointStates { type: 'TOGGLE_ALL_POINT_STATES', toggleAllPointStates: boolean }
interface SetAllStagePanelsExpanded { type: 'SET_ALL_STAGE_PANELS_EXPANDED', allStagePanelsExpanded: boolean }
interface ZoomToPoints { type: 'ZOOM_TO_POINTS' }
interface Undo { type: 'UNDO' }
interface Redo { type: 'REDO' }
interface DeleteSelected { type: 'DELETE_SELECTED' }
interface CloneSelected { type: 'CLONE_SELECTED' }
interface ClearStageFileState { type: 'CLEAR_STAGE_FILE_STATE' }
interface SetLoading { type: 'SET_LOADING', loading: boolean }
interface SetEditBoxOpen { type: 'SET_EDIT_BOX_OPEN', editBoxOpen: boolean }

export type KnownAction = SetEditBoxOpen | SetLoading | ClearStageFileState | UpdateStage | CloneSelected | DeleteSelected | Undo | Redo | TogglePolylines | ToggleAutoPolylines | ToggleEditMode | ZoomToPoints | SetAllStagePanelsExpanded | ToggleAllPointStates | UpdateStages | UpdateExtent | UpdatePoint | UpdatePointStates

export const stageFileActionCreators = {
    updateStages: (stages: SFStage[], isImport: boolean): AppThunkAction<KnownAction> => async (dispatch) => {
        dispatch({
            type: 'UPDATE_STAGES',
            stages: stages,
            isImport: isImport
        })
    },
    updateExtent: (extent: Extent.Extent): AppThunkAction<KnownAction> => async (dispatch) => {
        dispatch({
            type: 'UPDATE_EXTENT',
            extent: extent
        })
    },
    toggleEditMode: (): AppThunkAction<KnownAction> => async (dispatch) => {
        dispatch({
            type: 'TOGGLE_EDIT_MODE'
        })
    },
    toggleAutoPolylines: (): AppThunkAction<KnownAction> => async (dispatch) => {
        dispatch({
            type: 'TOGGLE_AUTO_POLYLINES'
        })
    },
    togglePolylines: (): AppThunkAction<KnownAction> => async (dispatch) => {
        dispatch({
            type: 'TOGGLE_POLYLINES'
        })
    },
    updatePoint: (point: SFStagePoint): AppThunkAction<KnownAction> => async (dispatch) => {
        dispatch({
            type: 'UPDATE_POINT',
            point: point
        })
    },
    updateStage: (stage: SFStage): AppThunkAction<KnownAction> => async (dispatch) => {
        dispatch({
            type: 'UPDATE_STAGE',
            stage: stage
        })
    },
    updatePointStates: (pointStates: PointState[]): AppThunkAction<KnownAction> => async (dispatch) => {
        dispatch({
            type: 'UPDATE_POINT_STATES',
            pointStates: pointStates
        })
    },
    toggleAllPointStates: (toggleAllPointStates: boolean): AppThunkAction<KnownAction> => async (dispatch) => {
        dispatch({
            type: 'TOGGLE_ALL_POINT_STATES',
            toggleAllPointStates: toggleAllPointStates
        })
    },
    setAllStagePanelsExpanded: (allStagePanelsExpanded: boolean): AppThunkAction<KnownAction> => async (dispatch) => {
        dispatch({
            type: 'SET_ALL_STAGE_PANELS_EXPANDED',
            allStagePanelsExpanded: allStagePanelsExpanded
        })
    },
    zoomToPoints: (): AppThunkAction<KnownAction> => async (dispatch) => {
        dispatch({
            type: 'ZOOM_TO_POINTS'
        })
    },
    undo: (): AppThunkAction<KnownAction> => async (dispatch) => {
        dispatch({
            type: 'UNDO'
        })
    },
    redo: (): AppThunkAction<KnownAction> => async (dispatch) => {
        dispatch({
            type: 'REDO'
        })
    },
    deleteSelected: (): AppThunkAction<KnownAction> => async (dispatch) => {
        dispatch({
            type: 'DELETE_SELECTED'
        })
    },
    cloneSelected: (): AppThunkAction<KnownAction> => async (dispatch) => {
        dispatch({
            type: 'CLONE_SELECTED'
        })
    },
    clearStageFileState: (): AppThunkAction<KnownAction> => async (dispatch) => {
        dispatch({
            type: 'CLEAR_STAGE_FILE_STATE'
        })
    },
    setLoading: (loading: boolean): AppThunkAction<KnownAction> => async (dispatch) => {
        dispatch({
            type: 'SET_LOADING',
            loading: loading
        })
    },
    setEditBoxOpen: (editBoxOpen: boolean): AppThunkAction<KnownAction> => async (dispatch) => {
        dispatch({
            type: 'SET_EDIT_BOX_OPEN',
            editBoxOpen: editBoxOpen
        })
    }
}

const unloaded: StageFileState = {
    stages: [],
    previousStates: [],
    nextStates: [],
    editMode: false,
    pointStates: [],
    toggleAllPointStates: false,
    allStagePanelsExpanded: false,
    showAutoPolylines: true,
    showPolylines: true,
    stagePointErrors: [],
    loading: false,
    editBoxOpen: false
}

const resetPointStates = (s?: SFStage[]) => {
    let newPointStates: PointState[] = []
    let flatStagePoints = s
        ? s.reduce((arr: SFStagePoint[], elem) => arr.concat(elem.stagePoints), [])
        : []

    flatStagePoints.forEach((p, i) => {
        newPointStates.push({ order: p.order, isSelected: false, isVisible: true })
    })
    return newPointStates
}

export const reducer: Reducer<StageFileState> = (state: StageFileState | undefined, incomingAction: Action): StageFileState => {
    if (state === undefined) return unloaded
    const action = incomingAction as KnownAction
    switch (action.type) {
        case 'UPDATE_STAGES': {
            let prev = [...state.previousStates]
            let newStages: SFStage[] = []
            if (state.stages.length > 0 || action.isImport) {
                prev.push(cloneDeep(state.stages))

                action.stages.forEach(s => {
                    let existingStage = state.stages.find(x => x.number == s.number)
                    if (existingStage && existingStage.polyLine)
                        s.polyLine = existingStage.polyLine
                    newStages.push(s)
                })
            } else {
                newStages = action.stages
            }

            return {
                ...state,
                stages: newStages,
                previousStates: prev,
                stagePointErrors: detectErrors(newStages)
            }
        }
        case 'UPDATE_EXTENT': {
            return {
                ...state,
                extent: action.extent
            }
        }
        case 'TOGGLE_EDIT_MODE': {
            return {
                ...state,
                editMode: !state.editMode
            }
        }
        case 'TOGGLE_AUTO_POLYLINES': {
            return {
                ...state,
                showAutoPolylines: !state.showAutoPolylines
            }
        }
        case 'TOGGLE_POLYLINES': {
            return {
                ...state,
                showPolylines: !state.showPolylines
            }
        }
        case 'UPDATE_POINT': {
            let newStages = [...state.stages]
            let stage = newStages.find(x => x.number == action.point.stageNumber)
            if (!stage) return state
            let point = stage.stagePoints.find(x => x.order == action.point.order)
            if (!point || !action.point) return state
            let prev = [...state.previousStates]
            let next = [...state.nextStates]
            if (!isEqual(prev[prev.length - 1], state.stages)) {
                prev.push(cloneDeep(state.stages))
                next = []
            }

            let newPoints = [...stage.stagePoints.filter(x => x.order != point?.order), action.point]
            newPoints.sort((a, b) => a.order - b.order)
            stage.stagePoints = newPoints

            // Update any points in duplicate stages
            let finishIndex = stage.stagePoints.findIndex(x => x.type.unitPointType == UnitPointType.TransitPoint)
            let pointIndex = stage.stagePoints.findIndex(x => x.order == point?.order)
            if (pointIndex >= 0 && pointIndex <= finishIndex) {
                let duplicateStages = newStages.filter(x => x.number != action.point.stageNumber && x.duplicateOfStage == action.point.stageNumber)
                duplicateStages.forEach(s => {
                    let p = s.stagePoints[pointIndex]
                    p.latitude = action.point?.latitude ?? 0
                    p.longitude = action.point?.longitude ?? 0
                    p.data = p.type.unitPointType != UnitPointType.TimeControl ? (action.point?.data ?? 0) : p.data,
                        p.type = action.point?.type ?? { code: 'O' }
                    let newP = [...s.stagePoints.filter(x => x.order != p?.order), p]
                    newP.sort((a, b) => a.order - b.order)
                    s.stagePoints = newP
                })
            }

            return {
                ...state,
                stages: newStages,
                previousStates: prev,
                nextStates: next,
                stagePointErrors: detectErrors(newStages)
            }
        }
        case 'UPDATE_STAGE': {
            let newStages = [...state.stages.filter(x => x.order != action.stage.order)]
            let stage = state.stages.find(x => x.order == action.stage.order)
            if (!stage) return state
            let prev = [...state.previousStates]
            let next = [...state.nextStates]
            if (!isEqual(prev[prev.length - 1], state.stages)) {
                prev.push(cloneDeep(state.stages))
                next = []
            }

            let duplicateStages = newStages.filter(x => x.duplicateOfStage == action.stage.number)
            duplicateStages.forEach(s => {
                s.polyLine = action.stage.polyLine
                s.length = action.stage.length
            })

            let newStage = cloneDeep(action.stage)
            if (action.stage.duplicateOfStage != undefined) {
                let originalStage = newStages.find(x => x.number == action.stage.duplicateOfStage)
                if (originalStage) {
                    newStage.polyLine = originalStage.polyLine
                    newStage.length = originalStage.length
                }
            }

            newStages.push(newStage)
            newStages.sort((a, b) => a.order - b.order)
            return {
                ...state,
                stages: newStages,
                previousStates: prev,
                nextStates: next,
                stagePointErrors: detectErrors(newStages)
            }
        }
        case 'UPDATE_POINT_STATES': {
            let newPointStates = [...state.pointStates.filter(x => !action.pointStates.map(y => y.order).includes(x.order))]
            newPointStates = newPointStates.concat(action.pointStates)
            newPointStates.sort((a, b) => a.order - b.order)
            return {
                ...state,
                pointStates: newPointStates
            }
        }
        case 'TOGGLE_ALL_POINT_STATES': {
            return {
                ...state,
                toggleAllPointStates: action.toggleAllPointStates
            }
        }
        case 'SET_ALL_STAGE_PANELS_EXPANDED': {
            return {
                ...state,
                allStagePanelsExpanded: action.allStagePanelsExpanded
            }
        }
        case 'ZOOM_TO_POINTS': {
            let coords: Coordinate[] = []
            let flatStagePoints = state.stages.reduce(
                (arr: SFStagePoint[], elem) => arr.concat(elem.stagePoints), [])
            flatStagePoints.forEach((p, i) => {
                let pointState = state.pointStates.find(x => x.order == p.order) ?? { order: p.order, isSelected: false, isVisible: false }
                if (pointState.isVisible)
                    coords.push(fromLonLat([p.longitude, p.latitude]))
            })
            const newExtent = Extent.boundingExtent(coords)
            return {
                ...state,
                extent: coords.length > 0 ? newExtent : state.extent
            }
        }
        case 'UNDO': {
            let prev = [...state.previousStates]
            let newStages = prev.pop()
            let next = [...state.nextStates]
            next.push(cloneDeep(state.stages))

            return {
                ...state,
                previousStates: prev,
                stages: newStages ?? state.stages,
                nextStates: next,
                pointStates: resetPointStates(newStages),
                stagePointErrors: detectErrors(newStages ?? state.stages)
            }
        }
        case 'REDO': {
            let next = [...state.nextStates]
            let newStages = next.pop()
            let prev = [...state.previousStates]
            prev.push(cloneDeep(state.stages))

            return {
                ...state,
                previousStates: prev,
                stages: newStages ?? state.stages,
                nextStates: next,
                pointStates: resetPointStates(newStages),
                stagePointErrors: detectErrors(newStages ?? state.stages)
            }
        }
        case 'DELETE_SELECTED': {
            let newStages = [...state.stages]
            let selected = [...state.pointStates.filter(x => x.isSelected).map(x => x.order)]
            let newPointStates = state.pointStates.filter(x => !x.isSelected)
            let stateOrder = 1
            let newPointIndex = -1
            let originalStage = -1
            newPointStates.forEach(x => {
                x.order = stateOrder++
            })
            let prev = [...state.previousStates]
            let next = [...state.nextStates]
            if (!isEqual(prev[prev.length - 1], state.stages)) {
                prev.push(cloneDeep(state.stages))
                next = []
            }

            let newOrder = 1
            newStages.forEach(stage => {
                let newStagePoints: SFStagePoint[] = []
                stage.stagePoints.forEach((sp, i) => {
                    if (!selected.includes(sp.order)) {
                        if (!(stage.duplicateOfStage == originalStage && i == newPointIndex)) {
                            sp.order = newOrder++
                            newStagePoints.push(sp)
                        }
                    } else {
                        newPointIndex = i
                        originalStage = stage.number
                    }
                })
                stage.stagePoints = newStagePoints
            })

            return {
                ...state,
                previousStates: prev,
                stages: newStages ?? state.stages,
                nextStates: next,
                pointStates: newPointStates,
                stagePointErrors: detectErrors(newStages ?? state.stages)
            }
        }
        case 'CLONE_SELECTED': {
            let newStages = [...state.stages]
            let selected = [...state.pointStates.filter(x => x.isSelected).map(x => x.order)]
            let newPointStates: PointState[] = []
            let prev = [...state.previousStates]
            let next = [...state.nextStates]
            if (!isEqual(prev[prev.length - 1], state.stages)) {
                prev.push(cloneDeep(state.stages))
                next = []
            }

            let newPointIndex = -1
            let originalStage = -1
            let newOrder = 1
            newStages.forEach(stage => {
                let newStagePoints: SFStagePoint[] = []
                stage.stagePoints.forEach((sp, i) => {
                    sp.order = newOrder++
                    newStagePoints.push(sp)
                    newPointStates.push({ order: sp.order, isSelected: false, isVisible: true })
                    if (selected.includes(sp.order)) {
                        newPointIndex = i
                        originalStage = stage.number
                        let newPoint = cloneDeep(sp)
                        newPoint.order = newOrder++
                        newStagePoints.push(newPoint)
                        newPointStates.push({ order: newPoint.order, isSelected: false, isVisible: true })
                    }
                    if (stage.duplicateOfStage == originalStage && i == newPointIndex) {
                        let newPoint = cloneDeep(sp)
                        newPoint.order = newOrder++
                        newStagePoints.push(newPoint)
                        newPointStates.push({ order: newPoint.order, isSelected: false, isVisible: true })
                    }
                })
                stage.stagePoints = newStagePoints
            })

            newPointStates.forEach(x => {
                x.isSelected = false
            })

            return {
                ...state,
                previousStates: prev,
                stages: newStages ?? state.stages,
                nextStates: next,
                pointStates: newPointStates,
                stagePointErrors: detectErrors(newStages ?? state.stages)
            }
        }
        case 'CLEAR_STAGE_FILE_STATE': {
            return unloaded
        }
        case 'SET_LOADING': {
            return {
                ...state,
                loading: action.loading
            }
        }
        case 'SET_EDIT_BOX_OPEN': {
            return {
                ...state,
                editBoxOpen: action.editBoxOpen
            }
        }
        default:
            return state
    }
}
