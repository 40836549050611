import { Autocomplete, TextField } from '@mui/material'
import * as React from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import '../../../helper/stringExtensions'
import { ApplicationState } from '../../../store'
import { Championship, championshipActionCreators } from '../../../store/Championship'
import { Dispatch } from 'redux'

type Props = {
    selectedChampionships: Championship[]
    onChange: (championships: Championship[]) => void
    limit?: boolean
}

export const ChampionshipMultiSelect: React.FunctionComponent<Props> = ({ selectedChampionships, limit, onChange }) => {
    const championships = useSelector((state: ApplicationState) => state.championship.championships)

    const _selectedChampionships = championships
        .filter(x => selectedChampionships
            .map(y => y.championshipId)
            .includes(x.championshipId))

    const dispatch: Dispatch<any> = useDispatch()

    useEffect(() => {
        dispatch(championshipActionCreators.requestChampionships())
    }, [])

    return (
        <Autocomplete
            sx={{ minWidth: '200px' }}
            size={'small'}
            multiple
            value={_selectedChampionships}
            limitTags={limit ? 4 : -1}
            groupBy={(option) => option.year.toString()}
            onChange={(e, v) => onChange(v)}
            options={championships}
            getOptionLabel={(option) => option.code}
            renderInput={(params) => (
                <TextField {...params} label='Championships' />
            )}
        />
    )
}