import { Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useInterval } from '../../helper/useInterval'

export const Memory: React.FunctionComponent = () => {
    const [memory, setMemory] = useState<number>(0)
    const [limit, setLimit] = useState<number>(0)

    useInterval(() => {
        measureMemory()
    }, 1000, true)

    const measureMemory = async () => {
        const memoryInfo = (window.performance as any).memory
        setMemory(memoryInfo.totalJSHeapSize)
        setLimit(memoryInfo.jsHeapSizeLimit)
    }

    return <Typography color={'white'}>{`${formatBytes(memory)}/${formatBytes(limit)}`}</Typography>
}

function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}