import { FormControl, InputLabel, MenuItem, Select } from "@mui/material"
import * as React from 'react'
import { Domain } from '../../../store/Event'

interface Props {
    onChange: (v: Domain) => void
    value: Domain
    disabled?: boolean
}

export const DomainSelect: React.FunctionComponent<Props> = ({ value, onChange, disabled }) => {
    return <FormControl sx={{ minWidth: 150 }}>
        <InputLabel>Domain</InputLabel>
        <Select
            value={value}
            disabled={disabled}
            onChange={(e) => {
                onChange(e.target.value as Domain)
            }}
            autoWidth
            label='Domain'
        >
            <MenuItem value={Domain.RallySafe}>RallySafe</MenuItem>
            <MenuItem value={Domain.RaceSafeH2O}>RaceSafe H2O</MenuItem>
            <MenuItem value={Domain.RallySafeLite}>RallySafe Lite</MenuItem>
            <MenuItem value={Domain.RallySafeRecce}>RallySafe Recce</MenuItem>
        </Select>
    </FormControl>
}