import * as React from 'react'
import image from './Images/RallySafe-Logo.png'

interface Props {
    height: number
    style?: React.CSSProperties
}

export const RallySafeLogo: React.FunctionComponent<Props> = (props) => {

    return <img
        src={image}
        alt="RallySafe Logo"
        height={props.height}
        style={props.style}
    />
}
