import { Tooltip, Typography } from '@mui/material'
import { DateTime, Duration, Settings } from 'luxon'

export enum TimingPrecision {
    Minute,
    Second,
    Tenth,
    Thousandth
}

export const toServerFormatString = (time: DateTime) => {
    return `${time.toUTC().toFormat('yyyy-MM-dd\'T\'HH:mm:ss')}Z`
}

export const getZoneName = (zone: number) => {
    let zoneName = 'utc'

    zoneName = `UTC${zone > -0.5 ? '+' : ''}`
    zoneName += Math.floor(zone)
    if (zone % 1 == 0.5) {
        zoneName += ':30'
    } else if (zone % 1 == 0.75) {
        zoneName += ':45'
    }

    return zoneName
}

export const getLuxonTimeFromString = (d: string, zone?: number, newZone?: number) => {
    if (!d || d == null || d == '') {
        return DateTime.now()
    }

    let timeString = d.toString()
    let zoneName = 'utc'
    if (zone != undefined) {
        zoneName = getZoneName(zone)
    }
    if (timeString.length > 22 && !timeString.includes('+') && !timeString.endsWith('Z') && !zone) {
        timeString = `${timeString}Z`
    }
    let originalDateTime = DateTime.fromISO(`${timeString}`, { zone: zoneName })
    if (newZone != undefined)
        return DateTime.fromISO(originalDateTime.toISO() ?? '', { zone: getZoneName(newZone) })
    return originalDateTime
}

export const getLuxonTimeFromTicks = (d: number) => {
    let date = DateTime.fromObject({ year: 1, month: 1, day: 1 }, { zone: 'utc' })
    if (!d || d == 0) {
        return date
    }

    return date.plus({ milliseconds: d / 10000 })
}

export const getStringFromDuration = (d: Duration, precision: TimingPrecision, fixedSize?: boolean, showDays?: boolean, round?: boolean) => {
    if (d.valueOf() == 0) return ''
    let hours = fixedSize || d.as('hours') >= 1 ? 'hh:' : ''
    let minutes = fixedSize || d.as('minutes') >= 1 ? 'mm:' : ''
    let days = showDays ? `d'd' ` : ''

    switch (precision) {
        case TimingPrecision.Minute:
            return d.toFormat(`${days}${hours}${minutes}`)
        case TimingPrecision.Second:
            return d.toFormat(`${days}${hours}${minutes}ss`)
        case TimingPrecision.Tenth:
            if (round) {
                let milli = d.get('millisecond')
                d = Duration.fromMillis(Math.round(milli / 100) * 100)
            }
            return d.toFormat(`${days}${hours}${minutes}ss.SSS`).slice(0, -2)
        case TimingPrecision.Thousandth:
            return d.toFormat(`${days}${hours}${minutes}ss.SSS`)
    }
}

export const getStringFromDateTime = (d: DateTime, precision: TimingPrecision, round?: boolean) => {
    if (!d.isValid) return ''
    let seconds = d.second != 0 ? ':ss' : ''

    switch (precision) {
        case TimingPrecision.Minute:
            return d.toFormat(`HH:mm${seconds}`)
        case TimingPrecision.Second:
            return d.toFormat(`HH:mm:ss`)
        case TimingPrecision.Tenth:
            if (round) {
                let milli = d.toMillis()
                d = DateTime.fromMillis(Math.round(milli / 100) * 100)
            }
            return d.toFormat(`HH:mm:ss.SSS`).slice(0, -2)
        case TimingPrecision.Thousandth:
            return d.toFormat(`HH:mm:ss.SSS`)
    }
}

export const getJsDateWithOffset = (d: Date | undefined) => {
    if (!d) {
        return undefined
    }
    return new Date(DateTime.fromISO(`${d.toString()}`).plus({ minutes: DateTime.local().offset }).toMillis())
}

export const getCurrentRealTime = (deltaMs: number, timeZoneName?: string) => {
    let realTime = DateTime.utc().plus({ milliseconds: deltaMs })
    if (timeZoneName) {
        realTime.setZone(timeZoneName)
    }
    return realTime
}

type Props = {
    isoDateTime: string,
    precision: TimingPrecision,
    offset?: number,
    tooltipPlacement?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top'
}

export const DateTimeDisplay: React.FunctionComponent<Props> = ({ isoDateTime, precision, tooltipPlacement, offset }) => {
    let dateTime = isoDateTime ? getLuxonTimeFromString(isoDateTime).plus(Duration.fromMillis((offset ?? 0) * 3600000)) : undefined
    return dateTime
        ? <Tooltip title={dateTime.toFormat('EEEE, dd MMM')} placement={tooltipPlacement ?? 'left'} >
            <Typography>{dateTime ? getStringFromDateTime(dateTime, precision) : ''} </Typography>
        </Tooltip>
        : null
}
