import { Autocomplete, TextField } from "@mui/material"
import { DateTime } from "luxon"
import * as React from 'react'
import { useEffect, useState } from "react"
import { getDays, HazardEvent } from "../../Events/Hazards/Models"

interface Props {
    onChange: (day: DateTime | null) => void
    value: DateTime | null
    label?: string
    hazardEvents: HazardEvent[]
}

export const DaySelect: React.FunctionComponent<Props> = (props) => {
    const { onChange, label, value, hazardEvents } = props

    const [days, setDays] = useState<DateTime[]>([])

    useEffect(() => {
        let _days = getDays(hazardEvents)
        setDays(_days)
    }, [hazardEvents.length])

    if (days.length < 1) return null

    days.sort((a, b) => a.toMillis() > b.toMillis() ? 1 : -1)

    return <Autocomplete
        sx={{ width: '204px' }}
        options={days}
        autoHighlight
        size={'small'}
        value={value}
        onChange={(e, v) => { onChange(v ?? null) }}
        getOptionLabel={(option) => option && option != null ? option.toFormat('EEEE, MMM d') : ''}
        renderInput={(params) => (
            <TextField
                {...params}
                label='Day'
                inputProps={{
                    ...params.inputProps,
                }}
            />
        )}
    />
}