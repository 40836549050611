import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormGroup, FormLabel, IconButton, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import FilterListIcon from '@mui/icons-material/FilterList'
import { useState, ReactElement } from 'react'


type Props = {
    children?: React.ReactNode
    extraFields?: ReactElement[]
    onlyChildren?: boolean
}

export const Filters: React.FunctionComponent<Props> = ({ children, extraFields, onlyChildren } ) => {

    const theme = useTheme()
    const screenSmall = useMediaQuery(theme.breakpoints.down('md'))

    const [open, setOpen] = useState(false)

    const onOpen = () => {
        setOpen(true)
    }

    const onClose = () => {
        setOpen(false)
    }

    const mobileView = <Box>
        <Button
            startIcon={<FilterListIcon />}
            onClick={onOpen}
            sx={{ height: '48px' }}
        >
            Filters
        </Button>
        {/* <Stack direction='row'>
            <Typography variant='h6'>Filters</Typography>
            <IconButton
                onClick={onOpen}
                // style={{ position: 'absolute', right: 30, top: topOffset ? topOffset : 77 }}
                size="large">
                <FilterListIcon />
            </IconButton>
        </Stack> */}

        <Dialog
            fullScreen={screenSmall}
            open={open}
            onClose={onClose}
        >
            <DialogTitle>{'Filters'}</DialogTitle>
            <DialogContent sx={{ ...{ '& .MuiFormControl-root': { my: 2 } }, minWidth: '256px', mb: 1 }} >
                <Stack spacing={2}>{children}</Stack>
                {extraFields}
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={onClose} color="primary">
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    </Box>

    return screenSmall
        ? mobileView
        : onlyChildren && children
            ? children as ReactElement
            : <Stack spacing={2} direction={'row'} flexWrap='wrap'>{children}</Stack>
}