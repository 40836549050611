import { stagePointError } from '.'
import { UnitPointType } from '../../../../../store/Itinerary'
import { GroupedStagePointRule, SFStagePoint } from '../../../../../store/StageFile'

export const stageStartHasMatchingFlyingFinish: GroupedStagePointRule = (stagePoints) => {
    let stageStart: SFStagePoint | undefined
    let flyingFinish: SFStagePoint | undefined

    stagePoints.forEach(stagePoint => {
        // Every SS should be matched by a finish (SF or F1/F2)
        if (stagePoint.type.unitPointType == UnitPointType.StageStart) {
            stageStart = stagePoint
        }
        if (stagePoint.type.unitPointType == UnitPointType.FlyingFinish) { //|| stagePoint.Type == UnitPointType.FinishLinePoint1) {
            flyingFinish = stagePoint
        }
    })
    if (stageStart && (!flyingFinish || (flyingFinish && flyingFinish.order <= stageStart.order))) {
        return [stagePointError(stageStart, `Every SS must be matched by a SF`)]
    } else {
        return undefined
    }
}