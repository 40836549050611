import { closeSnackbar, enqueueSnackbar } from "notistack"
import { usePubNubHook } from "./usePubNubHook"
import { Stack, Typography, IconButton } from "@mui/material"
import { ClearIcon } from "@mui/x-date-pickers"
import HybridIcon from '../components/@Global/images/hybrid.png'

type Props = {
    selectedEventId?: number
}

export const PubNubContainer: React.FC<Props> = ({ selectedEventId }) => {

    const showHybridNotification = (details: string) => {
        enqueueSnackbar(
            <Stack direction="row" spacing={1} alignItems='center'>
                <img src={HybridIcon} style={{ width: 32, height: 32 }} />
                <Typography>{details}</Typography>
            </Stack>,
            {
                action: snackbarId => (
                    <Stack direction="row" spacing={1} alignItems='center'>
                        <IconButton
                            onClick={() => { closeSnackbar(snackbarId) }}>
                            <ClearIcon />
                        </IconButton>
                    </Stack>
                ),
                persist: true
            }
        )
    }


    usePubNubHook(showHybridNotification, selectedEventId)
    return null
}