import { Box, Stack, TableCell, Typography } from '@mui/material'
import { isNull } from 'lodash'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { requestHazards } from '../../../api/Hazard'
import { CountryFlagIcon } from '../../../assets/Icons/CountryFlagIcon'
import { LocationDisplay } from '../../../helper/LocationDisplay'
import { getLuxonTimeFromString } from '../../../helper/timeHelper'
import { useInterval } from '../../../helper/useInterval'
import { ApplicationState } from '../../../store'
import { getPersonFullName, getSafetyStatusText } from '../../../store/Entry'
import { Stage } from '../../../store/Itinerary'
import { FlexBox } from '../../@Global/FlexBox'
import { BaseTable, BodyRow } from '../../Tables/BaseTable'
import { Window } from '../../Tables/Window'
import { ManufacturerDisplay } from '../Entries/ManufacturerDisplay'
import { getSafetyStatusColor, Hazard, HazardEvent } from './Models'

type Props = {
    hazardEvent: HazardEvent
    //   entry: Entry
    stage: Stage
    onWindowClose: (id: number) => void
    initialHistoryBoxPosition: number[]
}

export const HazardHistory: React.FunctionComponent<Props> = ({ hazardEvent, stage, onWindowClose, initialHistoryBoxPosition }) => {
    const entry = useSelector((state: ApplicationState) => state.entry.entries.find(x => x.entryId == hazardEvent.entryId))

    const [loading, setLoading] = useState<boolean>(false)
    const [hazards, setHazards] = useState<Hazard[]>([])
    // const [entryDetailed, setEntryDetailed] = useState<Entry | undefined>()

    useInterval(() => {
        if (!loading) {
            getHazards()
        }
    }, 5000)

    useEffect(() => {
        getHazards()
    }, [hazardEvent])

    if (!entry) return null

    // const getEntryDetailed = () => {
    //     requestEntryDetailed(entry.entryId)
    //         .then(result => {
    //             setEntryDetailed(result)
    //         })
    //         .catch((err: string) => console.log(err))
    // }

    const getHazards = () => {
        setLoading(true)
        requestHazards(hazardEvent.hazardEventId)
            .then(result => {
                setHazards(result)
            })
            .catch((err: string) => console.log(err))
            .finally(() => setLoading(false))
    }



    let i = 0
    const xyz: number[] = []

    const bodyRows: BodyRow[] = hazards.map((row, index) => {
        if (!isNull(row.xForce) && row.xForce != xyz[0]) { xyz[0] = row.xForce }
        if (!isNull(row.yForce) && row.yForce != xyz[1]) { xyz[1] = row.yForce }
        if (!isNull(row.zForce) && row.zForce != xyz[2]) { xyz[2] = row.zForce }

        const bodyCells = [
            <TableCell key={`Timestamp`}>
                <Typography>{getLuxonTimeFromString(row.timestamp.toString()).toFormat('HH:mm:ss')}</Typography>
            </TableCell>,
            <TableCell key={`Status`}>
                <Typography>{getSafetyStatusText(row.hazardType)}</Typography>
            </TableCell>,
            <TableCell key={`gForce`}>
                <Typography>{row.gforce ? row.gforce + ' g' : ''}</Typography>
            </TableCell>,
            <TableCell key={`distance`}>
                <Typography>{row.distance}</Typography>
            </TableCell>,
            <TableCell key={`badGps`}>
                <Typography>{row.badLocation ? 'Bad' : 'Good'}</Typography>
            </TableCell>,
            <TableCell key={`SentBy`}>
                <Typography>{row.source}</Typography>
            </TableCell>,
        ]

        i++

        return {
            key: `${row.hazardId}`,
            content: bodyCells,
            style: {
                '& td': {
                    padding: '0 8px',
                    color: '#222',
                    background: getSafetyStatusColor(row.hazardType, row.gforce, 'light'),
                },
                '& .MuiTypography-root': {
                    fontSize: 17,
                    lineHeight: 1.6,
                    fontWeight: 600
                }
            }
        }
    })

    return <Window
        title={`Hazard History - ${entry.identifier} `}
        uniqueId={'h' + hazardEvent.hazardEventId.toString()}
        loading={loading}
        minWidth={710}
        onClose={() => onWindowClose(hazardEvent.hazardEventId)}
        initialPosition={initialHistoryBoxPosition}
        zIndex={21}
    >
        <Stack sx={{ display: 'contents' }}>
            {<FlexBox spaceBetween style={{ width: '100%', padding: 4 }}>
                <FlexBox column spaceBetween style={{ flex: 3 }}>
                    <FlexBox style={{ alignItems: 'center' }}>
                        <Typography variant='subtitle1' color='primary' style={{ marginRight: 4 }}>Stage: </Typography>
                        <Typography variant='subtitle1'> {stage.name}</Typography>
                    </FlexBox>
                    <LocationDisplay lat={hazardEvent.lat} long={hazardEvent.long} />

                    {xyz.length == 3
                        ? <FlexBox style={{ alignItems: 'center' }}>
                            <Typography variant='subtitle1' color='primary' style={{ marginRight: 4 }}>G-Force X,Y,Z:</Typography>
                            <Typography variant='subtitle1'>{` ${xyz[0]}, ${xyz[1]}, ${xyz[2]}`}</Typography>
                        </FlexBox>
                        : <FlexBox style={{ height: 28 }} />}
                </FlexBox>
                <FlexBox column style={{ flex: 2, alignItems: 'center' }}>
                    <FlexBox style={{ alignItems: 'center' }}>
                        <Typography variant='subtitle1' style={{ color: getSafetyStatusColor(hazardEvent.hazardType, hazardEvent.maxGforce) }}>
                            {getSafetyStatusText(hazardEvent.hazardType)}
                        </Typography>
                    </FlexBox>
                </FlexBox>
                <FlexBox column style={{ alignItems: 'flex-end', flex: 3 }}>
                    <Stack direction='row' width='100%' alignItems='center' justifyContent='flex-end' spacing={1}>
                        <Typography variant='subtitle1'>{getPersonFullName(entry.vehicle.driver)}</Typography>

                        <CountryFlagIcon size={32} code={entry.vehicle.driver?.countryCode ?? ''} />
                    </Stack>
                    <Stack direction='row' width='100%' alignItems='center' justifyContent='flex-end' spacing={1}>
                        <Typography variant='subtitle1'>{getPersonFullName(entry.vehicle.navigator)}</Typography>

                        <CountryFlagIcon size={32} code={entry.vehicle.navigator?.countryCode ?? ''} />
                    </Stack>
                    {entry.vehicle.make && <ManufacturerDisplay size={32} make={entry.vehicle.make} alignRight />}
                </FlexBox>
            </FlexBox>}
            <Box sx={{ overflow: 'auto' }}>
                <BaseTable
                    headCells={[
                        { order: 0, id: 'Timestamp', label: 'Timestamp', sort: false, display: null },
                        { order: 1, id: 'Status', label: 'Status', sort: false, display: null },
                        { order: 2, id: 'gForce', label: 'G-Force', sort: false, display: null },
                        { order: 3, id: 'distance', label: 'Distance', sort: false, display: null },
                        { order: 4, id: 'badGps', label: 'GPS', sort: false, display: null },
                        { order: 5, id: 'SentBy', label: 'Sent By', sort: false, display: null }
                    ]}
                    bodyRows={bodyRows}
                    orderBy={'Timestamp'}
                    orderByDirection={'desc'}
                />
            </Box>
        </Stack>
    </Window >
}