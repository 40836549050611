import { Box, Breakpoint, Container, Paper, Stack, SxProps, Typography, useMediaQuery, useTheme } from '@mui/material'
import * as React from 'react'
import { ReactElement } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useLocation } from 'react-router-dom'
import AutoSizer from 'react-virtualized-auto-sizer'
import { FlexBox } from '../@Global/FlexBox'
import { Loader } from '../@Global/Loader'

interface Props {
    children?: (ReactElement | boolean | null)[] | ReactElement
    loadNext?: () => void
    dataLength?: number
    heading: string
    icon?: ReactElement
    loading?: boolean
    table?: ReactElement
    topRightContent?: ReactElement
    topLeftContent?: ReactElement
    mainContent?: ReactElement
    bottomContent?: ReactElement
    fab?: ReactElement
    disableTopGutter?: boolean
    customPadding?: string
    maxWidth?: Breakpoint | false
    headingStyle?: SxProps
}

export const TableContainer: React.FunctionComponent<Props> = (props) => {
    const { maxWidth, customPadding, children, bottomContent, loading, icon, table, topRightContent, topLeftContent, heading, fab, loadNext, dataLength, disableTopGutter, mainContent, headingStyle } = props

    const location = useLocation()
    const popout = location.pathname.includes('popout')
    const theme = useTheme()
    const screenSmall = useMediaQuery(theme.breakpoints.down('md'))

    return (
        <Container
            disableGutters
            maxWidth={maxWidth ?? false}
            sx={{
                p: popout ? '0px' : disableTopGutter ? '0 16px 16px 16px' : 2,
                overflowX: 'clip'
            }}
        >
            <Paper sx={{ padding: 0, height: '100%', maxHeight: '100vh', borderRadius: popout ? '0px !important' : 'auto' }}>
                <Stack style={{ height: '100%' }}>
                    <Stack p={customPadding ?? '12px 20px 16px 20px'} direction='row' justifyContent='space-between' flexWrap='wrap'>
                        {
                            !screenSmall
                                ? <Stack spacing={2} direction='row' alignSelf={'flex-start'} alignItems={'center'} sx={headingStyle}>
                                    {icon}
                                    {<Typography whiteSpace={'nowrap'} variant='h4'>{heading}</Typography>}
                                    {<Loader loading={loading} />}
                                    {topLeftContent}

                                </Stack>
                                : topLeftContent}
                        {topRightContent}
                    </Stack>
                    <FlexBox spaceBetween padding={'0 14px'} style={{ marginBottom: 2, alignItems: 'flex-end' }}>
                        {children}
                    </FlexBox>
                    <Box sx={{ height: '100%', mt: 1 }}>
                        {table != undefined && <AutoSizer>
                            {({ height, width }) => (
                                (loadNext && dataLength)
                                    ? <InfiniteScroll
                                        dataLength={dataLength}
                                        next={loadNext}
                                        hasMore={true}
                                        loader={null}
                                        height={height}
                                        style={{ width: width }}
                                        endMessage={null}
                                    >
                                        {table}
                                    </InfiniteScroll>
                                    : <Box sx={{ background: '#202020', width: width, height: height, overflow: 'auto' }}>
                                        {table}
                                    </Box>
                            )}
                        </AutoSizer>}
                        {mainContent}
                    </Box>
                    {bottomContent}
                </Stack>
            </Paper>
            {fab}
        </Container >
    )
}
