import { stagePointError } from '.'
import { UnitPointType } from '../../../../../store/Itinerary'
import { AllPointsRule } from '../../../../../store/StageFile'

export const firstPointIsValid: AllPointsRule = (stagePoints) => {
    // First line needs to be TC, TS, WP, BP, or SS
    if (stagePoints[0]) {
        switch (stagePoints[0].type.unitPointType) {
            case UnitPointType.TimeControl:
            case UnitPointType.TransitStart:
            case UnitPointType.Waypoint:
            case UnitPointType.BuoyPoint:
            case UnitPointType.StageStart:
                break
            default:
                return [stagePointError(stagePoints[0], `First point must be TC/TS/WP/SS - ${stagePoints[0].type.code} is invalid`)]
        }
    }
}