import { AppBar, IconButton, Stack, Toolbar, Typography, useMediaQuery, useTheme } from '@mui/material'
import { Howl } from 'howler'
import * as React from 'react'
import { useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { CountryFlagIcon } from '../../assets/Icons/CountryFlagIcon'
import { SASLogo } from '../../assets/Logos/SASLogo'
import { useStickyState } from '../../helper/useStickyState'
import { ApplicationState } from '../../store'
import { EventViewer, hasAccess } from '../../store/Account'
import HazardSounds from '../@Global/audio/hazard-sounds.mp3'
import HazardsButton from '../Events/Hazards/HazardsButton'
import Clock from './Clock'
import NotificationsButton from './Notifications/NotificationsButton'
import { PageTitle } from './PageTitle'
import { SmallEventImageDisplay } from './SmallEventImageDisplay'
import { UserButton } from './UserButton'
import { useInterval } from '../../helper/useInterval'
import { identityActionCreators } from '../../store/Identity'
import { Dispatch } from 'redux'
import { Weather } from '../Map/Weather'

type Props = {
    onDrawerOpen: () => void
    isOpen: boolean
    drawerWidth: number
}

let sound = new Howl({
    src: [HazardSounds],
    sprite: {
        'sos': [0, 190, true],
        'rollover': [280, 2600, false],
        'hybrid': [2910, 410, false],
        'comms': [3340, 1300, false],
    }
})

const Header: React.FunctionComponent<Props> = ({ isOpen, drawerWidth, onDrawerOpen }) => {
    const countryCode = useSelector((state: ApplicationState) => state.event.selectedEvent?.countryCode, shallowEqual)
    const selectedEventId = useSelector((state: ApplicationState) => state.event.selectedEventId, shallowEqual)
    const roles = useSelector((state: ApplicationState) => state.identity.roles)
    const isAuthenticated = useSelector((state: ApplicationState) => state.identity.isAuthenticated)
    const nightMode = useSelector((state: ApplicationState) => state.event.selectedEvent?.nightMode)
    const autoNightMode = useSelector((state: ApplicationState) => state.event.selectedEvent?.autoNightMode)
    const showWeather = useSelector((state: ApplicationState) => state.map.showWeather)

    const [hazardsUnread, setHazardsUnread] = useStickyState<number>(0, `hazardsUnread-${selectedEventId}`)

    const theme = useTheme()
    const screenExtraSmall = useMediaQuery(theme.breakpoints.down('sm'))
    const screenExtraSmall2 = useMediaQuery(theme.breakpoints.down('xs2'))
    const screenSmall = useMediaQuery(theme.breakpoints.down('md'))
    const screenSmall2 = useMediaQuery(theme.breakpoints.down('sm2'))
    const location = useLocation()
    const dispatch: Dispatch<any> = useDispatch()

    // Refresh the cookie every 10 minutes to avoid events claims expiring
    useInterval(() => {
        if (isAuthenticated && selectedEventId != undefined)
            dispatch(identityActionCreators.getIdentity())
    }, 60000 * 10)

    const onNewHazard = (_eventId: number) => {
        if (_eventId == selectedEventId)
            setHazardsUnread(hazardsUnread + 1)
    }

    const onMarkHazardsRead = () => {
        setHazardsUnread(0)
    }
    
    return (
        <AppBar position="fixed" sx={{
            height: screenExtraSmall ? 56 : 64,
            background: 'rgb(58,58,58)',
            zIndex: theme.zIndex.drawer + 1,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            ...(isOpen && {
                marginLeft: drawerWidth,
                width: `calc(100% - ${drawerWidth}px)`,
                transition: theme.transitions.create(['width', 'margin'], {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.enteringScreen,
                }),
            })
        }}>
            <Toolbar sx={{
                paddingRight: 1,
                paddingTop: 2,
                paddingBottom: 2
            }}>
                <IconButton
                    edge="start"
                    color="inherit"
                    onClick={onDrawerOpen}
                    sx={{
                        marginRight: '14px',
                        ...(isOpen && { display: 'none' }),
                    }}
                    //  className={clsx(classes.menuButton, isOpen && classes.menuButtonHidden)}
                    size="large">
                    <SASLogo height={20} />
                </IconButton>
                <Stack direction='row' alignItems='center' justifyContent='space-between' width='100%'>
                    <Stack direction='row' alignItems='center'>
                        <SmallEventImageDisplay />
                        {countryCode && !screenExtraSmall && <CountryFlagIcon size={!screenSmall ? 60 : 52} code={countryCode} />}
                        <PageTitle pathName={location.pathname} />
                        {showWeather == true && <Weather />}
                    </Stack>
              
                    <Stack direction='row' alignItems='center' spacing={{ sm: 1, md: 1.5 }}>
                        {selectedEventId && !screenExtraSmall2 && hasAccess(EventViewer, roles) &&
                            <HazardsButton
                                eventId={selectedEventId}
                                onMarkHazardsRead={onMarkHazardsRead}
                            />}
                        {selectedEventId && !screenExtraSmall2 && hasAccess(EventViewer, roles) &&
                            <NotificationsButton
                                eventId={selectedEventId}
                                sound={sound}
                            />}
                        {!screenSmall2 && <Clock />}
                        <UserButton />
                    </Stack>
                </Stack>
            </Toolbar>
        </AppBar>
    )
}

export default React.memo(Header)

