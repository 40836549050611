import { Slider, Typography, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import React, { CSSProperties, useState } from 'react'
import { useSelector } from 'react-redux'
import { ApplicationState } from '../../store'

const marks = [{ value: 0 }, { value: 1 }, { value: 2 }, { value: 3 }, { value: 4 }, { value: 5 }, { value: 6 }]

interface Props {
    style?: CSSProperties
    onPhaseChange(phase: number[])
    initialValues: number[]
}

export const PhaseSlider: React.FunctionComponent<Props> = (props) => {
    const { style, initialValues, onPhaseChange } = props
    const { username } = useSelector((state: ApplicationState) => state.identity)

    const [value, setValue] = useState<number[]>(initialValues)

    const theme = useTheme()
    const screenSmall = useMediaQuery(theme.breakpoints.down('md'))
    const isPublic = username == 'PublicUser'

    const handleChangeCommitted = (event: any, newValue: number[]) => {
        let sortedValue = newValue.sort()
        onPhaseChange(sortedValue)
    }

    const handleChange = (event: any, newValue: number[]) => {
        let sortedValue = newValue.sort()
        setValue(sortedValue)
    }

    let opacities = marks.map(x => {
        if (value[0] <= x.value && value[1] > x.value) {
            return 1
        } else {
            return 0.25
        }
    })

    const textStyle = {
        fontSize: 14,
        lineHeight: 1.65,
        fontWeight: 400
    }

    return (
        <div style={style}>
            {!screenSmall && <div style={{
                color: '#111',
                position: 'relative',
                zIndex: 1,
                pointerEvents: 'none',
                top: 30

            }}>
                {!isPublic && <Typography variant="button" display='inline' noWrap style={{ ...textStyle, marginLeft: 24 }}>
                    Planning
                </Typography>}
                {!isPublic && <Typography variant="button" display='inline' noWrap style={{ ...textStyle, marginLeft: 51 }}>
                    Building
                </Typography>}
                <Typography variant="button" display='inline' noWrap style={{ ...textStyle, marginLeft: isPublic ? 27 : 41 }}>
                    Preparation
                </Typography>
                <Typography variant="button" display='inline' noWrap style={{ ...textStyle, marginLeft: isPublic ? 66 : 53, color: 'white', opacity: opacities[3] }}>
                    Live
                </Typography>
                <Typography variant="button" display='inline' noWrap style={{ ...textStyle, marginLeft: isPublic ? 102 : 86 }}>
                    Post
                </Typography>
                {!isPublic && <Typography variant="button" display='inline' noWrap style={{ ...textStyle, marginLeft: 66 }}>
                    Archived
                </Typography>}
            </div>}
            <Slider
                onDragOver={(e) => console.log(e)}
                min={isPublic ? 2 : 0}
                max={isPublic ? 5 : 6}
                value={value}
                onChange={handleChange}
                onChangeCommitted={handleChangeCommitted}
                step={null}
                valueLabelDisplay="off"
                marks={marks}
                sx={{
                    width: screenSmall ? 465 : isPublic ? 402 : 700,
                    color: 'transparent',
                    backgroundImage: isPublic
                        ? `linear-gradient(90deg, rgba(79,195,247,${opacities[2]}) 0%, rgba(79,195,247,${opacities[2]}) 33.3%, rgba(229,57,53,${opacities[3]}) 33.3%, rgba(229,57,53,${opacities[3]}) 66.6%, rgba(189,189,189,${opacities[4]}) 66.6%, rgba(189,189,189,${opacities[4]}) 100%)`
                        : `linear-gradient(90deg, rgba(174,213,129,${opacities[0]}) 0%, rgba(174,213,129,${opacities[0]}) 16.7%, rgba(129,199,132,${opacities[1]}) 16.7%, rgba(129,199,132,${opacities[1]}) 33.3%, rgba(79,195,247,${opacities[2]}) 33.3%, rgba(79,195,247,${opacities[2]}) 49.9%, rgba(229,57,53,${opacities[3]}) 49.9%, rgba(229,57,53,${opacities[3]}) 66.6%, rgba(189,189,189,${opacities[4]}) 66.6%, rgba(189,189,189,${opacities[4]}) 83.3%, rgba(117,117,117,${opacities[5]}) 83.3%, rgba(117,117,117,${opacities[5]}) 100%)`,

                    height: 0,
                    padding: '18px 0 17px 0',
                    borderRadius: 18,
                    '& .MuiSlider-thumb': {
                        height: 20,
                        width: 20,
                        zIndex: 2,
                        backgroundColor: '#fff',
                        border: '1px solid currentColor',
                        boxShadow: '#444 0px 2px 2px',
                        '&:hover, &$active': {
                            boxShadow: '#eee 0px 2px 3px 1px',
                        },
                    },
                    '& .MuiSlider-valueLabel': {
                        left: 'calc(-50% + 11px)',
                        top: -22,
                        '& *': {
                            background: 'transparent',
                            color: '#000',
                        },
                    },
                    '& .MuiSlider-track': {
                        height: 2,
                    },
                    '& .MuiSlider-rail': {
                        opacity: 0,
                        height: 3,
                        zIndex: 2,
                    },
                    '& .MuiSlider-mark': {
                        display: 'none'
                    },
                }}
            />
        </div>
    )
}
