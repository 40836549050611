export enum HttpVerb {
    POST,
    PUT,
    DELETE,
    GET
}

import { v4 as uuidv4 } from 'uuid'

const getDeviceId = () => {
    let deviceId = document.cookie.split('; ').find(row => row.startsWith('deviceId='))
    if (!deviceId) {
        deviceId = `deviceId=${uuidv4()}`
        document.cookie = `${deviceId}; path=/; expires=Fri, 31 Dec 9999 23:59:59 GMT`
    }
    return deviceId.split('=')[1]
}

const deviceId = getDeviceId()

function request(verb: HttpVerb, data?: any) {
    let headers = new Headers()
    headers.append('Accept', '*/*')
    headers.append('Content-Type', 'application/json; charset=utf-8')
    headers.append('Device-Id', deviceId)

    let req: RequestInit = {
        method: HttpVerb[verb],
        headers: headers,
        credentials: "include"
    }

    if (data) {
        req.body = JSON.stringify(data)
    }

    return req
}

export const upload = (path: string, file: File) => {
    let req: RequestInit = {
        method: 'POST',
        headers: new Headers(),
        credentials: "include"
    }

    const formData: FormData = new FormData()
    formData.append('file', file, file['name'])
    req.body = formData
    return fetch(path, req)
}

export function postEmpty(path: string): Promise<Response> {
    return fetch(path, request(HttpVerb.POST))
        .then(result => {
            if (!result.ok) {
                return result.json().then((r: Error) => { throw r })
            }
            return result
        })
}

export function post<T>(path: string, payload: T): Promise<any> {
    return fetch(path, request(HttpVerb.POST, payload))
        .then(result => {
            if (!result.ok) {
                return result.json().then((r: Error) => { throw r })
            } else {
                return result
            }
        })
}

export function postWithResponse<T, U>(path: string, payload: T): Promise<U> {
    return fetch(path, request(HttpVerb.POST, payload))
        .then(result => {
            if (!result.ok) {
                console.log(result)
                return result.json().then((r: Error) => { throw r })
            } else {
                return result.json()
            }
        })
}

export function get<T>(path: string, raw?: boolean): Promise<T> {
    return fetch(path, request(HttpVerb.GET))
        .then(result => {
            if (raw == true) return result as unknown as Promise<T>
            if (!result.ok) {
                return result.json().then((r: Error) => { throw r })
            }
            return result.json() as Promise<T>
        })
}

export function del(path: string, id?: any): Promise<Response> {
    if (id)
        path = path + `?id=${id}`

    return fetch(path, request(HttpVerb.DELETE))
        .then(result => {
            if (!result.ok) {
                return result.json().then((r: Error) => { throw r })
            } else {
                return result
            }
        })
}
