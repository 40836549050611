import AssessmentIcon from '@mui/icons-material/Assessment'
import AssignmentIcon from '@mui/icons-material/Assignment'
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter'
import EventIcon from '@mui/icons-material/Event'
import InfoIcon from '@mui/icons-material/Info'
import MapIcon from '@mui/icons-material/Map'
import PeopleIcon from '@mui/icons-material/People'
import ShowChartIcon from '@mui/icons-material/ShowChart'
import TimerIcon from '@mui/icons-material/Timer'
import { Box, Divider, List, ListSubheader } from '@mui/material'
import * as React from 'react'
import { useSelector } from 'react-redux'
import UnitIcon from '../../assets/Icons/UnitIcon'
import { ApplicationState } from '../../store'
import { Basic, CompanyEditor, EventEditor, hasAccess } from '../../store/Account'
import { SideMenuLink } from './SideMenuLink'
import HandymanIcon from '@mui/icons-material/Handyman'
import { Domain, isLiteEvent } from '../../store/Event'

type Props = {
    open: boolean
}

export const SideMenu: React.FunctionComponent<Props> = ({ open }) => {
    const roles = useSelector((state: ApplicationState) => state.identity.roles)
    const selectedEvent = useSelector((state: ApplicationState) => state.event.selectedEvent)
    const selectedEventId = useSelector((state: ApplicationState) => state.event.selectedEventId)
    const username = useSelector((state: ApplicationState) => state.identity.username)

    const stageTimesVisible = (selectedEvent?.stageTimesVisible && selectedEvent?.domain == Domain.RallySafe) ?? false
    const useResultsLink = (selectedEvent?.resultsUrl.length ?? 0) > 0 && roles.length == 1 && roles[0] == Basic
    const isPublic = username == 'PublicUser'
    const mapVisible = !isPublic || (selectedEvent?.phase ?? 0) > 2

    return (
        <Box sx={{
            '& .MuiListSubheader-root': {
                ...(!open && { display: 'none' })
            }
        }}>
            {open && <Divider />}
            <List disablePadding>
                <SideMenuLink key={'sm-events'} title='Events' icon={<EventIcon />} location={`/events`} />
            </List>
            {
                selectedEventId &&
                <React.Fragment>
                    <Divider key='divider' />
                    <List key='event-pages' disablePadding subheader={<ListSubheader component="div">Current Event</ListSubheader>}>
                        <SideMenuLink key='Details' title='Details' icon={<InfoIcon />} location={`/events/${selectedEventId}/details#overview`} />
                        {mapVisible && <SideMenuLink key='Map' title='Map' icon={<MapIcon />} location={`/events/${selectedEventId}/map`} />}
                        <SideMenuLink key='Entries' title='Entries' icon={<AssignmentIcon />} location={`/events/${selectedEventId}/entries#entry-list`} />
                        <SideMenuLink key='Itinerary' title='Itinerary' icon={<ShowChartIcon />} location={`/events/${selectedEventId}/itinerary#stages`} />
                        {
                            hasAccess(EventEditor, roles) &&
                            <SideMenuLink key='Manage' title='Manage' icon={<BusinessCenterIcon />} location={`/events/${selectedEventId}/manage#${!isLiteEvent(selectedEvent) ? 'history' : 'lite'}`} />
                        }
                        {
                            (stageTimesVisible || hasAccess(EventEditor, roles, undefined, isLiteEvent(selectedEvent)) || useResultsLink)
                            && <SideMenuLink key='Times' title='Times' icon={<TimerIcon />} external={useResultsLink} location={useResultsLink ? selectedEvent?.resultsUrl ?? '' : `/events/${selectedEventId}/times#stage-times`} />
                        }
                    </List>
                </React.Fragment>
            }
            {
                hasAccess(EventEditor, roles, undefined, isLiteEvent(selectedEvent)) &&
                <React.Fragment>
                    <Divider key='divider' />
                    <List key='editors' disablePadding subheader={<ListSubheader component="div">Global</ListSubheader>}>
                        <SideMenuLink key='Equipment' title='Equipment' icon={<HandymanIcon />} location={`/equipment#log`} />
                        {/* <SideMenuLink key='Personal Data' title='Personal Data' icon={<PeopleIcon />} location={`/people`} /> */}
                    </List>
                </React.Fragment>
            }
            {
                roles.includes(CompanyEditor) &&
                <React.Fragment>
                    <List key='global' disablePadding>
                        <SideMenuLink key='Units' title='Units' icon={<UnitIcon />} location={`/units#messages`} />
                        {/* <SideMenuLink key='Company Data' title='Company Data' icon={<ClassIcon />} location={`/other-data#polylines`} /> */}
                        <SideMenuLink key='Metrics' title='Metrics' icon={<AssessmentIcon />} location={`/metrics`} />
                        <SideMenuLink key='Accounts' title='Accounts' icon={<PeopleIcon />} location={`/accounts#accounts`} />
                    </List>
                </React.Fragment>
            }
        </Box>
    )
}
