import * as Core from '.'
import { HazardEvent, Hazard, SlowCar } from '../components/Events/Hazards/Models'
import { VehicleType } from '../store/Entry'
import { Speeding } from '../store/Times'

export function requestHazardEvents(eventId: number): Promise<HazardEvent[]> {
    return Core.get<HazardEvent[]>(`/hazard/hazard-events?eventId=${eventId}`)
}

export function requestHazards(hazardEventId: number): Promise<Hazard[]> {
    return Core.get<Hazard[]>(`/hazard/hazards?hazardEventId=${hazardEventId}`)
}

export function requestSlowCars(skip: number, take: number, search: string, order: string, eventId: number, stageId: number, selectedClasses: number[], vehicleType: VehicleType | null): Promise<SlowCar[]> {
    return Core.get<SlowCar[]>(`/hazard/slow-cars?skip=${skip}&take=${take}&search=${search}&order=${order}&eventId=${eventId}&stageId=${stageId}&classes=${selectedClasses}&vehicleType=${vehicleType}`)
}
