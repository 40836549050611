import Pubnub from 'pubnub'

export const getPubnubUuid = () => {
    let currentValue = window.localStorage.getItem('pubnubUuid') ?? ''
    if (!currentValue.isEmpty()) {
        return currentValue
    } else {
        let newValue = Pubnub.generateUUID()
        window.localStorage.setItem('pubnubUuid', newValue)
        return newValue
    }
}