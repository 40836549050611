import { StaticDatePicker } from '@mui/x-date-pickers'
import { isEmpty, isNull, isUndefined } from 'lodash'
import { DateTime } from 'luxon'
import { invalid } from 'moment'
import { Action, Reducer } from 'redux'
import { AppThunkAction } from '.'
import * as Api from '../api/Event'
import { countryList } from '../components/@Global/CountryList'
import { getInitialNumberValue, getInitialValue } from '../helper/StorageHelper'
import { AccountBasic } from './Account'
import { Championship, ChampionshipBasic } from './Championship'
import { TempUserDetail } from '../api/Identity'

export enum EventPhase {
    Planning = 0,
    Building,
    Preparation,
    Live,
    Post,
    Archived
}

export enum RacingStatus {
    InTransport = 0,
    Racing = 1
}

export enum CompetitionStatus {
    Competing = 0,
    Withdrawn
}

export enum NotificationType {
    Notification,
    Warning,
    Error,
    IncomingMessage,
    Safety
}

export enum PricingTier {
    None = -1,
    ClubEvent = 0,
    StateEvent = 1,
    NationalEvent = 2,
    InternationalEvent = 3,
    Targa = 4,
    SpecialEventTier5 = 5,
    SpecialEventTier6 = 6,
    SpecialEventTier7 = 7,
    SpecialEventTier8 = 8,
    SpecialEventTier9 = 9,
    SpecialEventTier10 = 10
}

export const getPricingTier = (pricingTier: PricingTier) => {
    switch (pricingTier) {
        case PricingTier.ClubEvent: return 'Club Event - Tier 0, Free'
        case PricingTier.StateEvent: return 'State Event - Tier 1, $1.49 AUD'
        case PricingTier.NationalEvent: return 'National Event - Tier 2, $2.99 AUD'
        case PricingTier.InternationalEvent: return 'International Event - Tier 3, $4.49 AUD'
        case PricingTier.Targa: return 'Targa - Tier 4, $5.99 AUD'
        case PricingTier.SpecialEventTier5: return 'Special Event - Tier 5, $7.99 AUD'
        case PricingTier.SpecialEventTier6: return 'Special Event - Tier 6, $9.99 AUD'
        case PricingTier.SpecialEventTier7: return 'Special Event - Tier 7, $10.99 AUD'
        case PricingTier.SpecialEventTier8: return 'Special Event - Tier 8, $12.99 AUD'
        case PricingTier.SpecialEventTier9: return 'Special Event - Tier 9, $13.99 AUD'
        case PricingTier.SpecialEventTier10: return 'Special Event - Tier 10, $14.99 AUD'
    }
}

export const isLiteEvent = (selectedEvent: EventDetailed | null) => {
    return selectedEvent?.domain == Domain.RallySafeLite || selectedEvent?.domain == Domain.RallySafeRecce
}

export enum UnitMode {
    Tracking = 0,
    StandardFile,
    ReducedFile,
    Manual,
    RaceSafe,
    Beacon,
    Reccy,
    CourseSetup,
    CourseCheck,
    Chrono,
    Beam
}

export enum EventVisibility {
    Private = 0,
    MobileAndDesktop = 1,
    MobileOnly = 2,
    DesktopOnly = 3
}

export enum EventStatus {
    Unconfirmed = 0,
    Confirmed = 1,
    Postponed = 2,
    Cancelled = 3,
    Test = 4
}

export const getNotificationType = (s: string) => {
    switch (s) {
        case 'Notification': return NotificationType.Notification
        case 'Warning': return NotificationType.Warning
        case 'Error': return NotificationType.Error
        case 'IncomingMessage': return NotificationType.IncomingMessage
        default: return NotificationType.Notification
    }
}

export const getEventTimeZone = (selectedEvent: EventDetailed | null) => {
    if (selectedEvent == null)
        return ''
    var zoneName = `UTC${selectedEvent.timeZone > -0.5 ? '+' : ''}`
    zoneName += Math.floor(selectedEvent.timeZone)

    if (selectedEvent.timeZone % 1 == 0.5) {
        zoneName += ':30'
    } else if (selectedEvent.timeZone % 1 == 0.75) {
        zoneName += ':45'
    }
    return zoneName
}

export const isMobileAppEnabled = (ev: EventDetailed) => {
    return !isNull(ev.pricingTier)
        && !isEmpty(ev.name)
        && !isEmpty(ev.description)
        && !isEmpty(ev.featureImage)
        && ev.status == EventStatus.Confirmed
}

export enum UnitOfMeasurement {
    Length,
    Speed
}

const getIsImperial = (selectedEvent: EventDetailed) => {
    let isImperial = selectedEvent.domain == Domain.RaceSafeH2O
    let country = countryList.find(x => x.code == selectedEvent.countryCode)

    if (country && !isImperial) {
        isImperial = selectedEvent.countryCode == 'LR'
            || selectedEvent.countryCode == 'MM'
            || selectedEvent.countryCode == 'GB'
            || selectedEvent.countryCode == 'US'
            || selectedEvent.countryCode == 'BS'
            || selectedEvent.countryCode == 'KY'
            || selectedEvent.countryCode == 'VG'
            || selectedEvent.countryCode == 'VI'
    }
    // Add value to local storage
    window.localStorage.setItem('isImperial', isImperial.toString())

    return isImperial
}

export const getMeasurementUnit = (selectedEvent: EventDetailed | null, unitOfMeasurement: UnitOfMeasurement) => {
    if (selectedEvent == null)
        return ''

    const isImperial = getIsImperial(selectedEvent)

    switch (unitOfMeasurement) {
        case UnitOfMeasurement.Length:
            return isImperial ? 'mi' : 'km'
        case UnitOfMeasurement.Speed:
            return isImperial ? 'mph' : 'km/h'
    }
}

export const getConvertedSpeed = (kph: number, selectedEvent: EventDetailed | null) => {
    if (!kph) return 0
    if (getMeasurementUnit(selectedEvent, UnitOfMeasurement.Speed) == 'mph') {
        return kph / 1.609344
    } else {
        return kph
    }
}

export enum Domain {
    //  None = 0,
    RallySafe = 1,
    //  RallySafeAPI,
    RaceSafeH2O = 3,
    // RaceSafeH2OAPI,
    RallySafeLite = 5,
    RallySafeRecce = 6
}

export interface EventChampionshipMaps {
    eventId: number
    championshipId: number
    round: number
    event: EventListModel
    championship: Championship
}

export interface EventListModel {
    eventId: number
    name: string
    countryCode: string
    start: string
}

export interface EventYear {
    eventId: number
    name: string
    year: number
    match: number
}

export interface EventBasic {
    eventId: number
    name: string
    countryCode: string
    start: string
    finish: string
    location: string
    phase: EventPhase
    isTest: boolean
    isWrc: boolean
    expectedVehicles: number | null
    expectedCourseVehicles: number | null
    competitiveVehicleCount: number
    courseVehicleCount: number
    championships: Championship[]
    timeZone: number
    status: EventStatus
    domain: Domain
    appEnabled: boolean
}

export const BlankEventBasic: EventBasic = {
    eventId: -1,
    name: '',
    location: '',
    start: DateTime.local().set({ hour: 8, minute: 0, second: 0 }).toISO() ?? '',
    finish: DateTime.local().plus({ days: 1 }).set({ hour: 18, minute: 0, second: 0 }).toISO() ?? '',
    countryCode: '',
    phase: EventPhase.Planning,
    isTest: false,
    isWrc: false,
    expectedVehicles: null,
    expectedCourseVehicles: null,
    competitiveVehicleCount: 0,
    courseVehicleCount: 0,
    timeZone: 0,
    status: EventStatus.Confirmed,
    championships: [],
    domain: Domain.RallySafe,
    appEnabled: false
}

export const BlankEventDetailed: EventDetailed = {
    eventId: -1,
    name: '',
    location: '',
    start: DateTime.local().set({ hour: 8, minute: 0, second: 0 }).toISO() ?? '',
    finish: DateTime.local().plus({ days: 1 }).set({ hour: 18, minute: 0, second: 0 }).toISO() ?? '',
    countryCode: '',
    phase: EventPhase.Planning,
    isTest: false,
    isWrc: false,
    competitiveVehicleCount: 0,
    courseVehicleCount: 0,
    timeZone: 0,
    status: EventStatus.Confirmed,
    championships: [],
    domain: Domain.RallySafe,
    accomodationInfo: '',
    appVoucherCode: '',
    automaticallySendTabletTimesToResults: false,
    autoNightMode: false,
    contacts: [],
    description: '',
    disableGarrySearleFinishTimes: false,
    displayBeamTimes: false,
    equipment: [],
    featureImage: '',
    garrySearleEventName: '',
    isFree: false,
    liveTrackingOnMap: false,
    logistics: '',
    managerName: '',
    marshalCode: 0,
    notifications: [],
    outbackChallengeEnabled: false,
    plannerLink: '',
    preEventNotes: '',
    report: '',
    resultsUrl: '',
    sharePointLink: '',
    showResults: false,
    smallImage: '',
    speedPenaltyFactor: 0,
    splitTimesVisible: false,
    sponsorImage: '',
    sponsorImageLink: '',
    staff: [],
    stageFileChecksums: '',
    stageFileReport: '',
    stageMapName: '',
    stageTimesVisible: false,
    temporaryUsers: [],
    timeControlsByUnit: false,
    travelInfo: '',
    uniqueId: '',
    unitProfileName: '',
    useRealTimeControls: false,
    visibility: EventVisibility.Private
}

export interface Contact {
    contactId: number
    email: string
    name: string
    phone: string
}

export const blankContact = {
    contactId: -1,
    email: '',
    name: '',
    phone: ''
}

export interface EventEquipment {
    equipmentLogId: number
    isArchived: boolean
    type: string
    identifier: string
}

export interface EventDetailed {
    accomodationInfo: string
    appAvailable?: string
    appVoucherCode: string
    arrivalDate?: string
    automaticallySendTabletTimesToResults: boolean
    autoNightMode: boolean
    championships: ChampionshipBasic[]
    competitiveVehicleCount: number
    contacts: Contact[]
    countryCode: string
    courseVehicleCount: number
    description: string
    disableGarrySearleFinishTimes: boolean
    displayBeamTimes: boolean
    domain: Domain
    equipment: EventEquipment[]
    eventId: number
    // eventReportGoodStuff: string
    // eventReportIssues: string
    // eventReportOverview: string
    expectedCourseVehicles?: number
    expectedStaff?: number
    expectedVehicles?: number
    featureImage: string
    finish: string
    garrySearleEventName: string
    //gpscoordFormat: number
    //isBillingCompleted: boolean
    isFree: boolean
    isTest: boolean
    isWrc: boolean
    liveTrackingOnMap: boolean
    location: string
    logistics: string
    managerName: string
    marshalCode: number
    name: string
    nightMode?: boolean
    notifications: Notification[]
    outbackChallengeEnabled: boolean
    phase: EventPhase
    plannerLink: string
    preEventNotes: string
    pricingTier?: PricingTier
    report: string
    resultsSlug?: string
    resultsUrl: string
    sharePointLink: string
    showResults: boolean
    smallImage: string
    smallImageLink?: string
    speedPenaltyFactor: number
    splitTimesVisible: boolean
    sponsorImage: string
    sponsorImageLink: string
    staff: AccountBasic[]
    stageFileChecksums: string
    stageFileReport: string
    stageMapId?: number
    stageMapName: string
    stageTimesVisible: boolean
    start: string
    status: EventStatus
    temporaryUsers: TempUserDetail[]
    timeControlsByUnit: boolean
    timeZone: number
    travelInfo: string
    uniqueId: string
    unitMode?: UnitMode
    unitProfileId?: number
    unitProfileName: string
    unitVersionNumber?: number
    useRealTimeControls: boolean
    visibility: EventVisibility
}

export interface Notification {
    notificationId: number
    timestamp: string
    source: string
    details: string
    type: NotificationType
}

export interface CheckSetupMessages {
    valid: number
    invalid: number
}

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface EventState {
    eventsPending: boolean
    events: EventBasic[]
    eventDetailsPending: boolean
    selectedEvent: EventDetailed | null
    selectedEventId?: number
    eventNotificationsPending: boolean
    eventEntriesPending: boolean
    showNotifications: boolean
    editingEvent: boolean
    hazardClass: string
    hazardsUnread: number
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

interface RequestEvents { type: 'REQUEST_EVENTS' }
interface ReceiveEvents { type: 'RECEIVE_EVENTS', events?: EventBasic[], append?: boolean }
interface ClearEvents { type: 'CLEAR_EVENTS' }

interface RequestEventDetails { type: 'REQUEST_EVENT_DETAILS' }
interface ReceiveEventDetails { type: 'RECEIVE_EVENT_DETAILS', eventDetailed?: EventDetailed }
interface SetSelectedEventId { type: 'SET_SELECTED_EVENTID', eventId: number }
interface ClearSelectedEvent { type: 'CLEAR_SELECTED_EVENT' }

interface RequestNotifications { type: 'REQUEST_NOTIFICATIONS' }
interface ReceiveNotifications { type: 'RECEIVE_NOTIFICATIONS', notifications?: Notification[] }
interface AddNotification { type: 'ADD_NOTIFICATION', notification: Notification }

interface SetNightMode { type: 'SET_NIGHT_MODE', nightMode: boolean }
interface SetShowNotifications { type: 'SET_SHOW_NOTIFICATIONS', showNotifications: boolean }

interface ReceiveFailed { type: 'RECEIVE_FAILED' }
interface SetEditingEvent { type: 'SET_EDITING_EVENT', editingEvent: boolean }
interface SetHazardClass { type: 'SET_HAZARD_CLASS', hazardClass: string }
interface SetHazardsUnread { type: 'SET_HAZARDS_UNREAD', hazardsUnread: number }

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = SetHazardsUnread | SetHazardClass | SetEditingEvent | SetShowNotifications | SetNightMode | RequestEvents | ReceiveEvents | ClearEvents | SetSelectedEventId | RequestEventDetails | ReceiveEventDetails | ClearSelectedEvent | RequestNotifications | AddNotification | ReceiveNotifications | ReceiveFailed

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const eventActionCreators = {
    requestEvents: (skip: number, take: number, searchText: string, order: string, phaseRange: number[], append: boolean, status: EventStatus[], champs: number[], countries: string[]): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'REQUEST_EVENTS' })
        Api.requestEvents(skip, take, searchText, order, phaseRange, status, champs, countries)
            .then(result => dispatch({
                type: 'RECEIVE_EVENTS',
                events: result,
                append: append
            }))
            .catch((err: number) => {
                dispatch({
                    type: 'RECEIVE_EVENTS'
                })
                dispatch({
                    type: 'RECEIVE_FAILED'
                })
            })
    },
    clearEvents: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'CLEAR_EVENTS' })
    },
    requestEventDetails: (eventId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'REQUEST_EVENT_DETAILS' })
        Api.requestEventDetails(eventId)
            .then(result => {
                dispatch({
                    type: 'RECEIVE_EVENT_DETAILS',
                    eventDetailed: result
                })
            })
            .catch((err: number) => {
                dispatch({
                    type: 'RECEIVE_EVENT_DETAILS'
                })
                dispatch({
                    type: 'RECEIVE_FAILED'
                })
            })
    },
    setSelectedEventId: (eventId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'SET_SELECTED_EVENTID', eventId: eventId })
    },
    clearSelectedEvent: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'CLEAR_SELECTED_EVENT' })
    },
    addNotification: (notification: Notification): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'ADD_NOTIFICATION', notification: notification })
    },
    setNightMode: (nightMode: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'SET_NIGHT_MODE', nightMode: nightMode })
    },
    setShowNotifications: (showNotifications: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'SET_SHOW_NOTIFICATIONS', showNotifications: showNotifications })
    },
    setEditingEvent: (editingEvent: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'SET_EDITING_EVENT', editingEvent: editingEvent })
    },
    setHazardClass: (hazardClass: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'SET_HAZARD_CLASS', hazardClass: hazardClass })
    },
    setHazardsUnread: (hazardsUnread: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'SET_HAZARDS_UNREAD', hazardsUnread: hazardsUnread })
    }
}

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.
const unloaded: EventState = {
    eventsPending: false,
    events: <EventBasic[]>[],
    eventDetailsPending: false,
    selectedEvent: null,
    selectedEventId: getInitialNumberValue('selectedEventId'),
    eventNotificationsPending: false,
    eventEntriesPending: false,
    showNotifications: getInitialValue(`showNotifications${getInitialNumberValue('selectedEventId')}`, true),
    editingEvent: false,
    hazardClass: '',
    hazardsUnread: 0
}

export const reducer: Reducer<EventState> = (state: EventState | undefined, incomingAction: Action): EventState => {
    if (state === undefined) return unloaded
    const action = incomingAction as KnownAction

    switch (action.type) {
        case 'REQUEST_EVENTS':
            return {
                ...state,
                eventsPending: true
            }
        case 'RECEIVE_EVENTS':
            let events: EventBasic[] = []
            if (action.events) {
                events = action.events
                if (action.append) {
                    events = state.events.concat(action.events)
                }
            } else {
                events = state.events
            }

            return {
                ...state,
                eventsPending: false,
                events: events
            }
        case 'CLEAR_EVENTS':
            return {
                ...state,
                events: []
            }
        case 'REQUEST_EVENT_DETAILS':
            return {
                ...state,
                eventDetailsPending: true
            }
        case 'RECEIVE_EVENT_DETAILS':
            let eventDetailed: EventDetailed | null = null
            if (action.eventDetailed) {
                eventDetailed = action.eventDetailed
            } else {
                eventDetailed = state.selectedEvent
            }

            if (eventDetailed)
                getIsImperial(eventDetailed)

            return {
                ...state,
                eventDetailsPending: false,
                selectedEvent: eventDetailed
            }
        case 'SET_SELECTED_EVENTID':
            window.localStorage.setItem('selectedEventId', action.eventId.toString())
            let showNotifications = getInitialValue(`showNotifications${action.eventId}`, true)
            return {
                ...state,
                selectedEventId: action.eventId,
                showNotifications: showNotifications
            }
        case 'CLEAR_SELECTED_EVENT':
            window.localStorage.setItem('selectedEventId', '')
            return {
                ...state,
                selectedEvent: null
            }
        case 'REQUEST_NOTIFICATIONS':
            return {
                ...state,
                eventNotificationsPending: true
            }
        case 'RECEIVE_NOTIFICATIONS':
            if (state.selectedEvent) {
                let notifications: Notification[] = []
                if (action.notifications) {
                    notifications = action.notifications
                } else {
                    notifications = state.selectedEvent.notifications
                }
                return {
                    ...state,
                    eventNotificationsPending: false,
                    selectedEvent: {
                        ...state.selectedEvent,
                        notifications: notifications
                    }
                }
            }
            return state
        case 'ADD_NOTIFICATION':
            if (state.selectedEvent) {
                return {
                    ...state,
                    selectedEvent: {
                        ...state.selectedEvent,
                        notifications: [...state.selectedEvent?.notifications, action.notification]
                    }
                }
            }
            return state
        case 'SET_NIGHT_MODE':
            if (state.selectedEvent) {
                let nightMode = isUndefined(action.nightMode) ? null : action.nightMode
                return {
                    ...state,
                    selectedEvent: {
                        ...state.selectedEvent,
                        autoNightMode: action.nightMode,
                        nightMode: action.nightMode
                    }
                }
            }
            return state
        case 'SET_SHOW_NOTIFICATIONS': {
            window.localStorage.setItem(`showNotifications${state.selectedEventId}`, action.showNotifications.toString())
            return {
                ...state,
                showNotifications: action.showNotifications
            }
        }
        case 'RECEIVE_FAILED':
            return {
                ...state
            }
        case 'SET_EDITING_EVENT':
            return {
                ...state,
                editingEvent: action.editingEvent
            }
        case 'SET_HAZARD_CLASS':
            return {
                ...state,
                hazardClass: action.hazardClass
            }
        case 'SET_HAZARDS_UNREAD':
            return {
                ...state,
                hazardsUnread: action.hazardsUnread
            }
        default:
            return state
    }
}
