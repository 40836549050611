import * as Extent from 'ol/extent'
import { Action, Reducer } from 'redux'
import { AppThunkAction } from '.'
import { getInitialArrayValue, getInitialValue } from '../helper/StorageHelper'
import { Coordinate } from 'ol/coordinate'

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export type StagePointsDisplayType = 'hidden' | 'minimal' | 'extended'

export interface MapState {
    selectedEntry: number | null
    visibleStages?: number[]
    visibleEntries?: number[]
    lockedCamera: boolean
    rotation: number
    view: number[]
    showPlane: boolean
    showRoutes: boolean
    showOther: boolean
    showDebug: boolean
    showTransit: boolean
    moving: boolean
    stagePointsDisplay: StagePointsDisplayType
    distanceOnHover: boolean
    contentLoaded: number[]
    dangerousSpectators: number
    hideGoodVehicles: boolean
    latLon?: Coordinate
    showWeather?: boolean
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

interface ToggleStageVisibility { type: 'TOGGLE_STAGE_VISIBILITY', stage: number, eventId: number }
interface SetVisibleStages { type: 'SET_VISIBLE_STAGES', stages: number[], eventId: number }
interface ToggleEntryVisibility { type: 'TOGGLE_ENTRY_VISIBILITY', entry: number, eventId: number }
interface SetVisibleEntries { type: 'SET_VISIBLE_ENTRIES', entries: number[], eventId: number }
interface SetSelectedEntry { type: 'SET_SELECTED_ENTRY', entryId: number | null }
interface ClearSelectedEntry { type: 'CLEAR_SELECTED_ENTRY' }
interface SetLockedCamera { type: 'SET_LOCKED_CAMERA', lockedCamera: boolean }
interface SetRotation { type: 'SET_ROTATION', rotation: number }
interface SetView { type: 'SET_VIEW', view: number[] | undefined }
interface SetShowPlane { type: 'SET_SHOW_PLANE', value: boolean }
interface SetShowRoutes { type: 'SET_SHOW_ROUTES', value: boolean }
interface SetShowOther { type: 'SET_SHOW_OTHER', value: boolean }
interface SetShowTransit { type: 'SET_SHOW_TRANSIT', value: boolean }
interface SetShowDebug { type: 'SET_SHOW_DEBUG', value: boolean }
interface SetDistanceOnHover { type: 'SET_DISTANCE_ON_HOVER', value: boolean }
interface SetMoving { type: 'SET_MOVING', value: boolean }
interface SetStagePointsDisplay { type: 'SET_STAGE_POINTS_DISPLAY', value: StagePointsDisplayType }
interface ClearMapData { type: 'CLEAR_MAP_DATA' }
interface SetContentLoaded { type: 'SET_CONTENT_LOADED', value: number[] }
interface NewDangerousSpectator { type: 'NEW_DANGEROUS_SPECTATOR' }
interface SetHideGoodVehicles { type: 'SET_HIDE_GOOD_VEHICLES', value: boolean }
interface SetLatLon { type: 'SET_LAT_LON', value?: Coordinate }
interface ShowWeather { type: 'SHOW_WEATHER' }

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = ShowWeather | SetLatLon | SetHideGoodVehicles | NewDangerousSpectator | SetShowTransit | SetContentLoaded | SetDistanceOnHover | SetShowDebug | SetStagePointsDisplay | SetMoving | SetShowOther | ClearMapData | ToggleStageVisibility | SetVisibleStages | SetShowPlane | SetSelectedEntry | ClearSelectedEntry | SetLockedCamera | SetRotation | SetView | SetShowRoutes | SetVisibleEntries | ToggleEntryVisibility

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const mapActionCreators = {
    toggleStageVisibility: (stage: number, eventId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({
            type: 'TOGGLE_STAGE_VISIBILITY', stage, eventId
        })
    },
    setVisibleStages: (stages: number[], eventId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({
            type: 'SET_VISIBLE_STAGES', stages, eventId
        })
    },
    toggleEntryVisibility: (entry: number, eventId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({
            type: 'TOGGLE_ENTRY_VISIBILITY', entry, eventId
        })
    },
    setVisibleEntries: (entries: number[], eventId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({
            type: 'SET_VISIBLE_ENTRIES', entries, eventId
        })
    },
    setSelectedEntry: (entryId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({
            type: 'SET_SELECTED_ENTRY', entryId
        })
    },
    clearSelectedEntry: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'CLEAR_SELECTED_ENTRY' })
    },
    setLockedCamera: (lockedCamera: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({
            type: 'SET_LOCKED_CAMERA', lockedCamera
        })
    },
    setRotation: (rotation: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({
            type: 'SET_ROTATION', rotation
        })
    },
    setView: (view: number[] | undefined): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({
            type: 'SET_VIEW', view
        })
    },
    setShowPlane: (value: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({
            type: 'SET_SHOW_PLANE', value
        })
    },
    setShowRoutes: (value: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({
            type: 'SET_SHOW_ROUTES', value
        })
    },
    setShowOther: (value: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({
            type: 'SET_SHOW_OTHER', value
        })
    },
    setDistanceOnHover: (value: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({
            type: 'SET_DISTANCE_ON_HOVER', value
        })
    },
    setShowDebug: (value: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({
            type: 'SET_SHOW_DEBUG', value
        })
    },
    setShowTransit: (value: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({
            type: 'SET_SHOW_TRANSIT', value
        })
    },
    setMoving: (value: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({
            type: 'SET_MOVING', value
        })
    },
    setStagePointsDisplay: (value: StagePointsDisplayType): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({
            type: 'SET_STAGE_POINTS_DISPLAY', value
        })
    },
    setContentLoaded: (value: number[]): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({
            type: 'SET_CONTENT_LOADED', value
        })
    },
    clearMapData: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({
            type: 'CLEAR_MAP_DATA'
        })
    },
    newDangerousSpectator: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({
            type: 'NEW_DANGEROUS_SPECTATOR'
        })
    },
    setHideGoodVehicles: (value: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({
            type: 'SET_HIDE_GOOD_VEHICLES', value
        })
    },
    setLatLon: (value?: Coordinate): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({
            type: 'SET_LAT_LON', value
        })
    },
    showWeather: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({
            type: 'SHOW_WEATHER'
        })
    }
}

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.
const unloaded: MapState = {
    selectedEntry: null,
    visibleStages: [],
    visibleEntries: undefined,
    lockedCamera: false,
    rotation: 0,
    view: [],
    showPlane: false,
    showRoutes: false,
    showOther: false,
    showDebug: false,
    showTransit: getInitialValue('stagesShowTransit', false) as boolean,
    moving: false,
    stagePointsDisplay: getInitialValue('stagePointsDisplay', 'extended') as StagePointsDisplayType,
    distanceOnHover: getInitialValue('stagesDistanceOnHover', false) as boolean,
    contentLoaded: [0, 0, 0, 0],
    dangerousSpectators: 0,
    hideGoodVehicles: false
}

const toggleArrayItem = (a: number[], v: number) => {
    var i = a.indexOf(v)
    if (i === -1)
        a.push(v)
    else
        a.splice(i, 1)
}

export const reducer: Reducer<MapState> = (state: MapState | undefined, incomingAction: Action): MapState => {
    if (state === undefined) return unloaded
    const action = incomingAction as KnownAction

    switch (action.type) {
        case 'TOGGLE_STAGE_VISIBILITY':
            let newStages = state.visibleStages ? [...state.visibleStages] : []
            toggleArrayItem(newStages, action.stage)
            window.localStorage.setItem(`mapVisibleStages-${action.eventId}`, JSON.stringify(newStages))
            return {
                ...state,
                visibleStages: newStages
            }
        case 'SET_VISIBLE_STAGES':
            window.localStorage.setItem(`mapVisibleStages-${action.eventId}`, JSON.stringify(action.stages))
            return {
                ...state,
                visibleStages: action.stages
            }
        case 'TOGGLE_ENTRY_VISIBILITY':
            let newEntries = state.visibleEntries ? [...state.visibleEntries] : []
            toggleArrayItem(newEntries, action.entry)
            window.localStorage.setItem(`mapVisibleEntries-${action.eventId}`, JSON.stringify(newEntries))
            return {
                ...state,
                visibleEntries: newEntries
            }
        case 'SET_VISIBLE_ENTRIES':
            window.localStorage.setItem(`mapVisibleEntries-${action.eventId}`, JSON.stringify(action.entries))
            return {
                ...state,
                visibleEntries: action.entries
            }
        case 'SET_SELECTED_ENTRY':
            return {
                ...state,
                selectedEntry: action.entryId
            }
        case 'CLEAR_SELECTED_ENTRY':
            return {
                ...state,
                selectedEntry: -1
            }
        case 'SET_ROTATION':
            return {
                ...state,
                rotation: action.rotation
            }
        case 'SET_VIEW':
            if (action.view) {
                return {
                    ...state,
                    view: [...action.view]
                }
            }
            return {
                ...state
            }
        case 'SET_SHOW_PLANE':
            return {
                ...state,
                showPlane: action.value
            }
        case 'SET_SHOW_ROUTES':
            return {
                ...state,
                showRoutes: action.value
            }
        case 'SET_SHOW_OTHER':
            return {
                ...state,
                showOther: action.value
            }
        case 'SET_DISTANCE_ON_HOVER':
            return {
                ...state,
                distanceOnHover: action.value
            }
        case 'SET_SHOW_DEBUG':
            return {
                ...state,
                showDebug: action.value
            }
        case 'SET_SHOW_TRANSIT':
            window.localStorage.setItem(`stagesShowTransit`, JSON.stringify(action.value))
            return {
                ...state,
                showTransit: action.value
            }
        case 'SET_MOVING':
            return {
                ...state,
                moving: action.value
            }
        case 'SET_STAGE_POINTS_DISPLAY':
            window.localStorage.setItem(`stagePointsDisplay`, JSON.stringify(action.value))
            return {
                ...state,
                stagePointsDisplay: action.value
            }
        case 'SET_CONTENT_LOADED':
            return {
                ...state,
                contentLoaded: action.value
            }
        case 'CLEAR_MAP_DATA':
            return {
                ...state,
                visibleEntries: [],
                visibleStages: []
            }
        case 'NEW_DANGEROUS_SPECTATOR':
            return {
                ...state,
                dangerousSpectators: state.dangerousSpectators + 1
            }
        case 'SET_LOCKED_CAMERA':
            return {
                ...state,
                lockedCamera: action.lockedCamera
            }
        case 'SET_HIDE_GOOD_VEHICLES':
            return {
                ...state,
                hideGoodVehicles: action.value
            }
        case 'SET_LAT_LON':
            return {
                ...state,
                latLon: action.value
            }
        case 'SHOW_WEATHER':
            return {
                ...state,
                showWeather: !state.showWeather
            }
        default:
            return state
    }
}
