import { Action, Reducer } from 'redux'
import { AppThunkAction } from '.'

import { DateTime } from 'luxon'
import * as Api from '../api/Unit'
import { RacingStatus, SafetyStatus } from './Entry'

export enum EquipmentStatus {
    NeedsChecking = 0,
    NeedsRepair = 1,
    TestedAndWorking = 2,
    Dead = 3,
    Watch = 4
}

export enum RadioType {
    None = 0,
    XBEE = 1,
    RFD = 2,
    'RFD-EU' = 3
}

export enum ChannelType {
    DirectIp = 0,
    KoreEmail = 1,
    SasEmail = 2,
    Gsm = 3,
    Uhf = 4,
    UhfRepeater = 5,
    Replayed = 6
}

export const ChannelName = ['Direct IP', 'Kore Email', 'SAS Email', 'GSM', 'UHF', 'UHF Repeater', 'Replayed',
    'Lite', 'Direct IP (SB)', 'Kore Email (SB)', 'SAS Email (SB)', 'GSM (SB)', 'Replayed (SB)', 'Lite (SB)']

export enum MessageType {
    NoPayload = 0,
    Tracking = 1,
    StartTime = 2,
    IssuedStartTime = 3,
    FinishTime = 4,
    RequestedStageTime = 5,
    Split = 6,
    TimeControl = 7,
    Hazard = 8,
    RepeatHazard = 9,
    SpeedRestriction = 10,
    Chicane = 11,
    QuietZone = 12,
    Speeding = 13,
    FaultCode = 14,
    Setup = 15,
    MTAcknowledge = 16,
    MTTextResponse = 17,
    StageReport = 18,
    BuoyPoint = 19,
    CoursePoint = 20,
    CoursePointCheck = 21,
    BeamSetup = 22,
    BeamTime = 23,
    BeaconPoint = 24,
    BeamRepeatTime = 25,
    Relay = 26,
    IssuedStartTimeReceived = 32,
    FinishTimeV2 = 33,
    TextMessage = 34,
    TimeControlV2 = 35,
    SetupII = 36,
    SetupIII = 37,
    ZeroPoint = 38,
    CarFinishTimeBeam = 39,
    FinishTimeV3 = 40,
    SplitV2 = 41,
    FiaCan = 42,
    TrackingWithTiming = 43,
    FinishTimeFromSurface = 48,
    IssuedStartTimeReceivedV2 = 49,
    SpeedingV2 = 50,
    TyreChoice = 51,
    TimeControlV3 = 52,
    TrackingWithHazard = 53,
    Debug = 55,
    GSMInfo = 56,
    MTAcknowledgeV2 = 57,
    Setup24 = 58,
    ManualTime = 59,
    BoatSpeeding = 70,
    BeamBreak = 160
}

export enum LiteMessageType {
    // Messages sent through the Gateway
    // Connect,
    Tracking = 1,
    Disconnect = 2,
    //  ConnectAck = 3,
    //  DisconnectAck ,
    TextMessage = 5,
    FlagMessage = 6,
    MtAck = 7,
    TextResponse = 8,
    //  Speeding,
    SpeedingCapture = 10,
    TextResponseAck = 11,

    // Messages sent through the API
    Download = 12,
    StartTracking = 13,
    EndTracking = 14,
    OnStage = 15,
    OffStage = 16,
    StartSpeeding = 17,
    EndSpeeding = 18,
    Background = 19,
    Foreground = 20,
    Tamper = 21,
    Debug = 22,
    NetworkFound = 23,
    NetworkLost = 24,
    GPSFound = 25,
    GPSLost = 26,
    ReverseDirection = 27,
}

export enum IridiumSessionStatus {
    Success = 0,
    MtTooLarge = 1,
    LocationUnacceptable = 2,
    SbdTimeout = 10,
    MoTooLarge = 12,
    RfLinkLoss = 13,
    ImeiProtocolAnomaly = 14,
    ImeiProhibited = 15,
    Unknown = 16
}

export enum ElectricalStatus {
    ChargingEngineOn = 0,
    ChargingEngineOff = 1,
    OnBattery = 2
}

export enum GpsStatus {
    NoSignal = 0,
    Signal = 1
}

export enum UnitOfMeasurement {
    Imperial = 0,
    Metric = 1
}

export enum RequestedStatus {
    None = 0,
    Asleep = 1,
    Awake = 2
}

export enum UnitVersionType {
    Archived = 0,
    Failsafe = 1,
    Production = 2
}

export interface UnitProfile {
    unitProfileId: number
    name: string
    gforceTrigger: number
    rangeLimit: number
    stageTrackingDistance: number
    transitTrackingDistance: number
    lowSpeedThreshold: number
    lowSpeedTime: number
    transitSpeedThreshold: number
    transitSpeedTime: number
    buttonsEnabled: boolean
    lostEnabled: boolean
    stopToStart: boolean
    stageSpeedThreshold: number
    stageSpeedTime: number
    unitOfMeasurement: UnitOfMeasurement
    logPeriod: number
    overtakeEnabled: boolean
    displayGate: number
    maxTimeBetweenTransmission: number
    closingSpeed: number
    distanceForTransmission: number
    gateDistance: number
    deductTime: boolean
    speedRestriction: boolean
    fia: boolean
    winterTrial: boolean
    hazardByTrips: boolean
    showDirection: boolean
    showAverage: boolean
    gsmTrackingPeriodStage: number
    gsmTrackingPeriodTransport: number
    finishByTrips: boolean
    disableCourseCar: boolean
}

export const blankUnitProfile: UnitProfile = {
    unitProfileId: -1,
    name: '',
    gforceTrigger: 14,
    rangeLimit: 500,
    stageTrackingDistance: 3,
    transitTrackingDistance: 5,
    lowSpeedThreshold: 30,
    lowSpeedTime: 3,
    transitSpeedThreshold: 110,
    transitSpeedTime: 5,
    buttonsEnabled: false,
    lostEnabled: true,
    stopToStart: false,
    stageSpeedThreshold: 100,
    stageSpeedTime: 5,
    unitOfMeasurement: UnitOfMeasurement.Imperial,
    logPeriod: 1,
    overtakeEnabled: false,
    displayGate: 30,
    maxTimeBetweenTransmission: 4,
    closingSpeed: 70,
    distanceForTransmission: 50,
    gateDistance: 10,
    deductTime: false,
    speedRestriction: false,
    fia: false,
    winterTrial: false,
    hazardByTrips: false,
    showDirection: false,
    showAverage: true,
    gsmTrackingPeriodStage: 20,
    gsmTrackingPeriodTransport: 10,
    finishByTrips: false,
    disableCourseCar: false
}

export interface Unit {
    unitId: number
    imeinumber: number
    serialNumber: number
    faultFlag: boolean
    alertFlag: boolean
    dataRetrievalFlag: boolean
    iridiumStatus: boolean
    gsmImei: string
    sim: string
    comment: string
    lastServiceDate?: Date
    unitVersionId?: number
    unitStatus: EquipmentStatus
    isAvailable: boolean
    iriConnected: boolean
    gsmConnected: boolean
    region: string
    radioType: RadioType
}

export interface UnitBasic {
    serialNumber: number
    scanned: string
}

export interface MoMessage {
    moMessageId: number
    imei: number
    serialNumber: number
    channel: ChannelType
    rawData: string
    payload: string
    hash: number
    receivedTimestamp: string
    sentTimestamp: string
    messageType: MessageType
    processedTimestamp: string
    protocolRevisionNumber?: number
    messageLength?: number
    cdrReference?: number
    sessionStatus?: IridiumSessionStatus
    momsn?: number
    mtmsn?: number
    iridiumTimestamp?: string
    cepRadius?: number
    lat?: number
    lng?: number
    ipAddress: string
    processed: boolean
    stage: number
    distance: number
    bearing: number
    speed: number
    latitude: number
    longitude: number
    safetyStatus: SafetyStatus
    gpsStatus: GpsStatus
    electricalStatus: ElectricalStatus
    racingStatus: RacingStatus
    details: string
    failed: boolean
}

export interface LiteMessage {
    liteMessageId: number
    identifier: number
    type: string
    sequence: number
    sentTimestamp: string
    receivedTimestamp: string
    processedTimestamp: string
    speed?: number
    latitude: number
    longitude: number
    gps: boolean
    stages: number[]
    details: string
}

export interface UnitFaultModel {
    unitFaultId: number
    note: string
    userName: string
    enteredTime: string
    unitFaultCodeId: number
    faultName: string
    serialNumber: number | null
    lastScanLocation: string
    lastTested: string
}

export const blankUnitFault: UnitFaultModel = {
    unitFaultId: -1,
    note: '',
    userName: '',
    enteredTime: '',
    unitFaultCodeId: -1,
    faultName: '',
    serialNumber: null,
    lastScanLocation: '',
    lastTested: '0001-01-01T00:00:00'
}

export interface UnitFaultCode {
    unitFaultCodeId: number
    name: string
}

export interface UnitNoteModel {
    unitNoteId: number | null
    serialNumber: number | null
    note: string | null
    status: EquipmentStatus
    enteredTime: string
    user: string
}

export const blankUnitNote: UnitNoteModel = {
    unitNoteId: null,
    serialNumber: null,
    note: null,
    status: EquipmentStatus.NeedsChecking,
    enteredTime: '',
    user: ''
}

export interface UnitHistoryModel {
    unitHistoryId: number
    vehicleNumber: string
    serialNumber: number
    eventName: string
    enteredTime: string
    driverFirstName: string
    driverSurname: string
    navFirstName: string
    navSurname: string
}

export interface UnitListModel {
    unitId: number
    serialNumber: number | null
    unitStatus: EquipmentStatus
    gsmImei: string
    sim: string
    lastScanLocation: string
    comment: string
    unitVersionId: number | null
    versionName: string
    iridiumStatus: boolean
    requestedStatus: RequestedStatus
    faultFlag: boolean
    alertFlag: boolean
    isAvailable: boolean
    imeinumber: number | null
    region: string
    radioType: RadioType
}

export const blankUnit: UnitListModel = {
    unitId: -1,
    serialNumber: null,
    unitStatus: EquipmentStatus.TestedAndWorking,
    gsmImei: '',
    sim: '',
    lastScanLocation: '',
    comment: '',
    unitVersionId: null,
    versionName: '',
    iridiumStatus: true,
    requestedStatus: RequestedStatus.None,
    faultFlag: false,
    alertFlag: false,
    isAvailable: true,
    imeinumber: null,
    region: '',
    radioType: RadioType.None
}

export interface UnitVersions {
    unitVersionId: number | null
    name: string
    number: number
    unitVersionType: UnitVersionType
    modifiedTimestamp: string
    count: number
}

export const blankUnitVersion: UnitVersions = {
    unitVersionId: null,
    name: '',
    number: -1,
    unitVersionType: UnitVersionType.Archived,
    modifiedTimestamp: '',
    count: 0
}
// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface UnitState {
    pendingUnits: boolean
    units: Unit[]
    messages: MoMessage[]
    selectedMessage: MoMessage | null
    errorText: string
    selectedUnit: number | null
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

interface RequestUnits { type: 'REQUEST_UNITS' }
interface ReceiveUnits { type: 'RECEIVE_EVENT_UNITS', units: Unit[] }
interface ReceiveMessages { type: 'RECEIVE_MESSAGES', messages: MoMessage[], append: boolean }
interface ReceiveUnitsFailed { type: 'RECEIVE_UNITS_FAILED', errorText: string }
interface ClearUnits { type: 'CLEAR_UNITS' }
interface SelectMessage { type: 'SELECT_MESSAGE', selectedMessage: MoMessage | null }
interface SetSelectedUnit { type: 'SET_SELECTED_UNIT', selectedUnit: number | null }

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = SetSelectedUnit | RequestUnits | ReceiveUnits | ReceiveUnitsFailed | ReceiveMessages | ClearUnits | SelectMessage

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const unitActionCreators = {
    requestMessages: (skip: number, take: number, orderDesc: boolean, channel: string, showTracking: boolean, append: boolean, eventId?: number, unit?: number | null, messageType?: MessageType): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'REQUEST_UNITS' })
        Api.requestMessages(skip, take, orderDesc, showTracking, channel, eventId, unit, messageType)
            .then(result => dispatch({
                type: 'RECEIVE_MESSAGES',
                messages: result,
                append: append
            }))
            .catch((err: string) => {
                console.log(err)
                dispatch({
                    errorText: 'RECEIVE_UNITS_FAILED',
                    // errorText: err.replace(/['"]+/g, ''),
                    type: 'RECEIVE_UNITS_FAILED'

                })
            })
    },
    requestMessagesBasic: (unit: number, channel: string, start: DateTime, end: DateTime): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'REQUEST_UNITS' })
        Api.requestMessagesBasic(unit, channel, start, end)
            .then(result => dispatch({
                type: 'RECEIVE_MESSAGES',
                messages: result,
                append: false
            }))
            .catch((err: string) => {
                console.log(err)
                dispatch({
                    errorText: 'RECEIVE_UNITS_FAILED',
                    // errorText: err.replace(/['"]+/g, ''),
                    type: 'RECEIVE_UNITS_FAILED'

                })
            })
    },
    requesUnits: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'REQUEST_UNITS' })
        // Api.requestUnits()
        //     .then(result => dispatch({
        //         type: 'RECEIVE_EVENT_UNITS',
        //         units: result
        //     }))
        //     .catch((err: string) => {
        //         console.log(err)
        //         dispatch({
        //             errorText: 'RECEIVE_UNITS_FAILED',
        //             // errorText: err.replace(/['"]+/g, ''),
        //             type: 'RECEIVE_UNITS_FAILED'

        //         })
        //     })
    },
    clearUnits: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'CLEAR_UNITS' })
    },
    selectMessage: (selectedMessage: MoMessage | null): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'SELECT_MESSAGE', selectedMessage })
    },
    setSelectedUnit: (selectedUnit: number | null): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'SET_SELECTED_UNIT', selectedUnit })
    }
}

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.
const unloaded: UnitState = {
    pendingUnits: false,
    units: <Unit[]>[],
    messages: <MoMessage[]>[],
    selectedMessage: null,
    errorText: '',
    selectedUnit: null
}

export const reducer: Reducer<UnitState> = (state: UnitState | undefined, incomingAction: Action): UnitState => {
    if (state === undefined) return unloaded
    const action = incomingAction as KnownAction

    switch (action.type) {
        case 'REQUEST_UNITS':
            return {
                ...state,
                pendingUnits: true
            }
        case 'RECEIVE_EVENT_UNITS':
            let units = action.units
            return {
                ...state,
                pendingUnits: false,
                units: units
            }
        case 'RECEIVE_MESSAGES':
            let messages = action.messages
            if (action.append) {
                messages = state.messages.concat(action.messages)
            }
            return {
                ...state,
                pendingUnits: false,
                messages: messages
            }
        case 'RECEIVE_UNITS_FAILED':
            return {
                ...state,
                pendingUnits: false,
                errorText: action.errorText
            }
        case 'CLEAR_UNITS':
            return {
                ...state,
                units: [],
                messages: [],
                errorText: '',
                selectedMessage: null
            }
        case 'SELECT_MESSAGE':
            return {
                ...state,
                selectedMessage: action.selectedMessage
            }
        case 'SET_SELECTED_UNIT':
            return {
                ...state,
                selectedUnit: action.selectedUnit
            }
        default:
            return state
    }
}



