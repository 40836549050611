import { SvgIcon } from '@mui/material'
import * as React from 'react'

interface Props {
    background?: string
    foreground?: string
    style?: React.CSSProperties
    className?: string
}

export const HazardIcon: React.FunctionComponent<Props> = (props) => {
    const { background, foreground, style, className } = props

    return <SvgIcon
        viewBox={'0 0 481.33 430.78'}
        style={{ ...style, filter: 'drop-shadow( 0 0 3px hsla(0, 0%, 0%, 0.6))' }}

    >
        <polygon
            className={className}
            style={{
                fill: background ?? '#fdd835',
                stroke: background ?? '#fdd835',
                strokeLinecap: 'round',
                strokeLinejoin: 'round',
                strokeWidth: '53px',
                boxShadow: '20px 20px #000'
            }}
            points='240.67 26.5 26.5 404.28 454.83 404.28 240.67 26.5' />
        <g style={{
            fill: foreground ?? '#000',
            isolation: 'isolate'
        }}>
            <path d="M219.64,266.93l-5.76-86.31q-1.63-25.22-1.63-36.21,0-15,7.84-23.33t20.63-8.38q15.5,0,20.72,10.72t5.23,30.9a238.72,238.72,0,0,1-1.26,24.14l-7.75,88.83q-1.26,15.85-5.41,24.32t-13.69,8.47q-9.74,0-13.51-8.2T219.64,266.93Zm20,118.55a28.38,28.38,0,0,1-19.19-7.11q-8.2-7.12-8.2-19.91a26.77,26.77,0,0,1,27-26.85,26.69,26.69,0,0,1,19.37,7.84,25.61,25.61,0,0,1,8,19q0,12.62-8.11,19.82A27.61,27.61,0,0,1,239.64,385.48Z" />
        </g>
    </SvgIcon>
}