import EventIcon from '@mui/icons-material/Event'
import PrintIcon from '@mui/icons-material/Print'
import { Box, Button, IconButton, Menu, MenuItem, SortDirection, Stack, TableCell, ToggleButton, ToggleButtonGroup, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material'
import { amber, green, red } from '@mui/material/colors'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { Dispatch } from 'redux'
import { requestEvents } from '../../api/Event'
import { CountryFlagIcon } from '../../assets/Icons/CountryFlagIcon'
import { exportTableXLSX } from '../../helper/reportHelper'
import { getLuxonTimeFromString } from '../../helper/timeHelper'
import { useColumns } from '../../helper/useColumns'
import { useStickyState } from '../../helper/useStickyState'
import { useTabTitle } from '../../helper/useTabTitle'
import { ApplicationState } from '../../store'
import { CompanyEditor, EventViewer, hasAccess } from '../../store/Account'
import { Championship } from '../../store/Championship'
import { eventActionCreators, EventBasic, EventPhase, EventStatus } from '../../store/Event'
import { itineraryActionCreators } from '../../store/Itinerary'
import { countryList } from '../@Global/CountryList'
import { CreateButton } from '../@Global/CreateButton'
import AppIcon from '../@Global/images/iap.png'
import { ChampionshipMultiSelect } from '../Inputs/Selectors/ChampionshipMultiSelect'
import { BaseTable, BodyRow } from '../Tables/BaseTable'
import { Filters } from '../Tables/Filters'
import { SearchBox } from '../Tables/SearchBox'
import { TableContainer } from '../Tables/TableContainer'
import { EventCreateDialog } from './EventCreateDialog'
import { PhaseSlider } from './PhaseSlider'
import { PhaseText } from './PhaseText'
import { RecentEvents } from './RecentEvents'
import { EventSummariesDialog } from './EventSummariesDialog'
import { CountryMultiSelect } from '../Inputs/Selectors/CountryMultiSelect'
import DateRangeIcon from '@mui/icons-material/DateRange'
import { generateCsv, mkConfig, download as csvDownload } from 'export-to-csv'
import { DateTime } from 'luxon'

type Props = {
    username: string
}

export const EventTable: React.FunctionComponent<Props> = ({ username }) => {
    const { eventsPending, events, selectedEvent } = useSelector((state: ApplicationState) => state.event)
    const globalTimeZoneOffset = useSelector((state: ApplicationState) => state.global.timeZoneOffset)

    useTabTitle('Events', selectedEvent?.name)

    const theme = useTheme()
    const screenMedium = useMediaQuery(theme.breakpoints.down('lg'))
    const screenSmall = useMediaQuery(theme.breakpoints.down('md'))

    const [searchText, setSearchText] = useState<string>('')
    const [orderBy, setOrderBy] = useStickyState<string>('desc', 'eventsTableOrder')
    const [download, setDownload] = useState<boolean>(false)
    const [phaseRange, setPhaseRange] = useStickyState<number[]>([1, 5], 'phaseRange')
    const [loading, setLoading] = useState<boolean>(false)
    const [recentlySelected, setRecentlySelected] = useStickyState<EventBasic[]>([], `recentlySelectedEvents${username}`)
    const [createModelOpen, setCreateModelOpen] = useState<boolean>(false)
    const [statusToggles, setStatusToggles] = useStickyState<EventStatus[]>([EventStatus.Confirmed], 'eventStatusToggles')
    const [selectedChampionships, setSelectedChampionships] = useStickyState<Championship[]>([], 'eventSelectedChampionships')
    const [selectedCountries, setSelectedCountries] = useStickyState<string[]>([], 'eventSelectedCountries')
    const [printLoading, setPrintLoading] = useState<boolean>(false)
    const [openDownloadMenu, setOpenDownloadMenu] = useState<null | HTMLElement>(null)
    const [openEventSummaries, setOpenEventSummaries] = useState<boolean>(false)

    const dispatch: Dispatch<any> = useDispatch()
    const navigate = useNavigate()
    const roles = useSelector((state: ApplicationState) => state.identity.roles)
    const location = useLocation()

    const itemsPerPage = 30
    let timeout: NodeJS.Timeout
    const isPublic = username == 'PublicUser'

    const [columns, displayColumns, onDisplayColumnsChange] = useColumns([
        { id: 'Name', label: 'Name', sort: false, order: 0, display: true },
        { id: 'AppEnabled', label: '', sort: false, order: 1, display: true, alignRight: true },
        { id: 'Championships', label: 'Championships', sort: false, order: 2, display: true },
        { id: 'Start', label: 'Dates', sort: true, order: 3, display: true },
        { id: 'Phase', label: 'Phase', sort: false, order: 4, display: true },
        { id: 'Entries', alignRight: true, label: 'Expected/Actual Entries', sort: false, order: 5, display: true, hidden: !hasAccess(CompanyEditor, roles) }
    ], 'eventsDisplayTable')

    const downloadEvents = async (skip: number, take: number, append: boolean) => {
        if (take > 0 && !eventsPending) {
            let _selectedChampionships = isPublic ? [] : selectedChampionships.map(x => x.championshipId)
            dispatch(eventActionCreators.requestEvents(skip, take, searchText, orderBy, phaseRange, append, statusToggles, _selectedChampionships, selectedCountries))
        }
    }

    const popout = location.pathname.includes('popout')

    useEffect(() => {
        return () => {
            dispatch(eventActionCreators.clearEvents())
        }
    }, [])

    useEffect(() => {
        downloadEvents(0, itemsPerPage, false)
    }, [orderBy, phaseRange, searchText, statusToggles, selectedChampionships, selectedCountries])

    useEffect(() => {
        if (download) {
            const rows: any[] = []
            events.forEach(e => {
                let row: string[] = []
                columns.forEach((x, i) => {
                    switch (x.label) {
                        case 'Name': row.push(`\t${e.name}`)
                            break
                        case 'Start': row.push(`${e.start.toString()}`)
                            break
                        case 'Finish': row.push(`${e.finish.toString()}`)
                            break
                        case 'Phase': row.push(`${EventPhase[e.phase]}`)
                            break
                        case 'ExpectedVehicles': row.push(`${e.expectedVehicles}`)
                            break
                        case 'ExpectedCourseVehicles': row.push(`${e.expectedCourseVehicles}`)
                            break
                    }
                })
                rows.push(row)
            })
            const csvConfig = mkConfig({
                useKeysAsHeaders: true,
                filename: `EventsList`,
                showColumnHeaders: true,
                useBom: false,
                fieldSeparator: ',',
                quoteStrings: true,
            })
            csvDownload(csvConfig)(generateCsv(csvConfig)(rows))
            setDownload(false)
        }
    }, [events])

    useEffect(() => {
        if (eventsPending) {
            setLoading(true)
        } else {
            if (loading) {
                timeout = setTimeout(() => {
                    setLoading(false)
                }, 400)
            }
        }
        return () => {
            clearTimeout(timeout)
        }
    }, [eventsPending])

    const onCreateClick = () => {
        setCreateModelOpen(true)
    }

    const loadNext = async () => {
        downloadEvents(events.length, itemsPerPage, true)
    }

    const onChangeOrder = (id: string) => {
        if (id == 'Start') {
            setOrderBy(orderBy == 'desc' ? 'asc' : 'desc')
        }
    }

    const onSelectEvent = async (event: EventBasic) => {
        let _recentlySelected = recentlySelected.reverse()
        if (_recentlySelected.find(x => x.eventId == event.eventId)) {
            _recentlySelected = _recentlySelected.filter(x => x.eventId != event.eventId)
        }
        if (_recentlySelected.length > 3) {
            _recentlySelected = _recentlySelected.slice(_recentlySelected.length - 3, _recentlySelected.length)
        }
        setRecentlySelected(_recentlySelected.concat(event).reverse())
        dispatch(itineraryActionCreators.clearStages())
        dispatch(itineraryActionCreators.clearStagePoints())
        if (popout)
            window.open(`/events/${event.eventId}/map`, '_blank')
        else
            navigate(`/events/${event.eventId}/details#overview`)
    }

    const onRemoveEvent = (eventId) => {
        setRecentlySelected(recentlySelected.filter(x => x.eventId != eventId))
    }

    const onPhaseChange = (phase: number[]) => {
        setPhaseRange(phase)
    }

    const onCreateModalClose = (eventId?: number) => {
        setCreateModelOpen(false)
        if (eventId) {
            navigate(`/events/${eventId}/details#overview`)
        }
    }

    const onToggleChange = (event: React.MouseEvent<HTMLElement>, newToggles: EventStatus[]) => {
        setStatusToggles(newToggles)
    }

    const onExportTable = async () => {
        setPrintLoading(true)
        let result = await requestEvents(-1, -1, searchText, orderBy, phaseRange, statusToggles, selectedChampionships.map(x => x.championshipId), selectedCountries)

        let _result: any[] = []
        result.forEach((x, i) => {
            console.log(x)
            let competitiveVehiclesText = `${x.expectedVehicles ? x.expectedVehicles : '0'}`
            let courseVehiclesText = `${x.expectedCourseVehicles ? ' + ' + x.expectedCourseVehicles : ''}`
            let country = countryList.find(y => y.code == x.countryCode)
            _result.push({
                Name: x.name,
                Country: country?.name ?? x.countryCode,
                Dates: `${getLuxonTimeFromString(x.start).toFormat('yyyy/MM/dd')} - ${getLuxonTimeFromString(x.finish).toFormat('yyyy/MM/dd')}`,
                Championships: x.championships.map(y => y.code).join(', '),
                Entries: `${competitiveVehiclesText}${courseVehiclesText}`,
                CurrentVehicleCount: `${x.competitiveVehicleCount ? x.competitiveVehicleCount : '0'}${x.courseVehicleCount ? ' + ' + x.courseVehicleCount : ''}`
            })
        })
        exportTableXLSX(_result, [
            { id: 'Name', label: 'Name', order: 0, display: null },
            { id: 'Country', label: 'Country', order: 0, display: null },
            { id: 'Dates', label: 'Dates', order: 0, display: null },
            { id: 'Championships', label: 'Championships', order: 0, display: null },
            { id: 'Entries', label: 'Expected', order: 0, display: null },
            { id: 'CurrentVehicleCount', label: 'Actual', order: 0, display: null }
        ], 'Events')
        setPrintLoading(false)
    }

    const bodyRows: BodyRow[] = events.map((row, index) => {
        let dates = ''
        const start = getLuxonTimeFromString(row.start, row.timeZone, globalTimeZoneOffset)
        const finish = getLuxonTimeFromString(row.finish, row.timeZone, globalTimeZoneOffset)
        switch (row.phase) {
            case EventPhase.Building:
            case EventPhase.Preparation: {
                dates = `${start.toFormat("d LLL")} (${Math.floor(finish.diff(start, 'days').days + 1)} day${Math.floor(finish.diff(start, 'days').days) > 0 ? 's' : ''})`
                break
            }
            case EventPhase.Live: {
                dates = `Ends ${finish.toRelative()}`
                break
            }
            case EventPhase.Planning:
            case EventPhase.Post:
            case EventPhase.Archived: {
                dates = `${start.toFormat('yyyy/MM/dd')} - ${finish.toFormat('yyyy/MM/dd')}`
                break
            }
        }

        let competitiveVehiclesText = `${row.expectedVehicles ? row.expectedVehicles : '0'}`
        let courseVehiclesText = `${row.expectedCourseVehicles ? ' + ' + row.expectedCourseVehicles : ''}`

        let currentVehiclesText = `${row.competitiveVehicleCount ? row.competitiveVehicleCount : '0'}`
        let currentCourseText = `${row.courseVehicleCount ? ' + ' + row.courseVehicleCount : ''}`

        return {
            key: `${row.eventId}`,
            onClick: () => {
                onSelectEvent(row)
            },
            style: { cursor: 'pointer' },
            content: [
                <TableCell key='Name' sx={{ whiteSpace: 'nowrap' }}>
                    <Stack component={Link} to={!popout ? `/events/${row.eventId}/details#overview` : ''} direction='row' alignItems='center'>
                        <CountryFlagIcon
                            size={!screenSmall ? 48 : 40}
                            code={row.countryCode}
                            style={{ marginRight: '10px' }}
                        />
                        <Stack>
                            <Typography style={{
                                fontSize: !screenSmall ? 18 : 14,
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                lineHeight: 1.5
                            }}>
                                {row.name}
                            </Typography>
                            <Typography style={{
                                color: 'rgb(189, 189, 189)',
                                whiteSpace: 'nowrap',
                                fontSize: !screenSmall ? 12 : 11,
                                lineHeight: 1.5
                            }}>
                                {row.location}
                            </Typography>
                        </Stack>
                    </Stack>
                </TableCell>,
                <TableCell key='AppEnabled' align='right' sx={{ width: 0 }}>
                    <Stack component={Link} to={`/events/${row.eventId}/details#overview`} >
                        {row.appEnabled
                            ? <Tooltip title='App is enabled'>
                                <img src={AppIcon} height={32} width={18.5} />
                            </Tooltip>
                            : ''}
                    </Stack>
                </TableCell>,
                <TableCell key='Championships'>
                    <Stack component={Link} to={`/events/${row.eventId}/details#overview`} flexDirection='row'>
                        {
                            row.championships.map((x, i) => {
                                let more = i < row.championships.length - 1
                                return <Tooltip key={x.championshipId} title={`${x.name} ${x.year}`}>
                                    <Typography mr={more ? '3px' : '0'}>{x.code}{more ? ', ' : ''}</Typography>
                                </Tooltip>
                            })
                        }
                    </Stack>
                </TableCell>,
                <TableCell key='Start'>
                    <Link to={`/events/${row.eventId}/details#overview`} >
                        <Typography>{dates}</Typography>
                    </Link>
                </TableCell>,
                <TableCell key='Phase' sx={{ py: '2px' }}>
                    <Link to={`/events/${row.eventId}/details#overview`} >
                        <PhaseText phase={row.phase} status={row.status} isTest={row.isTest} />
                    </Link>
                </TableCell>,
                <TableCell key='Entries' style={{ textAlign: 'right' }}>
                    <Link to={`/events/${row.eventId}/details#overview`} >
                        <Stack direction='row' alignItems='center' justifyContent='flex-end'>
                            <Box fontSize='16px'>
                                {competitiveVehiclesText}
                            </Box>
                            <Box fontSize='12px' ml={1} mb='2px' sx={{ alignSelf: 'flex-end', color: (theme) => theme.palette.text.secondary }}>
                                {courseVehiclesText}
                            </Box>
                        </Stack>
                        <Stack direction='row' alignItems='center' justifyContent='flex-end'>
                            <Box fontSize='16px'>
                                {currentVehiclesText}
                            </Box>
                            <Box fontSize='12px' ml={1} mb='2px' sx={{ alignSelf: 'flex-end', color: (theme) => theme.palette.text.secondary }}>
                                {currentCourseText}
                            </Box>
                        </Stack>
                    </Link>
                </TableCell>
            ]
        }
    })

    return (
        <TableContainer
            icon={<EventIcon />}
            heading={isPublic ? 'Public Events' : 'Events'}
            loadNext={loadNext}
            loading={loading}
            dataLength={events.length}
            table={<BaseTable
                headCells={displayColumns}
                bodyRows={bodyRows}
                orderBy={'Start'}
                orderByDirection={orderBy as SortDirection}
                onChangeOrder={onChangeOrder}
            />}
            fab={<CreateButton
                allowedRoles={[CompanyEditor]}
                onClick={onCreateClick}
            />}
            topLeftContent={
                <Stack direction='row' alignItems='center' spacing={1}>
                    {roles.includes(CompanyEditor)
                        ? <Button color='primary' onClick={() => { navigate('/championships') }}>
                            Manage Championships
                        </Button>
                        : <Box />}
                    {hasAccess(CompanyEditor, roles) && <IconButton
                        disabled={printLoading}
                        onClick={(e) => {
                            setOpenDownloadMenu(e.currentTarget)
                        }}
                    >
                        <PrintIcon />
                    </IconButton>}
                    <Menu
                        anchorEl={openDownloadMenu}
                        keepMounted
                        open={Boolean(openDownloadMenu)}
                        onClose={() => setOpenDownloadMenu(null)}
                    >
                        <MenuItem key='eventsTable' onClick={() => {
                            onExportTable()
                            setOpenDownloadMenu(null)
                        }}>
                            Events Table
                        </MenuItem>
                        <MenuItem key='eventSummaries' onClick={() => {
                            setOpenEventSummaries(true)
                            setOpenDownloadMenu(null)
                        }}>
                            Event Summaries
                        </MenuItem>
                    </Menu>
                </Stack>
            }>
            <Filters>
                <Stack spacing={2} flexWrap='wrap' height='100%' justifyContent='flex-end'>
                    <Stack direction='row' flexWrap='wrap' alignItems='center'>
                        <Box m={1} mr={3} mt={screenSmall ? 4 : 'auto'}>
                            <PhaseSlider
                                onPhaseChange={onPhaseChange}
                                initialValues={phaseRange}
                                style={{
                                    height: 36,
                                    position: 'relative',
                                    transform: screenMedium ? 'scale(0.9)' : '',
                                    transformOrigin: 'top left',
                                    bottom: 24,
                                    width: screenMedium ? '100%' : 'auto'
                                }}
                            />
                        </Box>
                        <Box m={1}>
                            {!isPublic && <ToggleButtonGroup
                                value={statusToggles}
                                onChange={onToggleChange}
                                size='small'
                            >
                                <ToggleButton value={EventStatus.Unconfirmed}>
                                    Unconfirmed
                                </ToggleButton>
                                <ToggleButton value={EventStatus.Confirmed} color='success' sx={{ color: green[500] }}>
                                    Confirmed
                                </ToggleButton>
                                <ToggleButton value={EventStatus.Postponed} color='warning' sx={{ color: amber[500] }}>
                                    Postponed
                                </ToggleButton>
                                <ToggleButton value={EventStatus.Cancelled} color='error' sx={{ color: red[500] }}>
                                    Cancelled
                                </ToggleButton>
                                {hasAccess(CompanyEditor, roles) && <ToggleButton value={EventStatus.Test}>
                                    Test
                                </ToggleButton>}
                            </ToggleButtonGroup>}
                        </Box>
                    </Stack>
                    {!isPublic && <Stack direction='row' spacing={2}>
                        <SearchBox placeholder='Search...' onChange={(s => setSearchText(s))} />
                        {hasAccess(CompanyEditor, roles) && <ChampionshipMultiSelect
                            selectedChampionships={selectedChampionships}
                            onChange={(x) => setSelectedChampionships(x)}
                            limit={true}
                        />}
                        <CountryMultiSelect
                            selectedCountries={selectedCountries}
                            onChange={(x) => setSelectedCountries(x)}
                            limit={true}
                        />
                    </Stack>}
                </Stack>
            </Filters>

            {recentlySelected.length > 0 && !screenMedium
                && <RecentEvents
                    recentlySelected={recentlySelected}
                    onSelectEvent={onSelectEvent}
                    onRemoveEvent={onRemoveEvent}
                />
            }
            <EventCreateDialog
                open={createModelOpen}
                onClose={onCreateModalClose}
            />
            <EventSummariesDialog
                open={openEventSummaries}
                onClose={() => setOpenEventSummaries(false)}
            />
        </TableContainer>
    )
}
