import * as Core from '.'
import { EquipmentModel, EquipmentLocationModel, EquipmentLogBasic, EquipmentType, EquipmentScanModel, ResponseModel, EquipmentLocation, UnreturnedEquipment, EquipmentRemaining, EquipmentLocationBasic, EquipmentFaultModel, EquipmentFaultCreateModel, EquipmentNoteModel, EquipmentNoteCreateModel } from '../store/Equipment'

type TableResults = {
    count: number
    rows: EquipmentLogBasic[] | EquipmentModel[]
}

export function requestEquipmentLogs(skip: number, take: number, searchText: string, order: string, selectedEvent: number, selectedLocation: number, selectedType: number, onlyLatest: boolean): Promise<TableResults> {
    return Core.get<TableResults>(`/equipment/equipment-log-table?skip=${skip}&take=${take}&search=${searchText}&order=${order}&selectedEvent=${selectedEvent}&selectedLocation=${selectedLocation}&selectedType=${selectedType}&onlyLatest=${onlyLatest}`)
}

export function requestEquipmentTypes(): Promise<EquipmentType[]> {
    return Core.get<EquipmentType[]>(`/equipment/equipment-type-table`)
}

export function requestEquipmentLocations(includeArchived?: boolean): Promise<EquipmentLocationBasic[]> {
    return Core.get<EquipmentLocationBasic[]>(`/equipment/equipment-locations${includeArchived ? '?includeArchived=true' : ''}`)
}

export function requestEquipmentLocationsTable(skip: number, take: number, searchText: string): Promise<EquipmentLocationModel[]> {
    return Core.get<EquipmentLocationModel[]>(`/equipment/equipment-locations-table?skip=${skip}&take=${take}&search=${searchText}`)
}

export function scanAllEquipment() {
    return Core.get(`/equipment/scan-all-equipment`)
}

export const createEquipmentLog = (model: EquipmentScanModel): Promise<ResponseModel> => {
    return Core.postWithResponse<EquipmentScanModel, ResponseModel>(`/equipment/create-equipment-log`, model)
}

export const deleteEquipmentLog = (identifier: string, typeId: number, equipmentLogId: number): Promise<Response> => {
    return Core.del(`/equipment/delete-equipment-scan-log?identifier=${identifier}&typeId=${typeId}&equipmentLogId=${equipmentLogId}`)
}

export function requestEquipments(skip: number, take: number, search: string, type: number): Promise<TableResults> {
    return Core.get<TableResults>(`/equipment/equipment-table?skip=${skip}&take=${take}&search=${search}&type=${type}`)
}

export const createEquipment = (model: EquipmentModel): Promise<Response> => {
    return Core.post(`/equipment/create-equipment`, model)
}

export const updateEquipment = (model: EquipmentModel): Promise<Response> => {
    return Core.post(`/equipment/update-equipment`, model)
}

export const deleteEquipment = (id: number, serialNumber: number | null, equipmentTypeId: number): Promise<Response> => {
    return Core.del(`/equipment/delete-equipment?id=${id}&serialNumber=${serialNumber}&equipmentTypeId=${equipmentTypeId}`)
}

export const updateEquipmentType = (model: EquipmentType): Promise<Response> => {
    return Core.post<EquipmentType>(`/equipment/update-equipment-type`, model)
}

export const deleteEquipmentType = (id: number, totalNumber: number): Promise<Response> => {
    return Core.del(`/equipment/delete-equipment-type?id=${id}&totalNumber=${totalNumber}`)
}

export const createEquipmentLocation = (model: EquipmentLocationModel): Promise<Response> => {
    return Core.post(`/equipment/create-equipment-location`, model)
}

export const updateEquipmentLocation = (model: EquipmentLocationModel): Promise<Response> => {
    return Core.post<EquipmentLocationModel>(`/equipment/update-equipment-location`, model)
}

export function getEquipmentTypes(): Promise<EquipmentType[]> {
    return Core.get<EquipmentType[]>(`/equipment/equipment-type`)
}

export const createEquipmentType = (model: EquipmentType): Promise<Response> => {
    return Core.post(`/equipment/create-equipment-type`, model)
}

export function getUnreturnedEqupments(eventId: number): Promise<UnreturnedEquipment[]> {
    return Core.get<UnreturnedEquipment[]>(`/equipment/get-unreturned-equipment-details?eventId=${eventId}`)
}

export function getEquipmentRemaining(eventId: number): Promise<EquipmentRemaining[]> {
    return Core.get<EquipmentRemaining[]>(`/equipment/get-equipment-remaining?eventId=${eventId}`)
}

export function getEquipmentFaults(skip: number, take: number, orderDesc: boolean, equipmentTypeId?: number): Promise<EquipmentFaultModel[]> {
    return Core.get<EquipmentFaultModel[]>(`/equipment/equipment-faults-table?skip=${skip}&take=${take}&orderDesc=${orderDesc}&equipmentTypeId=${equipmentTypeId}`)
}

export function createEquipmentFault(model: EquipmentFaultCreateModel): Promise<Response> {
    return Core.post(`/equipment/create-equipment-fault`, model)
}

export function getEquipmentNotes(skip: number, take: number, orderDesc: boolean, equipmentTypeId?: number): Promise<EquipmentNoteModel[]> {
    return Core.get<EquipmentNoteModel[]>(`/equipment/equipment-notes-table?skip=${skip}&take=${take}&orderDesc=${orderDesc}&equipmentTypeId=${equipmentTypeId}`)
}

export function createEquipmentNote(model: EquipmentNoteCreateModel): Promise<Response> {
    return Core.post(`/equipment/create-equipment-note`, model)
}
