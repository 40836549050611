import * as Core from '.'
import { Championship } from '../store/Championship'

export function requestChampionships(): Promise<Championship[]> {
    return Core.get<Championship[]>(`/championship/championships`)
}

export function requestChampionshipsTable(skip: number, take: number, searchText: string, order: string): Promise<Championship[]> {
    return Core.get<Championship[]>(`/championship/table?skip=${skip}&take=${take}&search=${searchText}&order=${order}`)
}

export function createChampionship(championship: Championship): Promise<Response> {
    return Core.post<Championship>(`/championship/create`, championship)
}

export function updateChampionship(championship: Championship): Promise<Response> {
    return Core.post<Championship>(`/championship/update`, championship)
}

export function deleteChampionship(championship: Championship): Promise<Response> {
    return Core.del(`/championship/delete`, championship.championshipId)
}