import { Box, IconButton, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, ListSubheader, Stack, Typography } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Clear'
import moment from 'moment'
import * as React from 'react'
import { EventBasic } from '../../store/Event'
import { CountryFlagIcon } from '../../assets/Icons/CountryFlagIcon'
import { PhaseText } from './PhaseText'

interface Props {
    recentlySelected: EventBasic[]
    onSelectEvent: (event: EventBasic) => void
    onRemoveEvent: (eventId: number) => void
}

export const RecentEvents: React.FunctionComponent<Props> = (props) => {
    const { recentlySelected, onSelectEvent, onRemoveEvent } = props

    return (
            <List
                dense
                sx={{
                    width: 'fit-content',
                    padding: '0 0 4px 20px',
                    marginTop: '-55px',
                    minWidth: '400px',
                    alignSelf: 'flex-start'
                }}
                subheader={
                    <ListSubheader disableGutters>
                        Recently Selected
                    </ListSubheader>
                }
            >
                {recentlySelected.map(x => {
                    let secondaryElement = <Stack direction='row' alignItems='center'>
                        <Typography variant='button' fontSize={16}>
                            {moment(x.start).year()}
                        </Typography>
                        <Box mx={1}> · </Box>
                        <PhaseText phase={x.phase} />
                    </Stack>
                    return (
                        <ListItem
                            key={`re-${x.name}${x.start.toString()}`}
                            button
                            onClick={() => { onSelectEvent(x) }}
                            style={{ paddingTop: 2, paddingBottom: 2 }}
                        >
                            <ListItemAvatar>
                                <CountryFlagIcon
                                    size={30}
                                    code={x.countryCode}
                                    style={{ marginRight: '2px', float: 'left' }}
                                />
                            </ListItemAvatar>
                            <ListItemText primary={x.name} sx={{ mr: 2 }} />
                            <ListItemSecondaryAction>
                                <IconButton edge='end' onClick={() => onRemoveEvent(x.eventId)} size='small'>
                                    <DeleteIcon />
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                    )
                })}
            </List>
    )
}