import SettingsIcon from '@mui/icons-material/Settings'
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormGroup, IconButton, Paper, PaperProps, Tooltip, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import * as React from 'react'

interface Props {
    lastTwoHours: boolean
    onChangeLastTwoHours: (x: boolean) => void
    relativeTime: boolean
    onChangeRelativeTime: (x: boolean) => void
}

const PaperComponent = (props: PaperProps) => {
    return (
        <Paper {...props} elevation={6} />
    )
}

export const HazardsSettings: React.FunctionComponent<Props> = ({ lastTwoHours, onChangeLastTwoHours, relativeTime, onChangeRelativeTime }) => {
    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

    const [open, setOpen] = React.useState(false)

    const onOpen = () => {
        setOpen(true)
    }

    const onClose = () => {
        setOpen(false)
    }

    return (
        <div>
            <Tooltip placement='top' title={'Settings'}>
                <IconButton
                    onClick={onOpen}
                    size='medium'
                >
                    <SettingsIcon />
                </IconButton>
            </Tooltip>
            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={onClose}
                PaperComponent={PaperComponent}
            >
                <DialogTitle>{'Hazard Settings'}</DialogTitle>
                <DialogContent style={{ minWidth: 256 }}>
                    <FormControl component="fieldset" style={{ display: 'block' }} >
                        <FormGroup>
                            <FormControlLabel
                                control={<Checkbox checked={lastTwoHours} onChange={() => onChangeLastTwoHours(!lastTwoHours)} />}
                                label="Only show hazards from the past 2 hours"
                            />
                            <FormControlLabel
                                control={<Checkbox checked={relativeTime} onChange={() => onChangeRelativeTime(!relativeTime)} />}
                                label="Include relative time (30 seconds ago, 1 minute ago, etc.)"
                            />
                        </FormGroup>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={onClose} color="primary">
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
