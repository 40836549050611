import * as React from 'react'
import image from './heading.png'

interface Props {
    height: number
    style?: React.CSSProperties
}

export const Heading: React.FunctionComponent<Props> = (props) => {

    return <img
        src={image}
        alt="Race Control"
        height={props.height}
        style={props.style}
    />
}