import { DateTime } from 'luxon'
import { utils, writeFile } from 'xlsx'
import { Column } from '../components/Tables/BaseTable'
import { EventDetailed } from '../store/Event'

export const exportTableXLSX = async (data: any[], displayColumns: Column[], title: string, ev?: EventDetailed | null) => {
  const workbook = utils.book_new()
  const worksheet = utils.aoa_to_sheet([])
  utils.sheet_add_json(worksheet, data, { origin: 'A2', skipHeader: true, header: displayColumns.map(x => x.id) })
  utils.sheet_add_aoa(worksheet, [displayColumns.map(x => x.label)], { origin: 'A1' })

  worksheet['!ref'] = `A1:${getLetterFromNumber(displayColumns.length - 1)}${data.length + 1}`
  utils.book_append_sheet(workbook, worksheet, 'Sheet1')
  let _title = ev
    ? `${title}_${ev.name.replace(/\s/g, '')}${DateTime.fromISO(ev.start).year.toString().substring(2, 4)}_${DateTime.local().toFormat('yyyy-LL-dd_HH.mm.ss')}`
    : `${title}`
  writeFile(workbook, `${_title}.xlsx`)
}

const getLetterFromNumber = (num: number) => {
  var alpha = ''

  for (; num >= 0; num = parseInt((num / 26).toString(), 10) - 1) {
    alpha = String.fromCharCode(num % 26 + 0x41) + alpha
  }

  return alpha
}
