import { stagePointError } from '.'
import { UnitPointType } from '../../../../../store/Itinerary'
import { AdjacentStagePointRule } from '../../../../../store/StageFile'

export const pointFollowingStartLinePoint2IsValid: AdjacentStagePointRule = (currentPoint, nextPoint) => {
    // L2 – can be followed by F1, SB, BP, ZP, SP, SF, WP
    if (currentPoint.type.unitPointType == UnitPointType.StartLinePoint2) {
        switch (nextPoint.type.unitPointType) {
            //    case UnitPointType.FinishLinePoint1:
            //    case UnitPointType.SplitBoat:
            case UnitPointType.BuoyPoint:
            case UnitPointType.ZeroSpeedPoint:
            case UnitPointType.SplitPoint:
            case UnitPointType.FlyingFinish:
            case UnitPointType.Waypoint:
                break
            default:
                return stagePointError(nextPoint, `L2 must be followed by ZP/SP/SF/WP - ${nextPoint.type.code} is invalid`)
        }
    }
}