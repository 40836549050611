import { Fab, Typography } from "@mui/material"
import { useSelector } from "react-redux"
import { ApplicationState } from "../../store"
import { Role } from "../../store/Account"
import AddIcon from '@mui/icons-material/Add'

type Props = {
    allowedRoles: Role[]
    onClick: () => void
    text?: string
}

export const CreateButton: React.FunctionComponent<Props> = ({ allowedRoles, onClick, text }) => {
    const roles = useSelector((state: ApplicationState) => state.identity.roles)

    return (
        roles.some(x => allowedRoles.includes(x))
            ? <Fab
                color='primary'
                style={{ position: 'fixed', right: 40, bottom: 24 }}
                onClick={onClick}
                variant={text ? 'extended' : 'circular'}
            >
                <AddIcon />
                <Typography>{text}</Typography>
            </Fab>
            : null
    )
}