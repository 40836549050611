import ShowChartIcon from '@mui/icons-material/ShowChart'
import { Autocomplete, Box, Stack, TextField, Tooltip, Typography } from "@mui/material"
import { isNull } from 'lodash'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { getStageDisplayName, getStageStatusColor, Stage, StageStatus } from "../../../store/Itinerary"

interface Props {
    onChange: (locationGroupId: number | null) => void
    stages: Stage[]
    initialValue?: number | null
    allowBlank?: boolean
    allowAll?: boolean
    style?: React.CSSProperties
    required?: boolean
    onOpen?: () => void
    onClose?: () => void
    useStageNumber?: boolean
    defaultLatest?: boolean
    placeholder?: string
}

export const StageSelect: React.FunctionComponent<Props> = ({ placeholder, allowBlank, allowAll, onChange, onOpen, onClose, stages, initialValue, required, style, useStageNumber, defaultLatest }) => {
    const [value, setValue] = useState<number | null>(initialValue ? initialValue : null)

    useEffect(() => {
        if (initialValue !== undefined)
            setValue(initialValue)
    }, [initialValue])

    const onStageChange = (text: string) => {
        if (text == '') {
            onChange(null)
        } else {
            stages.forEach(e => {
                if (getStageDisplayName(e) != '' && getStageDisplayName(e) == text) {
                    onChange(useStageNumber && e.number != undefined ? e.number : e.locationGroupId)
                }
            })
        }
    }

    let defaultStage: Stage | null = null
    let _stages = [...stages]
    if (stages) {

        let ss: Stage | undefined
        if (!isNull(value)) {
            if (useStageNumber) {
                ss = _stages.find(x => x.number == value)
            } else {
                ss = _stages.find(x => x.locationGroupId == value)
            }
        } else if (defaultLatest && isNull(defaultStage)) {
            _stages.reverse()
            ss = _stages.find(x => x.status == StageStatus.Live)
            if (ss == undefined) {
                ss = _stages.find(x => x.status == StageStatus.Completed)
            }
            _stages.reverse()
        }
        if (ss == undefined && _stages.length > 0)
            ss = _stages[0]
        if (ss != undefined)
            defaultStage = ss
    }

    if (allowBlank && isNull(value)) {
        defaultStage = null
    }

    return <Autocomplete
        sx={{ minWidth: '250px', ...style }}
        options={stages}
        autoHighlight
        value={defaultStage}
        onOpen={onOpen}
        onClose={onClose}
        size={'small'}
        getOptionLabel={(option: Stage) => getStageDisplayName(option)}
        onInputChange={((x, v) => onStageChange(v))}
        renderOption={(props, option) => (
            <Box component="li" {...props}>
                <Stack direction={'row'} alignItems='center' spacing={1}>
                    <Tooltip title={StageStatus[option.status]}>
                        <ShowChartIcon
                            sx={{
                                width: 18,
                                height: 18,
                                color: getStageStatusColor(option.status),
                            }}
                            fontSize='small'
                        />
                    </Tooltip>
                    <Typography>{getStageDisplayName(option)}</Typography>
                </Stack>
            </Box>
        )}
        renderInput={(params) => (
            <TextField
                {...params}
                label={allowAll && isNull(value) ? 'All Stages' : 'Stage'}
                required={required}
                placeholder={placeholder ?? 'Select Stage'}
                inputProps={{
                    ...params.inputProps,
                }}
            />
        )}
    />
}