import { stagePointError } from '.'
import { UnitPointType } from '../../../../../store/Itinerary'
import { AdjacentStagePointRule } from '../../../../../store/StageFile'

export const pointFollowingChicaneStartIsValid: AdjacentStagePointRule = (currentPoint, nextPoint) => {
    // CS – can be followed by CF
    if (currentPoint.type.unitPointType == UnitPointType.ChicaneStart) {
        switch (nextPoint.type.unitPointType) {
            case UnitPointType.ChicaneFinish:
                break
            default:
                return stagePointError(nextPoint, `CS must be followed by CF - ${nextPoint.type.code} is invalid`)
        }
    }
}