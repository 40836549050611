
export const getInitialNumberValue = (key: string) => {
    const item = window.localStorage.getItem(key)
    return item ? parseInt(item) : undefined
}

export const getInitialArrayValue = (key: string) => {
    const item = window.localStorage.getItem(key)
    return item ? JSON.parse(item) as [] : undefined
}

export const getInitialValue = (key: string, fallback: any) => {
    const item = window.localStorage.getItem(key)
    return item ? JSON.parse(item) : fallback
}
