import { Box, Breakpoint, Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, useMediaQuery } from '@mui/material'
import Paper, { PaperProps } from '@mui/material/Paper'
import { useTheme } from '@mui/material/styles'
import * as React from 'react'
import { useEffect, useState } from 'react'
import Draggable from 'react-draggable'
import { SecureDeleteButton } from '../Buttons/SecureDeleteButton'

function DraggablePaperComponent(props: PaperProps) {
    return (
        <Draggable handle=".draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} elevation={6} />
        </Draggable>
    )
}

function PaperComponent(props: PaperProps) {
    return <Paper {...props} elevation={6} />
}

type T = {}

interface Props {
    model: T
    create?: boolean
    modelName?: string
    open: boolean
    saveDisabled?: boolean
    children: React.ReactElement | React.ReactElement[]
    deleteText?: string
    saveText?: string
    titleText?: string
    size?: Breakpoint | false
    onSave?: (model: T) => void
    onDelete?: (model: T) => void
    onClose: () => void
}

export const EditDialog: React.FunctionComponent<Props> = (props) => {
    const { titleText, saveText, create, model, deleteText, children, modelName, open, saveDisabled, size, onClose, onSave, onDelete } = props
    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'))

    const [editingModel, setEditingModel] = useState<T>(model)

    useEffect(() => {
        setEditingModel(model)
    }, [model])


    const onCloseClick = () => {
        onClose()
        setEditingModel({})
    }

    const onSaveClick = () => {
        if (onSave) {
            onSave(editingModel)
        }

        // setEditingModel({})
    }

    const onDeleteClick = () => {
        if (onDelete) {
            onDelete(editingModel)
        }

        setEditingModel({})
    }

    return (
        <Dialog
            fullScreen={fullScreen}
            open={open}
            onClose={onCloseClick}
            PaperComponent={fullScreen ? PaperComponent : DraggablePaperComponent}
            fullWidth={true}
            maxWidth={size ?? 'md'}
        >
            <DialogTitle sx={{ cursor: 'move' }} className="draggable-dialog-title">
                {`${titleText ? titleText : ((create ? 'Create ' : 'Edit ') + modelName)}`}
            </DialogTitle>
            <DialogContent>
                <Box
                    component='form'
                    sx={{
                        MuiStack: {
                            m: 2
                        },
                        pt: '12px'
                    }}
                    onSubmit={e => { e.preventDefault() }}
                    noValidate
                    autoComplete='off'
                >
                    {children}
                </Box>
            </DialogContent>

            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <DialogActions>
                    {deleteText && !create && <SecureDeleteButton
                        label={`Delete ${modelName}`}
                        mainText={deleteText}
                        onConfirm={onDeleteClick}
                    />}
                </DialogActions>
                <DialogActions>
                    <Stack direction='row' spacing={1}>
                        <Button onClick={onCloseClick} color='primary'>
                            Cancel
                        </Button>
                        {onSave && <Button
                            onClick={onSaveClick}
                            disabled={saveDisabled}
                            color='primary'
                            variant={'contained'}
                        >
                            {saveText ?? 'Save'}
                        </Button>}
                    </Stack>
                </DialogActions>
            </div>
        </Dialog>
    )
}
