import * as Core from '.'
import { Progress } from '../components/Events/Times/Progress/ProgressTable'
import { SplitTimesUpdate } from '../components/Events/Times/SplitTimesEditDialog'
import { WebPointType } from '../store/Itinerary'
import { BeamTabletTime, BeamTime, ControlTime, SplitTimesModel, StageTime } from '../store/Times'

export function requestStageTimes(stageId: number): Promise<StageTime[]> {
    return Core.get<StageTime[]>(`/times/stage-times?stageId=${stageId}`)
}

export function requestControlTimes(locationPointId: number, locationGroupId: number, type: WebPointType, orderByDesc: boolean): Promise<ControlTime[]> {
    return Core.get<ControlTime[]>(`/times/control-times?locationPointId=${locationPointId}&locationGroupId=${locationGroupId}&type=${type}&orderByDesc=${orderByDesc}`)
}

export function exportStageTimes(stageId: number): Promise<Array<Array<any>>> {
    return Core.get(`/times/export-sra-stage-times?stageId=${stageId}`)
}

export const updateStageTime = (s: StageTime): Promise<Response> => {
    return Core.post<StageTime>(`/times/update-stage-time`, s)
}

export function requestBeamTimes(locationGroupId: number): Promise<BeamTime[]> {
    return Core.get<BeamTime[]>(`/times/beam-times?locationGroupId=${locationGroupId}`)
}

export function requestBeamTabletTimes(eventId: number): Promise<BeamTabletTime[]> {
    return Core.get<BeamTabletTime[]>(`/times/beam-tablet-times?eventId=${eventId}`)
}

export function sendBeamTabletTimeToResults(beamTabletTimeId: number, confirm: boolean): Promise<Response> {
    return Core.postEmpty(`/times/send-beam-tablet-time-to-results?beamTabletTimeId=${beamTabletTimeId}&confirm=${confirm}`)
}

export function reassignBeamTime(beamTimeId: number, entryId?: number): Promise<Response> {
    return Core.postEmpty(`/times/reassign-beam-time?beamTimeId=${beamTimeId}&entryId=${entryId}`)
}

export function requestBeamTabletTime(beamTabletTimeId: number): Promise<BeamTabletTime> {
    return Core.get<BeamTabletTime>(`/times/beam-tablet-time?beamTabletTime=${beamTabletTimeId}`)
}

export function requestSplitTimes(eventId: number, stageId: number | null, classId: number): Promise<SplitTimesModel[]> {
    return Core.get<SplitTimesModel[]>(`/times/split-times?eventId=${eventId}&stageId=${stageId}&classId=${classId}`)
}

export const updateSplitTimes = (splitTimes: SplitTimesUpdate): Promise<Response> => {
    return Core.post<SplitTimesUpdate>(`/times/update-split-times`, splitTimes)
}

export function requestProgress(stageId: number): Promise<Progress> {
    return Core.get<Progress>(`/times/progress?stageId=${stageId}`)
}
