import * as Core from '.'
import { Inputs, PreEventFormModel } from '../components/PreEventForm'
import { CheckSetupMessages, EventBasic, EventDetailed, EventStatus, EventYear, Notification } from '../store/Event'

type Varient = 'sharepoint' | 'planner' | 'contacts' | 'pre-event-notes' | 'unit-profile' | 'imageUrls' | 'temp-user' | 'results' | 'travel' | 'accommodation' | 'basic' | 'date-time' | 'settings' | 'vehicles' | 'timing' | 'staff' | 'mobile' | 'status' | 'report' | 'logistics'

export function requestEvents(skip: number, take: number, searchText: string, order: string, phaseRange: number[], status: EventStatus[], champs: number[], countries: string[]): Promise<EventBasic[]> {
    return Core.get<EventBasic[]>(`/event/table?skip=${skip}&take=${take}&search=${searchText}&order=${order}&phaseStart=${phaseRange[0]}&phaseEnd=${phaseRange[1]}&status=${status}&champs=${champs}&countries=${countries}`)
}

export function requestEventsList(year: number): Promise<EventBasic[]> {
    return Core.get<EventBasic[]>(`/event/list?year=${year}`)
}

export function requestEventDetails(eventId: number): Promise<EventDetailed> {
    return Core.get<EventDetailed>(`/event/details?eventId=${eventId}`)
}

export function requestNotifications(eventId: number): Promise<Notification[]> {
    return Core.get<Notification[]>(`/event/notifications?eventId=${eventId}`)
}

export const updateEventImage = async (file: File, folderName: string, eventId: number) => {
    return Core.upload(`/event/update-event-image?folderName=${folderName}&eventId=${eventId}`, file)
}

export const updateEvent = async (ev: EventDetailed, varient: Varient) => {
    return Core.post(`/event/update-event?varient=${varient}`, ev)
}

export const createEvent = async (ev: EventBasic) => {
    return Core.post(`/event/create-event`, ev)
}

export function cloneEvent(eventId: number): Promise<number> {
    return Core.get<number>(`/event/clone?eventId=${eventId}`)
}

export function clearEvent(eventId: number): Promise<Response> {
    return Core.del(`/event/clear`, eventId)
}

export function deleteEvent(eventId: number): Promise<Response> {
    return Core.del(`/event/delete`, eventId)
}

export function checkSetupMessage(eventId: number): Promise<CheckSetupMessages> {
    return Core.get<CheckSetupMessages>(`/event/check-setup-messages?eventId=${eventId}`)
}

export function eventsFromOtherYears(eventId: number): Promise<EventYear[]> {
    return Core.get<EventYear[]>(`/event/events-from-other-years?eventId=${eventId}`)
}

export function hasStarted(eventId: number): Promise<boolean> {
    return Core.get<boolean>(`/event/has-started?eventId=${eventId}`)
}

export const convertRecceEntries = async (eventId: number) => {
    return Core.postEmpty(`/event/convert-recce-entries?eventId=${eventId}`)
}

export const submitPreEventForm = async (uniqueId: string, data: string) => {
    return Core.post(`/event/pre-event-form?uniqueId=${uniqueId}`, data)
}

export const getPreEventForms = async (eventId: number) => {
    return Core.get<PreEventFormModel[]>(`/event/pre-event-forms?eventId=${eventId}`)
}
