import { DateTime } from 'luxon'
import { Action, Reducer } from 'redux'
import { AppThunkAction } from '.'
import * as Api from '../api/Championship'

export interface ChampionshipEvent {
    eventId: number
    name: string
    countryCode: string
    start: string
    round: number
}

export interface ChampionshipBasic {
    championshipId: number,
    code: string,
}

export interface Championship {
    championshipId: number,
    code: string,
    name: string,
    year: number,
    championshipEvents: ChampionshipEvent[]
}

export const blankChampionship: Championship = {
    championshipId: -1,
    code: '',
    name: '',
    year: DateTime.local().year,
    championshipEvents: []
}

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface ChampionshipState {
    championshipsPending: boolean
    championships: Championship[]
    errorText: string
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

interface RequestChampionships { type: 'REQUEST_CHAMPIONSHIPS' }
interface ReceiveChampionships { type: 'RECEIVE_CHAMPIONSHIPS', championships?: Championship[] }
interface ReceiveChampionshipsFailed { type: 'RECEIVE_CHAMPIONSHIPS_FAILED', reason: string }

interface UpdateChampionship { type: 'UPDATE_CHAMPIONSHIP' }
interface UpdateChampionshipFailed { type: 'UPDATE_CHAMPIONSHIP_FAILED', reason: string }
interface DeleteChampionship { type: 'DELETE_CHAMPIONSHIP' }
interface DeleteChampionshipFailed { type: 'DELETE_CHAMPIONSHIP_FAILED', reason: string }

interface ClearChampionships { type: 'CLEAR_CHAMPIONSHIPS' }

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = RequestChampionships | ReceiveChampionships | ReceiveChampionshipsFailed | UpdateChampionship | UpdateChampionshipFailed | DeleteChampionship | DeleteChampionshipFailed | ClearChampionships

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const championshipActionCreators = {
    requestChampionships: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'REQUEST_CHAMPIONSHIPS' })
        Api.requestChampionships()
            .then(result => dispatch({
                type: 'RECEIVE_CHAMPIONSHIPS',
                championships: result
            }))
            .catch((err) => {
                err.json().then(text => {
                    dispatch({ type: 'RECEIVE_CHAMPIONSHIPS_FAILED', reason: text })
                })
            })
    },
    clearChampionships: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'CLEAR_CHAMPIONSHIPS' })
    }
}

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.
const unloaded: ChampionshipState = {
    championshipsPending: false,
    championships: [],
    errorText: ''
}

export const reducer: Reducer<ChampionshipState> = (state: ChampionshipState | undefined, incomingAction: Action): ChampionshipState => {
    if (state === undefined) return unloaded
    const action = incomingAction as KnownAction

    switch (action.type) {
        case 'REQUEST_CHAMPIONSHIPS':
            return {
                ...state,
                championshipsPending: true,
                errorText: '',
            }
        case 'RECEIVE_CHAMPIONSHIPS':
            let championships: Championship[] = []
            if (action.championships) {
                championships = action.championships
            } else {
                championships = state.championships
            }

            return {
                ...state,
                championshipsPending: false,
                championships: championships
            }
        case 'CLEAR_CHAMPIONSHIPS':
            return {
                ...state,
                championships: []
            }
        case 'RECEIVE_CHAMPIONSHIPS_FAILED':
            return {
                ...state,
                championshipsPending: false,
                errorText: action.reason
            }
        default:
            return state
    }
}
