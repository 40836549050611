import * as Core from '.'
import { ImportModel } from '../components/Events/Entries/Import/ImportEntriesTable'
import { SraBoatImportModel } from '../components/Events/Entries/Import/ImportSraBoatsTable'
import { Scan } from '../components/Events/Entries/ScanLogbooks/ScanLogbooks'
import { Entry, EntrySra, SetupInfo, TeamCommsMap, Withdrawal } from '../store/Entry'
import { SraIdentifier, SraIdentifierType } from '../store/SRA'
import { SraStartOrderModel } from "../components/Events/Times/ImportSraStartOrders"
import { AddWithdrawal } from '../components/Events/Entries/Withdrawals/WithdrawalDialog'
import { PreEntryUsers } from '../store/Entry'

export const requestEntries = (searchText: string, order: string, eventId: number, classId: number | null): Promise<Entry[]> => {
    return Core.get<Entry[]>(`/entry/table?search=${searchText}&order=${order}&eventId=${eventId}&classId=${classId}`)
}

export const createEntry = (entry: Entry): Promise<Response> => {
    return Core.post(`/entry/create`, entry)
}

export const updateEntry = (entry: Entry): Promise<Response> => {
    return Core.post<Entry>(`/entry/update`, entry)
}

export const deleteEntry = (entry: Entry): Promise<Response> => {
    return Core.del(`/entry/delete`, entry.entryId)
}

export const deleteAllEntries = (eventId: number): Promise<Response> => {
    return Core.del(`/entry/delete-all`, eventId)
}

export const requestEntryDetailed = (entryId: number): Promise<Entry> => {
    return Core.get<Entry>(`/entry/detailed?entryId=${entryId}`)
}

export const uploadEntries = async (model: ImportModel) => {
    return Core.post(`/entry/import`, model)
}

export const uploadSraBoats = async (model: SraBoatImportModel) => {
    return Core.post('/entry/import-sra-boats', model)
}

export const checkUnit = (eventId: number, entryId: number, serialNumber: number): Promise<string> => {
    return Core.get<string>(`/entry/check-unit?eventId=${eventId}&entryId=${entryId}&serialNumber=${serialNumber}`)
}

export function requestSraIdentifiers(type: SraIdentifierType): Promise<SraIdentifier[]> {
    return Core.get<SraIdentifier[]>(`/entry/sra-identifiers?type=${type}`)
}

export function sraBarcode(id: number): Promise<any> {
    return Core.get<any>(`/entry/sra-barcode?id=${id}`)
}

export function sraAssignUnit(sraId: string, serialNumber: number, eventId: number): Promise<Scan> {
    return Core.get<Scan>(`/entry/sra-assign-unit?sraId=${sraId}&serialNumber=${serialNumber}&eventId=${eventId}`)
}

export function requestEntriesSra(eventId: number): Promise<EntrySra[]> {
    return Core.get<EntrySra[]>(`/entry/entriesSra?eventId=${eventId}`)
}

export function createEntrySra(eventId: number, startOrder: SraStartOrderModel): Promise<EntrySra> {
    return Core.post<SraStartOrderModel>(`/entry/create-sra?eventId=${eventId}`, startOrder)
}

export function updateSraIdentifier(entryId: number, sraId: string): Promise<Response> {
    return Core.post(`/entry/update-sra?entryId=${entryId}&sraId=${sraId}`, null)
}

export function requestSetupInfo(eventId: number): Promise<SetupInfo[]> {
    return Core.get<SetupInfo[]>(`/entry/setup-info?eventId=${eventId}`)
}

export function requestWithdrawals(eventId: number, order: string): Promise<Withdrawal[]> {
    return Core.get<Withdrawal[]>(`/entry/withdrawals?eventId=${eventId}&order=${order}`)
}

export function addWithdrawal(addWithdrawal: AddWithdrawal): Promise<AddWithdrawal[]> {
    return Core.post<AddWithdrawal>(`/entry/add-withdrawal`, addWithdrawal)
}

export function deleteWithdrawal(withdrawalId: number): Promise<Response> {
    return Core.del(`/entry/delete-withdrawal?withdrawalId=${withdrawalId}`)
}

export function getVaildUsers(): Promise<PreEntryUsers[]> {
    return Core.get<PreEntryUsers[]>(`/entry/get-vaild-users`)
}

export function addTeamCommsMap(userId: string, entryId: number, eventId: number): Promise<any> {
    return Core.postEmpty(`/entry/create-team-comms-map?userId=${userId}&entryId=${entryId}&eventId=${eventId}`)
}

export function deleteTeamCommsMap(userId: string, entryId: number): Promise<Response> {
    console.log(entryId)
    return Core.del(`/entry/delete-team-comms-map?userId=${userId}&entryId=${entryId}`)
}

export function getTeamComms(eventId: number): Promise<TeamCommsMap[]> {
    return Core.get<TeamCommsMap[]>(`/entry/get-team-comms?eventId=${eventId}`)
}