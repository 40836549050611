import SearchIcon from '@mui/icons-material/Search'
import { FormHelperText, SxProps } from '@mui/material'
import InputBase from '@mui/material/InputBase'
import { alpha, styled } from '@mui/material/styles'
import React, { useEffect, useState } from 'react'

const PREFIX = 'SearchBox'

const classes = {
    search: `${PREFIX}-search`,
    searchIcon: `${PREFIX}-searchIcon`,
    inputInput: `${PREFIX}-inputInput`
}

const Root = styled('div')((
    { theme }
) => ({
    [`& .${classes.search}`]: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: alpha(theme.palette.common.white, 0.25),
        },
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: 'auto',
        },
    },

    [`& .${classes.searchIcon}`]: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },

    [`& .${classes.inputInput}`]: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '14ch',
            '&:focus': {
                width: '20ch',
            },
        },
    }
}))

interface Props {
    placeholder: string
    onChange: (value: string) => void
    disabled?: boolean
    helperText?: string
    style?: SxProps
}

export const SearchBox: React.FunctionComponent<Props> = (props) => {
    const { helperText } = props
    const [currentValue, setCurrentValue] = useState<string>('')


    useEffect(() => {
        const delayDebounce = setTimeout(() => {
            props.onChange(currentValue)
        }, 800)

        return () => clearTimeout(delayDebounce)
    }, [currentValue])

    return (
        <Root sx={{ alignSelf: 'flex-end', ...props.style }}>
            <div className={classes.search}>
                <div className={classes.searchIcon}>
                    <SearchIcon />
                </div>
                <InputBase
                    //  sx={{ ml: 1, flex: 1 }}
                    disabled={props.disabled}
                    onChange={(event) => setCurrentValue(event.currentTarget.value)}
                    placeholder={props.placeholder}
                    classes={{
                        input: classes.inputInput,
                    }}
                />

            </div>
            <FormHelperText>{helperText}</FormHelperText>
        </Root>
    )
}