import { UnitPointType } from '../../../../../store/Itinerary'
import { RuleType, SFStage, SFStagePoint, StagePointError } from '../../../../../store/StageFile'
import { AdjacentRules, AdjacentStageRules, AllPointsRules, AllStagesRules, DuplicateStageRules, GroupedRules, IndividualRules } from './'

export function detectErrors(stages: SFStage[]) {
    let stagePoints = stages.reduce(
        (arr: SFStagePoint[], elem) => arr.concat(elem.stagePoints), [])

    let errors: StagePointError[] = []
    let hasTimeControls: boolean = false

    stagePoints.forEach((stagePoint, index) => {
        let nextPoint = stagePoints[index + 1]

        // Run through Adjacent Rules using current point and next point
        if (nextPoint) {
            AdjacentRules.forEach(rule => {
                let result = rule(stagePoint, nextPoint, stages.find(x => x.number == stagePoint.stageNumber), stages)
                if (result) {
                    errors.push(result)
                }
            })
        }

        // Check if file has TC points
        if (stagePoint.type.unitPointType == UnitPointType.TimeControl) {
            hasTimeControls = true
        }

        // Run through Individual Rules using current point 
        IndividualRules.forEach(rule => {
            let result = rule(stagePoint)
            if (result) {
                errors.push(result)
            }
        })
    })

    AllStagesRules.forEach(rule => {
        let result = rule(stages)
        if (result) {
            errors.push(result)
        }
    })

    stages.forEach((stage, index) => {
        let nextStage = stages[index + 1]

        if (nextStage) {
            AdjacentStageRules.forEach(rule => {
                let result = rule(stage, nextStage)
                if (result) {
                    errors.push(result)
                }
            })
        }

        // Run through Grouped Rules using points in current stage
        GroupedRules.forEach(rule => {
            let result = rule(stage.stagePoints.map(x => x), hasTimeControls)
            if (result) {
                result.forEach(stagePointError => {
                    errors.push(stagePointError)
                })
            }
        })

        // Run through Duplicate Stage Rules with both stages
        if (stage.duplicateOfStage && stage.duplicateOfStage != stage.number) {
            let firstStage = stages.find(x => x.number == stage.duplicateOfStage)
            DuplicateStageRules.forEach(rule => {
                if (firstStage !== undefined) {
                    let result = rule(firstStage, stage)
                    if (result) {
                        result.forEach(stagePointError => {
                            errors.push(stagePointError)
                        })
                    }
                }
            })
        }
    })

    // Run through All Points Rules using full list of stage points
    AllPointsRules.forEach(rule => {
        let result = rule(stagePoints)
        if (result) {
            result.forEach(stagePointError => {
                errors.push(stagePointError)
            })
        }
    })

    return errors.sort((a, b) => {
        return a.stagePoint.order - b.stagePoint.order
    })
}

export const stagePointError = (stagePoint: SFStagePoint, text: string): StagePointError => {
    return {
        stagePoint: stagePoint,
        ruleText: text,
        ruleType: RuleType.Error
    }
}

export const stagePointWarning = (stagePoint: SFStagePoint, text: string): StagePointError => {
    return {
        stagePoint: stagePoint,
        ruleText: text,
        ruleType: RuleType.Warning
    }
}
