import { stagePointError } from '.'
import { UnitPointType } from '../../../../../store/Itinerary'
import { AdjacentStagePointRule } from '../../../../../store/StageFile'

export const pointFollowingQuietZoneStartIsValid: AdjacentStagePointRule = (currentPoint, nextPoint) => {
    // QS – can be followed by QF, WP, SP (Winter Trial), TC
    if (currentPoint.type.unitPointType == UnitPointType.QuietZoneStart) {
        switch (nextPoint.type.unitPointType) {
            case UnitPointType.QuietZoneFinish:
            case UnitPointType.Waypoint:
            case UnitPointType.SplitPoint:
            case UnitPointType.TimeControl:
                break
            default:
                return stagePointError(nextPoint, `QS must be followed by QF/WP/SP/TC - ${nextPoint.type.code} is invalid`)
        }
    }
}