import { Stack, Typography } from '@mui/material'
import { isNull } from 'lodash'
import { SafetyStatus } from '../../../store/Entry'
import { amber, blue, HazardEvent, isHighG, redLight, yellow } from './Models'

type Props = {
    hazard: HazardEvent
}

export const SummaryRow: React.FunctionComponent<Props> = ({ hazard }) => {

    const getWorstHazardColor = () => {
        if (hazard.autoSostime != null || hazard.manSostime != null)
            return redLight
        if (isHighG(hazard.maxGforce))
            return amber
        else
            return yellow
    }

    let element = <div />

    if (!isNull(hazard.maxGforce)) {
        element = <Typography variant='subtitle1' sx={{ color: getWorstHazardColor() }}>
            {hazard.maxGforce} g
        </Typography>
    }

    if (!isNull(hazard.fiaAlarm)) {
        if (hazard.hazardType == SafetyStatus.IsolationResistance) {
            element = <Stack>
                <Typography sx={{ color: blue }}>
                    {hazard.fiaAlarm.isolationResistance} kΩ
                </Typography>
                <Typography fontSize='15px' sx={{ color: blue }}>
                    0x{hazard.fiaAlarm.hybridStatus.toString(16).toUpperCase()}
                </Typography>
            </Stack>
        } else if (hazard.hazardType == SafetyStatus.BatteryTemperature) {
            element = <Stack>
                <Typography sx={{ color: blue }}>
                    {hazard.fiaAlarm.batteryTemperature.toFixed(1)}°
                </Typography>
                <Typography fontSize='15px' sx={{ color: blue }}>
                    0x{hazard.fiaAlarm.hybridStatus.toString(16).toUpperCase()}
                </Typography>
            </Stack>
        }
    }
    return element
}