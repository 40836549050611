import * as React from 'react'
import image from './Images/SAS-Logo-White.png'

interface Props {
    height: number
    style?: React.CSSProperties
}

export const SASLogoText: React.FunctionComponent<Props> = (props) => {

    return <img
        src={image}
        alt="SAS Logo Text"
        height={props.height}
        style={props.style}
    />
}
