import { DateTime } from 'luxon'
import { getLuxonTimeFromString } from '../../../helper/timeHelper'
import { Person, SafetyStatus } from '../../../store/Entry'

export const yellow = '#fdd835'
export const yellowDark = 'hsl(49deg 98% 43%)'
export const yellowLight = '#fdda3f'
export const amber = '#f69000'
export const amberLight = '#ffa82e'
export const red = 'hsl(0, 100%, 50%)'
export const redLight = 'hsl(0, 100%, 62%)'
export const redDark = 'hsl(0, 100%, 40%)'
export const green = '#33cc33'
export const greenLight = '#4bd24b'
export const greenDark = 'hsl(122deg 59% 43%)'
export const grey = '#909090'
export const greyLight = '#9e9e9e'
export const blue = 'hsl(200deg 100% 66%)'

export const isHighG = (maxGforce: number | null) => {
    return maxGforce && maxGforce > 6
}

export const getDays = (hazardEvents: HazardEvent[]) => {
    let days: DateTime[] = []
    hazardEvents.forEach(x => {
        if (x.earliestTime) {
            let earliestTime = x.earliestTime
            if (earliestTime && days.filter(z => z.toFormat('yyyy-M-d') == earliestTime.toFormat('yyyy-M-d')).length == 0) {
                days.push(DateTime.fromFormat(earliestTime.toFormat('yyyy-M-d'), 'yyyy-M-d'))
            }
        }
    })
    return days
}

export const getSafetyStatusColor = (safetyStatus: SafetyStatus, gForce: number | null, shade?: 'dark' | 'light') => {
    let color = shade == 'dark' ? redDark : 'light' ? redLight : red
    switch (safetyStatus) {
        case SafetyStatus.Hazard:
        case SafetyStatus.Slow:
            color = shade == 'dark' ? yellowDark : 'light' ? yellowLight : yellow
            break
        case SafetyStatus.RolloverHazard:
        case SafetyStatus.RoadBlocked:
            color = shade == 'dark' ? amber : 'light' ? amberLight : amber
            break
        case SafetyStatus.Ok:
        case SafetyStatus.RolloverOk:
            color = shade == 'dark' ? greenDark : 'light' ? greenLight : green
            break
        case SafetyStatus.NoHazard:
            color = shade == 'dark' ? grey : 'light' ? greyLight : grey
            break
        case SafetyStatus.IsolationResistance:
        case SafetyStatus.BatteryTemperature:
        case SafetyStatus.ReceiveOvertake:
        case SafetyStatus.TransmitOvertake:
            color = blue
            break
    }

    if (isHighG(gForce) && safetyStatus == SafetyStatus.Hazard) {
        color = shade == 'dark' ? amber : 'light' ? amberLight : amber
    }
    return color
}

export const happenedOnDay = (hazardEvent: HazardEvent, day: string | null) => {
    if (!day) {
        return true
    }
    let d = DateTime.fromISO(day)
    if (hazardEvent.earliestTime && hazardEvent.earliestTime.toFormat('yyyy-M-d') == d.toFormat('yyyy-M-d')) {
        return true
    }
    return false
}

export const isOnStage = (hazardEvent: HazardEvent, stage: number | null) => {
    if (stage == null)
        return true

    return hazardEvent.locationGroupId == stage
}

export const wasInLastTwoHours = (hazardEvent: HazardEvent, lastTwoHours: boolean) => {
    if (lastTwoHours && hazardEvent.earliestTime) {
        return hazardEvent.earliestTime.toMillis() > DateTime.now().minus({ hours: 2 }).toMillis()
    }
    return true
}

export const getDates = (hazardEvent: HazardEvent, zone?: number) => {
    let dates: DateTime[] = []
    if (hazardEvent.hazardTime) dates.push(getLuxonTimeFromString(hazardEvent.hazardTime, zone))
    if (hazardEvent.autoSostime) dates.push(getLuxonTimeFromString(hazardEvent.autoSostime, zone))
    if (hazardEvent.manSostime) dates.push(getLuxonTimeFromString(hazardEvent.manSostime, zone))
    if (hazardEvent.oktime) dates.push(getLuxonTimeFromString(hazardEvent.oktime, zone))
    if (hazardEvent.lastRepeatTime) dates.push(getLuxonTimeFromString(hazardEvent.lastRepeatTime, zone))
    if (hazardEvent.lastUpdated) dates.push(getLuxonTimeFromString(hazardEvent.lastUpdated, zone))
    if (hazardEvent.repeatSostime) dates.push(getLuxonTimeFromString(hazardEvent.repeatSostime, zone))
    return dates
}

export interface FiaAlarm {
    fiaAlarmId: number
    hazardId: number
    isolationResistance: number
    batteryTemperature: number
    hybridStatus: number
}

export interface Hazard {
    hazardId: number
    hazardType: SafetyStatus
    locationGroupId: number
    hazardEventId: number | null
    lat: number
    long: number
    source: string
    timestamp: Date
    distance: number | null
    speed: number | null
    gforce: number | null
    xForce: number | null
    yForce: number | null
    zForce: number | null
    badLocation: boolean | null
    entryId: number
}

export interface HazardEvent {
    hazardEventId: number
    entryId: number
    locationGroupId: number
    hazardType: SafetyStatus
    hazardDistance: number | null
    hazardTime: string | null
    autoSostime: string | null
    manSostime: string | null
    oktime: string | null
    lastRepeatTime: string | null
    lat: number
    long: number
    lastUpdated: string
    repeatSostime: string | null
    badLocation: boolean | null
    maxGforce: number | null
    earliestTime?: DateTime
    fiaAlarm: FiaAlarm
}

export interface SlowCar {
    hazardId: number
    entryId: number
    identifier: string
    driver: Person
    stageNumber: number
    recorded: string
    timeSlow: string
    minSpeed: number
    hazardType: SafetyStatus
    distance: number
    lat: number
    long: number
    includesHazard: boolean
}

export const getSafetyStatusFromCode = (code: string) => {
    switch (code) {
        case 'NO': return SafetyStatus.NoHazard
        case 'HZ': return SafetyStatus.Hazard
        case 'RH': return SafetyStatus.RolloverHazard
        case 'OK': return SafetyStatus.Ok
        case 'RK': return SafetyStatus.RolloverOk
        case 'SS': return SafetyStatus.AutomaticSos
        case 'MS': return SafetyStatus.ManualSos
        case 'RS': return SafetyStatus.RolloverSos
        case 'SF': return SafetyStatus.ManualSosFire
        case 'SM': return SafetyStatus.ManualSosMedical
        case 'SL': return SafetyStatus.Slow
        case 'OV': return SafetyStatus.TransmitOvertake
        case 'RO': return SafetyStatus.ReceiveOvertake
        case 'FO': return SafetyStatus.FivOnCourse
        case 'RB': return SafetyStatus.RoadBlocked
        case 'PO': return SafetyStatus.Police
        case 'MC': return SafetyStatus.Mechanical
        case 'CS': return SafetyStatus.ConfirmSos
        default: return SafetyStatus.NoHazard
    }
}