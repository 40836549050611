import { stagePointError } from '.'
import { Stage, UnitPointType, StagePoint, GetLocationPointCode } from '../../../../../store/Itinerary'
import { DuplicateStageRule, SFStage, SFStagePoint, StagePointError } from '../../../../../store/StageFile'

export const stagePointMatches: DuplicateStageRule = (firstStage: SFStage, secondStage: SFStage) => {
    // Point of the same index in second stage much match the first
    let stagePointErrors: StagePointError[] = []
    let finishIndex = 999

    firstStage.stagePoints.forEach((firstStagePoint, i) => {
        if (firstStagePoint.type.unitPointType == UnitPointType.TransitPoint) {
            finishIndex = i
        }
        let secondStagePoint = secondStage.stagePoints[i]
        if (secondStagePoint != null && i <= finishIndex) {
            if (secondStagePoint.data != firstStagePoint.data && firstStagePoint.type.unitPointType != UnitPointType.TimeControl) {
                stagePointErrors.push(createError(firstStagePoint, secondStagePoint, 'Data'))
            }
            if (secondStagePoint.latitude != firstStagePoint.latitude) {
                stagePointErrors.push(createError(firstStagePoint, secondStagePoint, 'Latitude'))
            }
            if (secondStagePoint.longitude != firstStagePoint.longitude) {
                stagePointErrors.push(createError(firstStagePoint, secondStagePoint, 'Longitude'))
            }
            if (secondStagePoint.type.unitPointType != firstStagePoint.type.unitPointType) {
                stagePointErrors.push(createError(firstStagePoint, secondStagePoint, 'Type'))
            }
        }
    })

    return stagePointErrors
}

const createError = (firstStagePoint: SFStagePoint, secondStagePoint: SFStagePoint, field: string) => {
    return stagePointError(secondStagePoint, `${field} field of ${secondStagePoint.type.code} point in stage ${secondStagePoint.stageNumber} does not match the same point in stage ${firstStagePoint.stageNumber}`)
}