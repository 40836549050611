import { Action, Reducer } from 'redux'
import { AppThunkAction } from '.'
import * as Api from '../api/Itinerary'
import { ClassModel } from './Class'
import { EventBasic } from './Event'

export enum WebPointType {
    Other = 0,
    StageStart = 1,
    StageFinish = 2,
    TransitStart = 3,
    TransitFinish = 4,
    TransitPoint = 5,
    QuietZoneStart = 6,
    QuietZoneFinish = 7,
    ChicaneStart = 8,
    ChicaneFinish = 9,
    RestrictionStart = 10,
    RestrictionFinish = 11,
    Waypoint = 12,
    SplitPoint = 13,
    TimeControl = 14,
    Refuel = 15,
    Airport = 16,
    Airfield = 17,
    BuoyPoint = 18,
    BoatRamp = 19,
    FinishLine1 = 20,
    FinishLine2 = 21,
    BoatStart = 22,
    SplitBoat = 23,
    ZeroSpeedPoint = 24
}

export enum UnitPointType {
    Other = 0,
    StageStart = 1,
    FlyingFinish = 2,
    TransitPoint = 3,
    TransitStart = 4,
    TransitFinish = 5,
    ZeroSpeedPoint = 6,
    ChicaneStart = 7,
    ChicaneFinish = 8,
    RestrictionStart = 9,
    RestrictionFinish = 10,
    QuietZoneStart = 11,
    QuietZoneFinish = 12,
    Waypoint = 13,
    SplitPoint = 14,
    ResetPoint = 15, //
    HeatStart = 16, //
    LapPoint = 17, //
    TimeControl = 18,
    GridPosition = 19, //
    PitPosition = 20, //
    PitEntry = 21, //
    PitExit = 22, //
    BuoyPoint = 23, //
    FinishLinePoint1 = 24, //
    FinishLinePoint2 = 25, //
    SplitBoat = 26, //
    BoatStart = 27, //
    StartLinePoint1 = 28, //
    StartLinePoint2 = 29, //
    RaceEnd = 99, //
    LogStart = 100,  // Log Start for course point
    LogFinish = 101,  // Log Finish for course point
}

export const ConvertWebPointToUnitPoint = (webPoint: WebPointType) => {
    switch (webPoint) {
        case WebPointType.Other:
            return UnitPointType.Other
        case WebPointType.StageStart:
            return UnitPointType.StageStart
        case WebPointType.StageFinish:
            return UnitPointType.FlyingFinish
        case WebPointType.TransitStart:
            return UnitPointType.TransitStart
        case WebPointType.TransitFinish:
            return UnitPointType.TransitFinish
        case WebPointType.TransitPoint:
            return UnitPointType.TransitPoint
        case WebPointType.QuietZoneStart:
            return UnitPointType.QuietZoneStart
        case WebPointType.QuietZoneFinish:
            return UnitPointType.QuietZoneFinish
        case WebPointType.ChicaneStart:
            return UnitPointType.ChicaneStart
        case WebPointType.ChicaneFinish:
            return UnitPointType.ChicaneFinish
        case WebPointType.RestrictionStart:
            return UnitPointType.RestrictionStart
        case WebPointType.RestrictionFinish:
            return UnitPointType.RestrictionFinish
        case WebPointType.Waypoint:
            return UnitPointType.Waypoint
        case WebPointType.SplitPoint:
            return UnitPointType.SplitPoint
        case WebPointType.TimeControl:
            return UnitPointType.TimeControl
        case WebPointType.BuoyPoint:
            return UnitPointType.BuoyPoint
        case WebPointType.FinishLine1:
            return UnitPointType.FinishLinePoint1
        case WebPointType.FinishLine2:
            return UnitPointType.FinishLinePoint2
        case WebPointType.BoatStart:
            return UnitPointType.BoatStart
        case WebPointType.SplitBoat:
            return UnitPointType.SplitBoat
        case WebPointType.ZeroSpeedPoint:
            return UnitPointType.ZeroSpeedPoint
        case WebPointType.Airport:
        case WebPointType.Refuel:
        case WebPointType.BoatRamp:
        default:
            return UnitPointType.Other
    }
}

export const GetUnitPointType = (code: string): UnitPointType => {
    switch (code) {
        case "O":
            return UnitPointType.Other
        case "SS":
            return UnitPointType.StageStart
        case "SF":
            return UnitPointType.FlyingFinish
        case "TP":
            return UnitPointType.TransitPoint
        case "TS":
            return UnitPointType.TransitStart
        case "TF":
            return UnitPointType.TransitFinish
        case "ZP":
            return UnitPointType.ZeroSpeedPoint
        case "CS":
            return UnitPointType.ChicaneStart
        case "CF":
            return UnitPointType.ChicaneFinish
        case "RS":
            return UnitPointType.RestrictionStart
        case "RF":
            return UnitPointType.RestrictionFinish
        case "QS":
            return UnitPointType.QuietZoneStart
        case "QF":
            return UnitPointType.QuietZoneFinish
        case "WP":
            return UnitPointType.Waypoint
        case "SP":
            return UnitPointType.SplitPoint
        case "RP":
            return UnitPointType.ResetPoint
        case "HS":
            return UnitPointType.HeatStart
        case "LP":
            return UnitPointType.LapPoint
        case "TC":
            return UnitPointType.TimeControl
        case "GP":
            return UnitPointType.GridPosition
        case "PP":
            return UnitPointType.PitPosition
        case "PL":
            return UnitPointType.PitEntry
        case "PR":
            return UnitPointType.PitExit
        case "BP":
            return UnitPointType.BuoyPoint
        case "F1":
            return UnitPointType.FinishLinePoint1
        case "F2":
            return UnitPointType.FinishLinePoint2
        case "SB":
            return UnitPointType.SplitBoat
        case "BS":
            return UnitPointType.BoatStart
        case "L1":
            return UnitPointType.StartLinePoint1
        case "L2":
            return UnitPointType.StartLinePoint2
        case "RE":
            return UnitPointType.RaceEnd
        case "LS":
            return UnitPointType.LogStart
        case "FL":
            return UnitPointType.LogFinish
        default:
            return UnitPointType.Other
    }
}

export const GetLocationPointCode = (type: UnitPointType): string => {
    switch (type) {
        case UnitPointType.Other:
            return "O"
        case UnitPointType.StageStart:
            return "SS"
        case UnitPointType.FlyingFinish:
            return "SF"
        case UnitPointType.TransitPoint:
            return "TP"
        case UnitPointType.TransitStart:
            return "TS"
        case UnitPointType.TransitFinish:
            return "TF"
        case UnitPointType.ZeroSpeedPoint:
            return "ZP"
        case UnitPointType.ChicaneStart:
            return "CS"
        case UnitPointType.ChicaneFinish:
            return "CF"
        case UnitPointType.RestrictionStart:
            return "RS"
        case UnitPointType.RestrictionFinish:
            return "RF"
        case UnitPointType.QuietZoneStart:
            return "QS"
        case UnitPointType.QuietZoneFinish:
            return "QF"
        case UnitPointType.Waypoint:
            return "WP"
        case UnitPointType.SplitPoint:
            return "SP"
        case UnitPointType.ResetPoint:
            return "RP"
        case UnitPointType.HeatStart:
            return "HS"
        case UnitPointType.LapPoint:
            return "LP"
        case UnitPointType.TimeControl:
            return "TC"
        case UnitPointType.GridPosition:
            return "GP"
        case UnitPointType.PitPosition:
            return "PP"
        case UnitPointType.PitEntry:
            return "PL"
        case UnitPointType.PitExit:
            return "PR"
        case UnitPointType.BuoyPoint:
            return "BP"
        case UnitPointType.FinishLinePoint1:
            return "F1"
        case UnitPointType.FinishLinePoint2:
            return "F2"
        case UnitPointType.SplitBoat:
            return "SB"
        case UnitPointType.BoatStart:
            return "BS"
        case UnitPointType.StartLinePoint1:
            return "L1"
        case UnitPointType.StartLinePoint2:
            return "L2"
        case UnitPointType.RaceEnd:
            return "RE"
        case UnitPointType.LogStart:
            return "LS"
        case UnitPointType.LogFinish:
            return "FL"
        default:
            return "O"
    }
}

export enum StageStatus {
    NotChecked,
    Amber,
    Green,
    Live,
    Completed,
    Abandoned,
    Downgraded,
    FlagActive
}

export enum LocationGroupType {
    Other = 0,
    SpecialStage = 1,
    ServicePark = 2,
    ParcFerme = 3,
    Refuel = 4,
    Regroup = 5,
    QuietZone = 6
}

export enum BeamStatus {
    NotSetup,
    Misaligned,
    Aligned,
    PoweredOff
}

export const GetBeamStatusText = (beamStatus: BeamStatus): string => {
    switch (beamStatus) {
        case BeamStatus.NotSetup:
            return 'Not Setup'
        case BeamStatus.PoweredOff:
            return 'Powered Off'
        default: return BeamStatus[beamStatus]
    }
}

export const GetWebPointCode = (type: WebPointType | undefined): string => {
    switch (type) {
        case WebPointType.Other:
            return "O"
        case WebPointType.StageStart:
            return "SS"
        case WebPointType.StageFinish:
            return "SF"
        case WebPointType.TransitStart:
            return "TS"
        case WebPointType.TransitFinish:
            return "TF"
        case WebPointType.TransitPoint:
            return "TP"
        case WebPointType.QuietZoneStart:
            return "QS"
        case WebPointType.QuietZoneFinish:
            return "QF"
        case WebPointType.ChicaneStart:
            return "CS"
        case WebPointType.ChicaneFinish:
            return "CF"
        case WebPointType.RestrictionStart:
            return "RS"
        case WebPointType.RestrictionFinish:
            return "RF"
        case WebPointType.Waypoint:
            return "WP"
        case WebPointType.SplitPoint:
            return "SP"
        case WebPointType.TimeControl:
            return "TC"
        case WebPointType.Refuel:
            return "RF"
        case WebPointType.Airport:
            return "AP"
        case WebPointType.Airfield:
            return "AF"
        case WebPointType.BuoyPoint:
            return "BP"
        case WebPointType.BoatRamp:
            return "BR"
        case WebPointType.FinishLine1:
            return "L1"
        case WebPointType.FinishLine2:
            return "L2"
        case WebPointType.BoatStart:
            return "BS"
        case WebPointType.SplitBoat:
            return "SB"
        case WebPointType.ZeroSpeedPoint:
            return "ZP"
        default:
            return "O"
    }
}

export const getStagePercentage = (s: Stage, distance: number) => {
    let percent = 0
    if (distance === null || s.length === null || s.length === undefined || distance === 0 || s.length === 0) return 0
    var result = distance / s.length * 100
    if (result > 100) {
        percent = 100
    }
    percent = Math.round(result * Math.pow(10, 2)) / Math.pow(10, 2)

    if (percent > 100)
        percent = 100
    if (percent < 0)
        percent = 0

    return percent
}

export const getStageStatusColor = (stageStatus?: StageStatus, light?: boolean) => {
    const polyNotChecked = `rgb(95,121,156)`
    const polyAmber = `rgb(237,133,21)`
    const polyGreen = `rgb(19,168,25)`
    const polyLive = `rgb(0,245,10)`
    const polyDowngraded = light ? `rgb(242,33,33)` : `rgb(242,22,22)`
    const polyAbandoned = `rgb(0,0,0)`
    const polyCompleted = light ? `rgb(24,118,242)` : `rgb(22,117,242)`
    const polyEventRoute = `rgb(105,105,105)`

    switch (stageStatus) {
        case StageStatus.NotChecked:
            return polyNotChecked
        case StageStatus.Amber:
            return polyAmber
        case StageStatus.Green:
            return polyGreen
        case StageStatus.Live:
            return polyLive
        case StageStatus.Completed:
            return polyCompleted
        case StageStatus.Abandoned:
            return polyAbandoned
        case StageStatus.Downgraded:
        case StageStatus.FlagActive:
            return polyDowngraded
        default:
            return polyEventRoute
    }
}

export const getStageDisplayName = (stage: Stage, nameOnly?: boolean) => {
    if (!stage || (stage && !stage.name)) return ''

    if (nameOnly) {
        if (stage.name.substring(0, 2) != 'SS')
            return stage.name
        else
            return stage.name.substring(4, stage.name.length).trim()
    }

    if (stage.number != undefined && stage.name.length > 2 && stage.name.substring(0, 2) != 'SS') {
        return `${stage.number} - ${stage.name}`
    }
    return `${stage.name}`
}

export const getStageStatusText = (stageStatus: StageStatus) => {
    switch (stageStatus) {
        case StageStatus.NotChecked: return 'Not Checked'
        case StageStatus.FlagActive: return 'Flag Active'
        default:
            return StageStatus[stageStatus]
    }
}

export interface StagePoint {
    locationPointId: number
    locationGroupId: number
    geoLocation?: GeoLocation
    type: WebPointType
    order: number
    timeControlId?: number
    timeControlCode: string
    data?: number
    comment: string
    stageFileLineNumber: number
}

export const getStagePointCode = (stagePoint: StagePoint, stage: Stage) => {
    if (!stagePoint) return ''
    switch (stagePoint.type) {
        case WebPointType.StageStart: return `SS${stage.number}`
        case WebPointType.StageFinish: return `SF${stage.number}`
        case WebPointType.TimeControl:
        case WebPointType.TransitFinish:
            return `${stagePoint.comment}`
    }
    return `${stagePoint.comment}`
}

export interface UnitStagePoint {
    stagePointId: number
    index: number
    code: string
    data?: number
    text: string
    stageNumber: number
    longitude?: number
    latitude?: number
}

export interface StageMapBasic {
    StageMapId: number
    Name: string
    MaxStageLength?: number
    Events?: EventBasic[]
}

export interface StageMap {
    stageMapId: number
    name: string
    maxStageLength: number
    eventClassStageMaps: EventClassStageMap[]
    events: EventBasic[]
    stagePoints: UnitStagePoint[]
}

export interface EventClassStageMap {
    start?: Date
    class: ClassModel
    classId: number
    event?: EventBasic
    eventId: number
    stageMapId: number
}

export interface GeoLocation {
    geoLocationId: number
    name: string
    latitude: number
    longitude: number
}

export interface PolylineBasic {
    polyLineId: number
    name: string
    polyPointCount?: number
}

export interface Polyline extends PolylineBasic {
    polyPoints: PolyPoint[]
}

export interface PolyPoint {
    polyPointId: number
    lat: number
    long: number
}

export interface Split {
    order: number
    data: number
}

export interface Stage {
    locationGroupId: number
    name: string
    number: number
    order: number
    eventId: number
    length?: number
    polyLine?: Polyline | PolylineBasic
    polyLineId?: number
    isTransit: boolean
    status: StageStatus
    type: LocationGroupType
    liveTimestamp?: string
    closedTimestamp?: string
    //maxTime?: number
    useActualTimes?: boolean
    removeRestrictionTimeFromTotal?: boolean
    firstCarDue?: string
    zeroCarStartTimestamp?: string
    zeroCarFinishTimestamp?: string
    sweepCarStartTimestamp?: string
    sweepCarFinishTimestamp?: string
    startBeamNumber?: number
    finishBeamNumber?: number
    startBeamLastBreakTimestamp?: string
    finishBeamLastBreakTimestamp?: string
    startBeamStatus: BeamStatus
    finishBeamStatus: BeamStatus
    duplicateOfStage?: number
    splits: Split[]
    speedLimit?: number
}

export interface RoutePoint {
    eventRoutePointId: number
    eventRouteId: number
    lat: number
    long: number
    order: number | null
}

export interface Route {
    eventRouteId: number
    name: string
    routeType?: number
    eventRoutePoints: RoutePoint[]
    pointCount?: number
}

export const blankStage: Stage = {
    locationGroupId: -1,
    eventId: -1,
    name: '',
    number: 0,
    polyLineId: -1,
    isTransit: false,
    status: StageStatus.NotChecked,
    type: LocationGroupType.Other,
    order: 0,
    startBeamStatus: BeamStatus.NotSetup,
    finishBeamStatus: BeamStatus.NotSetup,
    splits: [],
    removeRestrictionTimeFromTotal: false,
    firstCarDue: '',
}

export const blankPolyline: Polyline = {
    polyLineId: -1,
    name: '{event_name} {year} {stage_name}',
    polyPointCount: 0,
    polyPoints: []
}

export const blankRoute: Polyline = {
    polyLineId: -1,
    name: '{route_name}',
    polyPointCount: 0,
    polyPoints: []
}

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface ItineraryState {
    stagesPending: boolean
    stages: Stage[]
    stagesLoaded: boolean
    stagePointsPending: boolean
    stagePoints: StagePoint[]
    stagePointsLoaded: boolean
    stageMaps: StageMap[]
    eventStageMaps: StageMap[]
    stageMapsPending: boolean
    routes: Route[]
    routesLoaded: boolean
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

interface RequestStages { type: 'REQUEST_STAGES' }
interface ReceiveStages { type: 'RECEIVE_STAGES', stages?: Stage[], includePolyline?: boolean }
interface StagesLoaded { type: 'STAGES_LOADED', stagesLoaded: boolean }
interface ClearStages { type: 'CLEAR_STAGES' }

interface RequestStagePoints { type: 'REQUEST_STAGE_POINTS' }
interface ReceiveStagePoints { type: 'RECEIVE_STAGE_POINTS', stagePoints?: StagePoint[], append?: boolean }
interface StagePointsLoaded { type: 'STAGE_POINTS_LOADED', stagePointsLoaded: boolean }
interface ClearStagePoints { type: 'CLEAR_STAGE_POINTS' }

interface RequestStageMaps { type: 'REQUEST_STAGE_MAPS' }
interface ReceiveStageMaps { type: 'RECEIVE_STAGE_MAPS', stageMaps?: StageMap[], eventId?: number }
interface ClearStageMaps { type: 'CLEAR_STAGE_MAPS' }

interface RequestRoutes { type: 'REQUEST_ROUTES' }
interface ReceiveRoutes { type: 'RECEIVE_ROUTES', routes?: Route[] }
interface RoutesLoaded { type: 'ROUTES_LOADED', routesLoaded: boolean }

interface ReceiveItineraryFailed { type: 'RECEIVE_ITINERARY_FAILED' }

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = RoutesLoaded | StagePointsLoaded | StagesLoaded | RequestStages | ReceiveStages | ClearStages | RequestStagePoints | ReceiveStagePoints | ClearStagePoints | ReceiveItineraryFailed | RequestStageMaps | ReceiveStageMaps | ClearStageMaps | RequestRoutes | ReceiveRoutes

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const itineraryActionCreators = {
    requestStages: (eventId: number, includePolyline?: boolean): AppThunkAction<KnownAction> => async (dispatch) => {
        dispatch({ type: 'REQUEST_STAGES' })
        return Api.requestStages(eventId, includePolyline)
            .then(result => {
                dispatch({
                    type: 'RECEIVE_STAGES',
                    stages: result,
                    includePolyline
                })
                dispatch({
                    type: 'STAGES_LOADED',
                    stagesLoaded: true
                })
            })
            .catch((err: number) => {
                dispatch({
                    type: 'RECEIVE_STAGES'
                })
                dispatch({
                    type: 'RECEIVE_ITINERARY_FAILED'
                })
            })
    },
    stagesLoaded: (stagesLoaded: boolean): AppThunkAction<KnownAction> => async (dispatch) => {
        dispatch({ type: 'STAGES_LOADED', stagesLoaded })
    },
    clearStages: (): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: 'CLEAR_STAGES' })
    },
    requestStagePoints: (eventId: number): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: 'REQUEST_STAGE_POINTS' })
        return Api.requestStagePoints(eventId)
            .then(result => {
                dispatch({
                    type: 'RECEIVE_STAGE_POINTS',
                    stagePoints: result
                })
                dispatch({
                    type: 'STAGE_POINTS_LOADED',
                    stagePointsLoaded: true
                })
            })
            .catch((err: number) => {
                dispatch({
                    type: 'RECEIVE_STAGE_POINTS'
                })
                dispatch({
                    type: 'RECEIVE_ITINERARY_FAILED'
                })
            })
    },
    stagePointsLoaded: (stagePointsLoaded: boolean): AppThunkAction<KnownAction> => async (dispatch) => {
        dispatch({ type: 'STAGE_POINTS_LOADED', stagePointsLoaded })
    },
    clearStagePoints: (): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: 'CLEAR_STAGE_POINTS' })
    },
    requestStageMaps: (eventId?: number, search?: string): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: 'REQUEST_STAGE_MAPS' })
        return Api.requestStageMaps(eventId, search)
            .then(result => dispatch({
                type: 'RECEIVE_STAGE_MAPS',
                stageMaps: result,
                eventId: eventId
            }))
            .catch((err: number) => {
                dispatch({
                    type: 'RECEIVE_STAGE_MAPS'
                })
                dispatch({
                    type: 'RECEIVE_ITINERARY_FAILED'
                })
            })
    },
    clearStageMaps: (): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: 'CLEAR_STAGE_MAPS' })
    },
    requestRoutes: (eventId: number): AppThunkAction<KnownAction> => async (dispatch) => {
        dispatch({ type: 'REQUEST_ROUTES' })
        return Api.requestRoutes(eventId)
            .then(result => {
                dispatch({
                    type: 'RECEIVE_ROUTES',
                    routes: result
                })
                dispatch({
                    type: 'ROUTES_LOADED',
                    routesLoaded: true
                })
            })
            .catch((err: number) => {
                dispatch({
                    type: 'RECEIVE_ROUTES'
                })
            })
    },
    routesLoaded: (routesLoaded: boolean): AppThunkAction<KnownAction> => async (dispatch) => {
        dispatch({ type: 'ROUTES_LOADED', routesLoaded })
    },
}

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.
const unloaded: ItineraryState = {
    stagesPending: false,
    stages: [],
    stagesLoaded: false,
    stagePointsPending: false,
    stagePoints: [],
    stagePointsLoaded: false,
    stageMapsPending: false,
    stageMaps: [],
    eventStageMaps: [],
    routes: [],
    routesLoaded: false,
}

export const reducer: Reducer<ItineraryState> = (state: ItineraryState | undefined, incomingAction: Action): ItineraryState => {
    if (state === undefined) return unloaded
    const action = incomingAction as KnownAction

    switch (action.type) {
        case 'REQUEST_STAGES':
            return {
                ...state,
                stagesPending: true
            }
        case 'RECEIVE_STAGES':
            let newStages: Stage[] = []
            if (!action.includePolyline && action.stages) {
                newStages = [...action.stages]
                newStages.map(x => {
                    let s = state.stages.find(y => y.locationGroupId == x.locationGroupId)
                    if (s) {
                        x.polyLine = s.polyLine
                        x.polyLineId = s.polyLineId
                    }
                })
            } else {
                newStages = action.stages ?? state.stages
            }
            return {
                ...state,
                stagesPending: false,
                stages: newStages
            }
        case 'STAGES_LOADED':
            return state.stagesLoaded != action.stagesLoaded
                ? {
                    ...state,
                    stagesLoaded: action.stagesLoaded
                } : state
        case 'CLEAR_STAGES':
            return {
                ...state,
                stages: [],
                routes: []
            }
        case 'REQUEST_STAGE_POINTS':
            return {
                ...state,
                stagePointsPending: true
            }
        case 'RECEIVE_STAGE_POINTS':
            return {
                ...state,
                stagePointsPending: false,
                stagePoints: action.stagePoints
                    ? action.append
                        ? state.stagePoints.concat(action.stagePoints)
                        : action.stagePoints
                    : state.stagePoints
            }
        case 'STAGE_POINTS_LOADED':
            return state.stagePointsLoaded != action.stagePointsLoaded
                ? {
                    ...state,
                    stagePointsLoaded: action.stagePointsLoaded
                } : state
        case 'CLEAR_STAGE_POINTS':
            return {
                ...state,
                stagePoints: []
            }
        case 'RECEIVE_STAGE_MAPS':
            return {
                ...state,
                stageMapsPending: false,
                stageMaps: action.stageMaps && !action.eventId ? action.stageMaps : state.stageMaps,
                eventStageMaps: action.stageMaps && action.eventId ? action.stageMaps : state.eventStageMaps
            }
        case 'CLEAR_STAGE_MAPS':
            return {
                ...state,
                stageMaps: [],
                eventStageMaps: []
            }
        case 'REQUEST_ROUTES':
            return {
                ...state
            }
        case 'RECEIVE_ROUTES':
            return {
                ...state,
                routes: action.routes ? action.routes : state.routes
            }
        case 'ROUTES_LOADED':
            return state.routesLoaded != action.routesLoaded
                ? {
                    ...state,
                    routesLoaded: action.routesLoaded
                } : state
        case 'RECEIVE_ITINERARY_FAILED':
            return {
                ...state
            }
        default:
            return state
    }
}