import { Box, Button, CircularProgress, Container, FormHelperText, Link, Paper, TextField, Typography } from '@mui/material'
import * as React from 'react'
import { connect } from 'react-redux'
import { forgotPassword } from '../../api/Identity'
import { ApplicationState } from '../../store'
import * as IdentityStore from '../../store/Identity'
import { Link as RouterLink } from 'react-router-dom'
import { useState } from 'react'

type LoginProps =
    IdentityStore.IdentityState
    & typeof IdentityStore.identityActionCreators

const ForgotPassword: React.FunctionComponent<LoginProps> = (props) => {
    const {
        isAuthenticated, status,
        login
    } = props

    const [email, setEmail] = useState<string>('')
    const [errorText, setErrorText] = useState<string>('')
    const [successfullText, setSuccessfullText] = useState<string>('')
    const [loading, setLoading] = useState<boolean>(false)

    const onEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.currentTarget.value)
    }

    const onSubmit = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault()
        if (email !== '') {
            setLoading(true)
            forgotPassword(email)
                .then(result => {
                    result.json()
                        .then(result => {
                            setSuccessfullText(result.message)
                            setLoading(false)
                        })
                })
                .catch(err => {
                    setErrorText(err.message)
                    setLoading(false)
                })
        }
    }

    return <Container maxWidth='sm'>
        <Paper
            elevation={3}
            style={{
                paddingBottom: 36,
                margin: '0 auto',
                textAlign: 'center'
            }}>
            <form style={{
                maxWidth: 250,
                margin: '0px auto',
                lineHeight: 5,
                textAlign: 'left'
            }}>
                <Typography variant="h4" color="inherit" noWrap style={{ marginBottom: 20, marginTop: 10, fontSize: '1.8rem' }}>
                    Forgot Password
                </Typography>
                {successfullText === ''
                    ? <React.Fragment>
                        <TextField
                            label="Email"
                            required
                            placeholder='Enter your email address'
                            onChange={onEmailChange}
                            fullWidth={true}
                            autoFocus
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        <FormHelperText error={errorText != ''}>{errorText}</FormHelperText>
                        <Button
                            onClick={onSubmit}
                            fullWidth={true}
                            variant='contained'
                            color='primary'
                            type='submit'
                            disabled={loading}
                        >
                            Submit
                        </Button>
                        <div style={{ justifyContent: 'center', display: 'flex' }}>
                            {loading && <CircularProgress style={{ alignSelf: 'center' }} size={28} />}
                        </div>
                    </React.Fragment>
                    : <Box color="success.main" marginBottom={5}>
                        <Typography variant='body1' style={{}}>
                            {successfullText}
                        </Typography>
                    </Box>
                }
            </form>
            <Link
                to='/login'
                component={RouterLink}
                style={{ fontSize: 12 }}
                underline='hover'
            >
                Back to log in
            </Link>
        </Paper>
    </Container>
}

export default connect(
    (state: ApplicationState) => state.identity,
    IdentityStore.identityActionCreators
)(ForgotPassword as any)
