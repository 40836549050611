import { Action, Reducer } from 'redux'
import { AppThunkAction } from '.'

import * as Api from '../api/Account'
import { Championship } from './Championship'
import { EventBasic } from './Event'

export type Role = 'Admin'
    | 'Basic'
    | 'CompanyEditor'
    | 'EventEditor'
    | 'EventViewer'
    | 'PerEntryComms'
    | 'SafetyOfficer'
    | 'CompanyViewer'
    | 'TeamsAccess'

export const Admin: Role = 'Admin'
export const Basic: Role = 'Basic'
export const CompanyEditor: Role = 'CompanyEditor'
export const EventEditor: Role = 'EventEditor'
export const EventViewer: Role = 'EventViewer'
export const PerEntryComms: Role = 'PerEntryComms'
export const SafetyOfficer: Role = 'SafetyOfficer'
export const CompanyViewer: Role = 'CompanyViewer'
export const TeamsAccess: Role = 'TeamsAccess'

// Define the order of roles in an array
const roleHierarchy: Role[] = [
    SafetyOfficer,
    PerEntryComms,
    CompanyViewer,
    TeamsAccess,
    Basic,
    EventViewer,
    EventEditor,
    CompanyEditor,
    Admin
]

export const hasAccess = (minimumRole: Role, roles: Role[], alternativeRole?: Role, allowCompanyEditorsOnly?: boolean) => {
    const minRoleIndex = roleHierarchy.indexOf(minimumRole)

    if (minRoleIndex === -1) {
        throw new Error(`Invalid minimum role: ${minimumRole}`)
    }

    if (alternativeRole && roles.some(role => role === alternativeRole))
        return true

    if (allowCompanyEditorsOnly == true)
        return hasAccess(CompanyEditor, roles)

    // Check if any of the roles in the array have an index higher or equal to the minimumRole's index
    return roles.some(role => {
        const roleIndex = roleHierarchy.indexOf(role)
        if (roleIndex === -1) {
            throw new Error(`Invalid role: ${role}`)
        }
        return roleIndex >= minRoleIndex
    })
}

export interface RoleWithId {
    roleId: string
    name: string
}

export interface AccountBasic {
    userId: string
    userName: string
}

export interface Account {
    accountId: string
    userName: string
    normalizedUserName: string
    password?: string
    email: string
    normalizedEmail: string
    lockoutEnabled: boolean
    lockoutEnd: Date
    accessFailedCount: number
    roles: RoleWithId[]
    championships: Championship[]
    events: EventBasic[]
}

export const blankAccount: Account = {
    accountId: '',
    userName: '',
    normalizedUserName: '',
    email: '',
    normalizedEmail: '',
    lockoutEnabled: false,
    lockoutEnd: new Date(),
    accessFailedCount: 0,
    roles: [],
    championships: [],
    events: []
}

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface AccountState {
    pendingAccounts: boolean
    accounts: Account[]
    accountSaved: number | null
    accountDeleted: number | null
    roles: RoleWithId[]
    rolesPending: boolean
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

interface RequestAccounts { type: 'REQUEST_ACCOUNTS' }
interface ReceiveAccounts { type: 'RECEIVE_ACCOUNTS', accounts: Account[], append: boolean }
interface ReceiveAccountFailed { type: 'RECEIVE_ACCOUNTS_FAILED' }
interface AccountSaved { type: 'ACCOUNT_SAVED', accountSaved: number | null }
interface AccountDeleted { type: 'ACCOUNT_DELETED', accountDeleted: number | null }
interface ClearAccounts { type: 'CLEAR_ACCOUNTS' }
interface SaveAccount { type: 'SAVE_ACCOUNT', account: Account }
interface RequestRoles { type: 'REQUEST_ROLES' }
interface ReceiveRoles { type: 'RECEIVE_ROLES', roles: RoleWithId[] }
interface ResetResponses { type: 'RESET_RESPONSES' }

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = RequestAccounts | ReceiveAccounts | ClearAccounts | SaveAccount | ReceiveAccountFailed | AccountSaved | AccountDeleted | RequestRoles | ReceiveRoles | ResetResponses

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const accountActionCreators = {
    requestAccounts: (skip: number, take: number, order: string, search: string, append: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'REQUEST_ACCOUNTS' })
        Api.requestAccounts(skip, take, order, search)
            .then(result => dispatch({
                type: 'RECEIVE_ACCOUNTS',
                accounts: result,
                append: append
            }))
            .catch((err: number) => dispatch({
                type: 'RECEIVE_ACCOUNTS_FAILED'
            }))
    },
    createAccount: (account: Account): AppThunkAction<KnownAction> => (dispatch, getState) => {
        return Api.createAccount(account)
            .then(result => result.json().then(r => {
                dispatch({
                    type: 'ACCOUNT_SAVED',
                    accountSaved: r
                })
            }))
            .catch((err: number) => dispatch({
                type: 'ACCOUNT_SAVED',
                accountSaved: -1
            }))
    },
    updateAccount: (account: Account): AppThunkAction<KnownAction> => (dispatch, getState) => {
        return Api.updateAccount(account)
            .then(result => result.json().then(r => {
                dispatch({
                    type: 'ACCOUNT_SAVED',
                    accountSaved: r
                })
            }))
            .catch((err: number) => dispatch({
                type: 'ACCOUNT_SAVED',
                accountSaved: -1
            }))
    },
    deleteAccount: (account: Account): AppThunkAction<KnownAction> => (dispatch, getState) => {
        return Api.deleteAccount(account.accountId)
            .then(result => result.json().then(r => {
                dispatch({
                    type: 'ACCOUNT_DELETED',
                    accountDeleted: r
                })
            }))
            .catch((err: number) => dispatch({
                type: 'ACCOUNT_DELETED',
                accountDeleted: -1
            }))
    },
    clearAccounts: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'CLEAR_ACCOUNTS' })
    },
    requestRoles: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'REQUEST_ROLES' })
        Api.requestRoles()
            .then(result => dispatch({
                type: 'RECEIVE_ROLES',
                roles: result
            }))
    },
    resetResponses: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'RESET_RESPONSES' })
    },
}

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.
const unloaded: AccountState = {
    pendingAccounts: false,
    accounts: [],
    accountSaved: null,
    accountDeleted: null,
    roles: [],
    rolesPending: false
}

export const reducer: Reducer<AccountState> = (state: AccountState | undefined, incomingAction: Action): AccountState => {
    if (state === undefined) return unloaded
    const action = incomingAction as KnownAction

    switch (action.type) {
        case 'REQUEST_ACCOUNTS':
            return {
                ...state,
                pendingAccounts: true
            }
        case 'RECEIVE_ACCOUNTS':
            let accounts = action.accounts
            if (action.append) {
                accounts = state.accounts.concat(action.accounts)
            }
            return {
                ...state,
                pendingAccounts: false,
                accounts: accounts
            }
        case 'RECEIVE_ACCOUNTS_FAILED':
            return {
                ...state,
                pendingAccounts: false
            }
        case 'ACCOUNT_SAVED':
            return {
                ...state,
                accountSaved: action.accountSaved
            }
        case 'ACCOUNT_DELETED':
            return {
                ...state,
                accountDeleted: action.accountDeleted
            }
        case 'CLEAR_ACCOUNTS':
            return {
                ...state,
                accounts: []
            }
        case 'REQUEST_ROLES':
            return {
                ...state,
                rolesPending: true
            }
        case 'RECEIVE_ROLES':
            return {
                ...state,
                rolesPending: false,
                roles: action.roles
            }
        case 'RESET_RESPONSES':
            return {
                ...state,
                accountSaved: null,
                accountDeleted: null
            }
        default:
            return state
    }
}



