import { stagePointError } from '.'
import { UnitPointType } from '../../../../../store/Itinerary'
import { AdjacentStagePointRule } from '../../../../../store/StageFile'

export const pointFollowingStartLinePoint1IsValid: AdjacentStagePointRule = (currentPoint, nextPoint) => {
    // L1 – can be followed by L2
    if (currentPoint.type.unitPointType == UnitPointType.StartLinePoint1) {
        switch (nextPoint.type.unitPointType) {
            case UnitPointType.StartLinePoint2:
                break
            default:
                return stagePointError(nextPoint, `L1 must be followed by L2 - ${nextPoint.type.code} is invalid`)
        }
    }
}