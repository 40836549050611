import { AdjacentStagePointRule, AdjacentStageRule, AllPointsRule, AllStagesRule, DuplicateStageRule, GroupedStagePointRule, IndividualStagePointRule } from '../../../../../store/StageFile'
import { allTcIdsAreUnique } from './allTcIdsAreUnique'
import { commentLengthIsValid } from './commentLengthIsValid'
import { dataValueIsValid } from './dataValueIsValid'
import { distanceBetweenPointsIsValid } from './distanceBetweenPointsIsValid'
import { firstPointInStageIsValid } from './firstPointInStageIsValid'
import { firstPointIsValid } from './firstPointIsValid'
import { firstStageIsValid } from './firstStageIsValid'
import { locationIsUnique } from './locationIsUnique'
import { locationIsValid } from './locationIsValid'
import { onlyOneFlyingFinishInStage } from './onlyOneFlyingFinishInStage'
import { onlyOneStageStartInStage } from './onlyOneStageStartInStage'
import { onlyOneTransitInStage } from './onlyOneTransitInStage'
import { pointFollowingBoatStartIsValid } from './pointFollowingBoatStartIsValid'
import { pointFollowingBuoyPointIsValid } from './pointFollowingBuoyPointIsValid'
import { pointFollowingChicaneFinishIsValid } from './pointFollowingChicaneFinishIsValid'
import { pointFollowingChicaneStartIsValid } from './pointFollowingChicaneStartIsValid'
import { pointFollowingFinishLinePoint1IsValid } from './pointFollowingFinishLinePoint1IsValid'
import { pointFollowingFinishLinePoint2IsValid } from './pointFollowingFinishLinePoint2IsValid'
import { pointFollowingFlyingFinishIsValid } from './pointFollowingFlyingFinishIsValid'
import { pointFollowingQuietZoneFinishIsValid } from './pointFollowingQuietZoneFinishIsValid'
import { pointFollowingQuietZoneStartIsValid } from './pointFollowingQuietZoneStartIsValid'
import { pointFollowingRestrictionFinishIsValid } from './pointFollowingRestrictionFinishIsValid'
import { pointFollowingRestrictionStartIsValid } from './pointFollowingRestrictionStartIsValid'
import { pointFollowingSplitBoatIsValid } from './pointFollowingSplitBoatIsValid'
import { pointFollowingSplitPointIsValid } from './pointFollowingSplitPointIsValid'
import { pointFollowingStageStartIsValid } from './pointFollowingStageStartIsValid'
import { pointFollowingStartLinePoint1IsValid } from './pointFollowingStartLinePoint1IsValid'
import { pointFollowingStartLinePoint2IsValid } from './pointFollowingStartLinePoint2IsValid'
import { pointFollowingTimeControlIsValid } from './pointFollowingTimeControlIsValid'
import { pointFollowingTransitFinishIsValid } from './pointFollowingTransitFinishIsValid'
import { pointFollowingTransitStartIsValid } from './pointFollowingTransitStartIsValid'
import { pointFollowingZeroSpeedPointIsValid } from './pointFollowingZeroSpeedPointIsValid'
import { pointsBetweenStartAndFinishAreValid } from './pointsBetweenStartAndFinishAreValid'
import { pointsCannotBeBetweenStageStartAndFlyingFinish } from './pointsCannotBeBetweenStageStartAndFlyingFinish'
import { sequenceIdIsValid } from './sequenceIdIsValid'
import { stageNumberIsValid } from './stageNumberIsValid'
import { stagePointMatches } from './stagePointMatches'
import { stageStartHasMatchingFlyingFinish } from './stageStartHasMatchingFlyingFinish'

export { detectErrors, stagePointError, stagePointWarning } from './rules'

export const IndividualRules: IndividualStagePointRule[] = [
    dataValueIsValid,
    commentLengthIsValid,
    locationIsValid
]

export const GroupedRules: GroupedStagePointRule[] = [
    onlyOneStageStartInStage,
    onlyOneFlyingFinishInStage,
    onlyOneTransitInStage,
    pointsBetweenStartAndFinishAreValid,
    firstPointInStageIsValid,
    stageStartHasMatchingFlyingFinish,
    locationIsUnique
]

export const AdjacentRules: AdjacentStagePointRule[] = [
    pointFollowingBoatStartIsValid,
    pointFollowingBuoyPointIsValid,
    pointFollowingChicaneFinishIsValid,
    pointFollowingChicaneStartIsValid,
    pointFollowingFinishLinePoint1IsValid,
    pointFollowingFinishLinePoint2IsValid,
    pointFollowingFlyingFinishIsValid,
    pointFollowingQuietZoneFinishIsValid,
    pointFollowingQuietZoneStartIsValid,
    pointFollowingRestrictionFinishIsValid,
    pointFollowingRestrictionStartIsValid,
    pointFollowingSplitBoatIsValid,
    pointFollowingSplitPointIsValid,
    pointFollowingStageStartIsValid,
    pointFollowingStartLinePoint1IsValid,
    pointFollowingStartLinePoint2IsValid,
    pointFollowingTimeControlIsValid,
    pointFollowingTransitFinishIsValid,
    pointFollowingTransitStartIsValid,
    pointFollowingZeroSpeedPointIsValid,
    stageNumberIsValid,
    sequenceIdIsValid,
    distanceBetweenPointsIsValid
]

export const AllPointsRules: AllPointsRule[] = [
    pointsCannotBeBetweenStageStartAndFlyingFinish,
    firstPointIsValid,
    allTcIdsAreUnique
]

export const AdjacentStageRules: AdjacentStageRule[] = [
    //  stageNumbersIncrementCorrectly
]

export const AllStagesRules: AllStagesRule[] = [
    firstStageIsValid
]

export const DuplicateStageRules: DuplicateStageRule[] = [
    stagePointMatches
]