import { Action, Reducer } from 'redux'
import { AppThunkAction } from '.'

import * as Api from '../api/Identity'
import { Role } from './Account'

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface IdentityState {
    identityPending: boolean
    status: string
    username: string
    roles: Role[]
    isAuthenticated: boolean | null
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

interface RequestIdentity { type: 'REQUEST_IDENTITY' }
interface ReceiveIdentity { type: 'RECEIVE_IDENTITY', username: string, roles: Role[], redirect?: boolean, returnUrl?: string }
interface RemoveIdentity { type: 'REMOVE_IDENTITY' }
interface RejectIdentity { type: 'REJECT_IDENTITY', reason: string }

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = RequestIdentity | ReceiveIdentity | RemoveIdentity | RejectIdentity

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const identityActionCreators = {
    login: (username: string, password: string, returnUrl?: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'REQUEST_IDENTITY' })
        Api.login(username, password)
            .then(result => {
                result.json()
                    .then(result => {

                        dispatch({
                            type: 'RECEIVE_IDENTITY',
                            username: result.username,
                            roles: result.roles as Role[]
                        })
                    })
            })
            .catch((error: Error) => {
                dispatch({
                    reason: error.message,
                    type: 'REJECT_IDENTITY'
                })
            })
    },
    getIdentity: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'REQUEST_IDENTITY' })
        Api.get()
            .then(result => {
                if (result.username && result.username.length > 0) {
                    dispatch({
                        type: 'RECEIVE_IDENTITY',
                        username: result.username,
                        roles: result.roles as Role[]
                    })
                }
            })
            .catch((error: Error) => {
                console.log(error)
            })
    },
    logout: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'REMOVE_IDENTITY' })
    }
}

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.
const unloaded: IdentityState = {
    identityPending: true,
    username: '',
    roles: [],
    status: '',
    isAuthenticated: null
}

export const reducer: Reducer<IdentityState> = (state: IdentityState | undefined, incomingAction: Action): IdentityState => {
    const action = incomingAction as KnownAction
    switch (action.type) {
        case 'REQUEST_IDENTITY':
            let previousState = state != null ? state : unloaded
            return {
                ...previousState,
                identityPending: true
            }
        case 'RECEIVE_IDENTITY':
            return {
                ...unloaded,
                username: action.username,
                roles: action.roles,
                isAuthenticated: action.username == 'PublicUser' ? false : true,
                identityPending: false
            }
        case 'REMOVE_IDENTITY':
            return {
                ...unloaded,
                identityPending: false,
                isAuthenticated: null,
                username: ''
            }
        case 'REJECT_IDENTITY':
            return {
                ...unloaded,
                isAuthenticated: false,
                status: action.reason,
                identityPending: false
            }
        default:
            return state || unloaded
    }

}



