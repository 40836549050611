import * as Core from '.'
import { HttpVerb } from '.'

export function requestTime() {
    return Core.get<string>(`/global/currentTime`)
}

export function getPlane(secondPlane: boolean): Promise<any> {
    //return planeGet<any[]>(`https://opensky-network.org/api/states/all?icao24=3924a0`) // Added in Spain 2022
    return !secondPlane
        ? planeGet<any[]>(`https://opensky-network.org/api/states/all?icao24=44866c`) //https://fr24.com/OOASL
        : planeGet<any[]>(`https://opensky-network.org/api/states/all?icao24=44b0b4`) //https://fr24.com/OOLET (OLD)
}

function planeRequest(verb: HttpVerb, data?: any) {
    let headers = new Headers()
    headers.append('Accept', '*/*')
    headers.append('Content-Type', 'application/json; charset=utf-8')
    headers.append('Authorization', 'Basic ' + window.btoa('ben_24' + ":" + 'VGRxYt7nBY4YLZ1P5m'))

    let req: RequestInit = {
        method: HttpVerb[verb],
        headers: headers,
        credentials: "include"
    }

    if (data) {
        req.body = JSON.stringify(data)
    }

    return req
}


export function planeGet<T>(path: string): Promise<T> {
    return fetch(path, planeRequest(HttpVerb.GET))
        .then(result => {
            if (!result.ok) {
                return result.json().then((r: Error) => { throw r })
            }
            return result.json() as Promise<T>
        })
}