import { Button, Dialog, DialogActions, DialogContent, DialogTitle, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import * as React from 'react'
import { useState } from 'react'

interface Props {
    label: string
    mainText: string
    onConfirm: () => void
}

export const SecureDeleteButton: React.FunctionComponent<Props> = (props) => {
    const { label, mainText, onConfirm } = props
    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'))

    const [open, setOpen] = useState<boolean>(false)

    return (
        <div>
            <Button onClick={() => setOpen(true)} style={{ color: 'rgb(236,44,41)' }}>
                Delete
            </Button>
            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={() => setOpen(false)}
                fullWidth={true}
                maxWidth={'sm'}
            >
                <DialogTitle>
                    {label}
                </DialogTitle>
                <DialogContent>
                    {mainText}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)}>
                        Cancel
                    </Button>
                    <Button onClick={onConfirm} style={{ color: 'white', background: 'rgb(210,34,31)' }} variant={'contained'}>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
