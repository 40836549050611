import { Button, Container, Paper, TextField, Link, Typography, FormHelperText, CircularProgress } from '@mui/material'
import * as React from 'react'
import { connect } from 'react-redux'
import { ApplicationState } from '../../store'
import * as IdentityStore from '../../store/Identity'
import { Heading } from '../Home/Images/Heading'
import { RallySafeLogo } from '../../assets/Logos/RallySafeLogo'
import {
    BrowserRouter as Router,
    Route,
    Link as RouterLink,
    useLocation,
    useNavigate
} from "react-router-dom"
import { FormEvent, useState } from 'react'
import { Box } from '@mui/material'
import { Stack } from '@mui/material'

type LoginProps =
    IdentityStore.IdentityState
    & typeof IdentityStore.identityActionCreators

const Login: React.FunctionComponent<LoginProps> = (props) => {
    const {
        isAuthenticated, status, identityPending,
        login
    } = props

    const [username, setUsername] = useState<string>('')
    const [password, setPassword] = useState<string>('')

    const navigate = useNavigate()
    let location = useLocation()
    let returnUrl = (location.state && location.state != '/login') ? location.state as string : undefined

    if (isAuthenticated) {
        if (returnUrl) {
            navigate(returnUrl)
        } else {
            navigate('/')
        }
    }

    const handleUserChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUsername(event.currentTarget.value)
    }

    const onPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(event.currentTarget.value)
    }

    const onLogin = () => {
        if (username !== '' && password !== '') {
            login(username, password, returnUrl)
        }
    }

    const onFormSubmit = (e: FormEvent) => {
        e.preventDefault()
        onLogin()
    }

    return <Container maxWidth='sm'>
        <Paper
            elevation={3}
            style={{
                paddingBottom: 36,
                margin: '0 auto',
                textAlign: 'center'
            }}>
            <RallySafeLogo height={120} style={{ marginBottom: 10 }} />
            <Heading height={26} style={{ margin: '0 auto', marginBottom: 40, display: 'block' }} />
            <form
                onSubmit={onFormSubmit}
                style={{
                    maxWidth: 250,
                    margin: '0px auto',
                    lineHeight: 5,
                    textAlign: 'left'
                }}>
                <Typography variant="h4" color="inherit" noWrap style={{ marginBottom: 20, fontSize: '1.8rem' }}>
                    Log In
                </Typography>
                <TextField
                    label="Username"
                    required
                    placeholder='John'
                    onChange={handleUserChange}
                    fullWidth={true}
                    autoFocus
                    autoComplete='username'
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <TextField
                    label="Password"
                    required
                    placeholder='Enter your password'
                    type="password"
                    autoComplete='current-password'
                    onChange={onPasswordChange}
                    fullWidth={true}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <Link
                    to='/forgotpassword'
                    component={RouterLink}
                    underline='hover'
                    style={{
                        float: 'right',
                        lineHeight: 0,
                        fontSize: 12,
                        marginBottom: 20,
                        marginTop: -5
                    }}
                >
                    Forgot password?
                </Link>
                <FormHelperText style={{ float: 'left' }} error={status != ''}>{status}</FormHelperText>
                <Button
                    onClick={onLogin}
                    fullWidth={true}
                    variant='contained'
                    color='primary'
                    type='submit'
                    style={{ marginTop: 10 }}
                    disabled={identityPending}
                >
                    Log In
                </Button>
                <div style={{ justifyContent: 'center', display: 'flex' }}>
                    {identityPending && <CircularProgress style={{ alignSelf: 'center' }} size={28} />}
                </div>
                <Stack alignItems='center' width='100%'>
                    <Link
                        to='/events'
                        component={RouterLink}
                        underline='hover'
                        sx={{ fontSize: 14, textAlign: 'center', height: '26px' }}
                    >
                        View public events
                    </Link>
                </Stack>
            </form>
        </Paper>
    </Container>
}

export default connect(
    (state: ApplicationState) => state.identity,
    IdentityStore.identityActionCreators
)(Login)
