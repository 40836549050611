import * as Core from '.'
import { ClassModel } from '../store/Class'
import { Domain } from '../store/Event'

export function requestClasses(skip: number, take: number, searchText: string, order: string): Promise<ClassModel[]> {
    return Core.get<ClassModel[]>(`/class/classTable?skip=${skip}&take=${take}&search=${searchText}&order=${order}`)
}

export function requestEventClasses(eventId: number): Promise<ClassModel[]> {
    return Core.get<ClassModel[]>(`/class/eventClasses?eventId=${eventId}`)
}

export function requestEventClassesList(eventId: number): Promise<ClassModel[]> {
    return Core.get<ClassModel[]>(`/class/event-classes-list?eventId=${eventId}`)
}

export function requestClassList(domain?: Domain): Promise<ClassModel[]> {
    return Core.get<ClassModel[]>(`/class/list?domain=${domain}`)
}

export function createClass(model: ClassModel, eventId: number): Promise<Response> {
    return Core.post(`/class/create?eventId=${eventId}`, model)
}

export function updateClass(model: ClassModel): Promise<Response> {
    return Core.post<ClassModel>(`/class/update`, model)
}

export function deleteClass(model: ClassModel, eventId: number): Promise<Response> {
    return Core.del(`/class/delete?classId=${model.classId}&eventId=${eventId}`)
}