import CheckIcon from '@mui/icons-material/Check'
import ClearIcon from '@mui/icons-material/Clear'
import ForwardIcon from '@mui/icons-material/Forward'
import { IconButton, Paper, Stack, Typography } from '@mui/material'
import { Howl } from 'howler'
import { DateTime } from 'luxon'
import { CustomContentProps, SnackbarContent, useSnackbar } from 'notistack'
import { forwardRef, useEffect, useState } from 'react'
import { requestBeamTabletTime, sendBeamTabletTimeToResults } from '../../../api/Times'
import { getStringFromDateTime, TimingPrecision } from '../../../helper/timeHelper'
import { useInterval } from '../../../helper/useInterval'
import { BeamTabletTime } from '../../../store/Times'
import DingSound from '../../@Global/audio/beambreakding.mp3'
import { Loader } from '../../@Global/Loader'
import { green, red } from '../../Events/Hazards/Models'

interface Props extends CustomContentProps {
    beamTabletTime: BeamTabletTime
}

let sound = new Howl({
    src: [DingSound],
    sprite: {
        'ding': [0, 800, false]
    }
})

export const BeamTabletTimeNotification = forwardRef<HTMLDivElement, Props>(
    ({ id, ...props }, ref) => {

        const { enqueueSnackbar, closeSnackbar } = useSnackbar()

        const [loadingRows, setLoadingRows] = useState<number[]>([])
        const [downloading, setDownloading] = useState<boolean>(false)
        const [showConfirm, setShowConfirm] = useState<boolean>(false)
        const [model, setModel] = useState<BeamTabletTime>(props.beamTabletTime)

        useEffect(() => {
            sound.play('ding')
        }, [])

        useEffect(() => {
            if (model.sentToResults)
                setTimeout(() => closeSnackbar(id), 3500)
        }, [model])

        const downloadData = async () => {
            if (!downloading) {
                setDownloading(true)
                try {
                    let newData = await requestBeamTabletTime(props.beamTabletTime.beamTabletTimeId)
                    if (newData)
                        setModel(newData)
                } catch (e) {

                } finally {
                    setDownloading(false)
                }
            }
        }

        useInterval(() => {
            downloadData()
        }, 1000)

        const onSendToResults = async (row: BeamTabletTime, confirm: boolean) => {
            setLoadingRows(v => [...v, row.beamTabletTimeId])
            try {
                let response = await sendBeamTabletTimeToResults(row.beamTabletTimeId, confirm)
                if (response.status == 202)
                    setShowConfirm(true)
                else {
                    closeSnackbar(id)
                    enqueueSnackbar('Beam time sent', {
                        variant: 'success',
                        autoHideDuration: 2700
                    })
                }
            } catch (e) {
                enqueueSnackbar(e.message, {
                    variant: 'error',
                    autoHideDuration: 2700
                })
            } finally {
                setLoadingRows(v => v.filter(x => x != row.beamTabletTimeId))
            }
        }

        const beamTime = DateTime.fromISO(model.beamTime.substring(0, model.beamTime.length - 1) ?? '1900-01-01T00:00:00')
        const vehicleGpsFinish = DateTime.fromISO(model.vehicleGpsFinish ?? '1900-01-01T00:00:00')

        let endComponent = <Stack direction='row' alignItems='center' spacing={0.5}>
            <IconButton
                sx={{ p: '1px', color: red }}
                onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                    closeSnackbar(id)
                }}
                size='small'>
                <ClearIcon />
            </IconButton>
            {!loadingRows.includes(model.beamTabletTimeId)
                && <IconButton
                    sx={{ p: '1px', color: green, ml: -2 }}
                    onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                        onSendToResults(model, false)
                    }}
                    size='small'>
                    <ForwardIcon />
                </IconButton>}
            {loadingRows.includes(model.beamTabletTimeId)
                && <Loader size={14} loading={true} removeImmediately style={{ m: 0, mr: 1 }} />}
        </Stack>

        if (model.sentToResults) {
            endComponent = <Stack direction='row' alignItems='center' spacing={2}>
                <Typography color='#33cc33'>{`Sent by ${model.sentToResultsUser}`}</Typography>
            </Stack>
        } else if (showConfirm) {
            endComponent = <Stack direction='row' alignItems='center' spacing={2}>
                <Typography>Difference between beam and GPS time is more than 2 seconds. Send anyway? </Typography>
                <Stack direction='row' alignItems='center' spacing={0.5}>
                    <IconButton
                        sx={{ p: '1px', color: red }}
                        onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                            closeSnackbar(id)
                        }}
                        size='small'>
                        <ClearIcon />
                    </IconButton>
                    <IconButton
                        sx={{ p: '1px', color: green, ml: -2 }}
                        onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                            onSendToResults(model, true)
                        }}
                        size='small'>
                        <CheckIcon />
                    </IconButton>
                </Stack>
            </Stack>
        }

        return <SnackbarContent ref={ref}>
            <Paper sx={{ p: 1 }} elevation={8}>
                <Stack direction='row' alignItems='center' spacing={2}>
                    <Typography width='20px'>{model.vehicleIdentifier}</Typography>
                    <Typography>{model.isFinish ? 'SF' : 'SS'}{model.stageNumber}</Typography>
                    <Typography>{getStringFromDateTime(beamTime, TimingPrecision.Thousandth)}</Typography>
                    <Typography>{model.vehicleGpsFinish ? getStringFromDateTime(vehicleGpsFinish, TimingPrecision.Thousandth) : 'No GPS Time'}</Typography>
                    {endComponent}
                </Stack>
            </Paper>
        </SnackbarContent>
    }
)
