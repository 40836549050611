import { UnitPointType } from '../../../../../store/Itinerary'
import { AdjacentStagePointRule } from '../../../../../store/StageFile'
import { stagePointError } from './'

export const pointFollowingTransitStartIsValid: AdjacentStagePointRule = (currentPoint, nextPoint, currentStage, stages) => {
    // TS – can be followed by TC, TF, WP, SP, SS, QS (SP is for Challenge and Winter Trial)
    if (currentPoint.type.unitPointType == UnitPointType.TransitStart || currentPoint.type.unitPointType == UnitPointType.TransitPoint) {
        switch (nextPoint.type.unitPointType) {
            case UnitPointType.TimeControl:
            case UnitPointType.TransitFinish:
            case UnitPointType.Waypoint:
            //   case UnitPointType.SplitPoint:
            case UnitPointType.StageStart:
            case UnitPointType.QuietZoneStart:
                break
            default:
                // Can be followed by Other point if last stage
                if (!(currentStage?.order == stages?.sort((a, b) => b.order - a.order)[0].order && nextPoint.type.unitPointType == UnitPointType.Other)) {
                    return stagePointError(nextPoint, `TS/TP must be followed by TC/TF/WP/SS/QS - ${nextPoint.type.code} is invalid`)
                }
        }
    }
}