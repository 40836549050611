import { useEffect, useState } from 'react'
import { Column } from '../components/Tables/BaseTable'
import { useStickyState } from './useStickyState'

interface DisplayColumn {
    id: string
    display: boolean | null
}

export const useColumns = (columns: Column[], tableId?: string) => {

    const [displayColumns, setDisplayColumns] = tableId
        ? useStickyState<DisplayColumn[]>(columns.map(x => {
            return { id: x.id, display: x.display }
        }), tableId)
        : useState<DisplayColumn[]>(columns.map(x => {
            return { id: x.id, display: x.display }
        }))

    useEffect(() => {
        if (displayColumns.length > 0 && columns.length > 0 && displayColumns.length !== columns.length) {
            setDisplayColumns(columns.map(x => {
                return { id: x.id, display: x.display }
            }))
        }
    }, [columns])

    const onChange = (c: Column[]) => {
        setDisplayColumns(c.map(x => { return { id: x.id, display: x.display } }))
    }

    return [
        columns.map(x => { return { ...x, display: displayColumns.find(y => y.id == x.id)?.display ?? x.display } }),
        columns.filter(x => !x.hidden).filter(x => displayColumns.filter(y => y.display !== false).some(y => y.id == x.id)),
        onChange
    ] as const
}