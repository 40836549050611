import * as React from 'react'
import image from './Images/SAS-Logo-Bars.png'

interface Props {
    height: number
    style?: React.CSSProperties
}

export const SASLogo: React.FunctionComponent<Props> = (props) => {

    return <img
        src={image}
        alt="SAS Logo"
        height={props.height}
        style={props.style}
    />
}
