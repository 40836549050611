import * as Core from '.'
import { Account, AccountBasic, RoleWithId } from '../store/Account'
import { Contact, EventBasic } from '../store/Event'
import { TempUserDetail } from './Identity'

export function requestAccounts(skip: number, take: number, searchText: string, order: string): Promise<Account[]> {
    return Core.get<Account[]>(`/account/table?skip=${skip}&take=${take}&search=${searchText}&order=${order}`)
}

export function createAccount(account: Account): Promise<any> {
    return Core.post(`/account/create`, account)
}

export function updateAccount(account: Account): Promise<any> {
    return Core.post(`/account/update`, account)
}

export function deleteAccount(id: string): Promise<any> {
    return Core.del(`/account/delete`, id)
}

export function requestRoles() {
    return Core.get<RoleWithId[]>(`/account/roles`)
}

export function requestUserEvents(accountId: string, includeChamps?: boolean) {
    return Core.get<EventBasic[]>(`/account/user-events?accountId=${accountId}&includeChamps=${includeChamps}`)
}

export const requestStaff = async () => {
    return Core.get<AccountBasic[]>(`/account/staff`)
}

export function requestStaffedEvents(accountId: string) {
    return Core.get<EventBasic[]>(`/account/user-staffed-events?accountId=${accountId}`)
}

export const requestContacts = async () => {
    return Core.get<Contact[]>(`/account/contacts`)
}

export const createContact = async (model: Contact) => {
    return Core.post(`/account/create-contact`, model)
}

export const updateContact = async (model: Contact) => {
    return Core.post(`/account/update-contact`, model)
}

export function deleteContact(model: Contact): Promise<any> {
    return Core.del(`/account/delete-contact`, model.contactId)
}

export function createTemporaryUser(model: TempUserDetail, eventId: number): Promise<any> {
    return Core.post(`/account/create-temporary-user?eventId=${eventId}`, model)
}

export function deleteTemporaryUser(model: TempUserDetail, eventId: number): Promise<any> {
    return Core.del(`/account/delete-temporary-user?eventId=${eventId}`, model)
}
