import { stagePointError } from '.'
import { UnitPointType } from '../../../../../store/Itinerary'
import { AdjacentStagePointRule } from '../../../../../store/StageFile'

export const pointFollowingBoatStartIsValid: AdjacentStagePointRule = (currentPoint, nextPoint) => {
    // BS – can be followed by F1, SB, SF, WP, BP
    if (currentPoint.type.unitPointType == UnitPointType.BoatStart) {
        switch (nextPoint.type.unitPointType) {
            case UnitPointType.FinishLinePoint1:
            case UnitPointType.SplitBoat:
            case UnitPointType.RestrictionFinish:
            case UnitPointType.Waypoint:
            case UnitPointType.BuoyPoint:
                break
            default:
                return stagePointError(nextPoint, `BS must be followed by F1/SB/SF/WP/BP - ${nextPoint.type.code} is invalid`)
        }
    }
}