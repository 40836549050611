import { stagePointError } from '.'
import { UnitPointType } from '../../../../../store/Itinerary'
import { GroupedStagePointRule } from '../../../../../store/StageFile'

export const firstPointInStageIsValid: GroupedStagePointRule = (stagePoints, hasTimeControls) => {
    // Only applies if the stage number is not set to "121" or “0” - If there are TC points in the file, a TC point must be the first point on a new stage, otherwise, an SS point must always be the first point on a new stage
    let firstPoint = stagePoints.sort((a, b) => a.order - b.order)[0]
    if (firstPoint && firstPoint.stageNumber != 0 && firstPoint.stageNumber < 121) {
        // if (hasTimeControls && firstPoint.Type != UnitPointType.TimeControl) {
        // Until stage files are updated
        if (false) {
            return [stagePointError(firstPoint, `First point of each stage must be a TC - ${firstPoint.type.code} is invalid`)]
        } else if (!hasTimeControls && firstPoint.type.unitPointType != UnitPointType.StageStart) {
            return [stagePointError(firstPoint, `First point of each stage must be a SS - ${firstPoint.type.code} is invalid`)]
        }
    }
}