import { Action, Reducer } from 'redux'
import { AppThunkAction } from '.'

import * as Api from '../api/Person'
import { Domain } from './Event'

export interface Person {
    personId: number
    firstName: string
    surname: string
    countryCode: string
    mobile: string
    email: string
    address: string
    domain: Domain
    skiRacingAustraliaUniqueId?: boolean
    fullName: string
    abbvName: string
}

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface PeopleState {
    pendingPeople: boolean
    people: Person[]
    personSaved: number | null
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

interface RequestPeople { type: 'REQUEST_PEOPLE' }
interface ReceivePeople { type: 'RECEIVE_PEOPLE', people: Person[], append: boolean }
interface ReceivePeopleFailed { type: 'RECEIVE_PEOPLE_FAILED' }
interface PersonSaved { type: 'PERSON_SAVED', personSaved: number | null }
interface ClearPeople { type: 'CLEAR_PEOPLE' }

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = RequestPeople | ReceivePeople | ReceivePeopleFailed | PersonSaved | ClearPeople

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    requestPeople: (skip: number, take: number, searchText: string, order: string, append: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'REQUEST_PEOPLE' })
        Api.requestPeople(skip, take, searchText, order)
            .then(result => dispatch({
                type: 'RECEIVE_PEOPLE',
                people: result,
                append: append
            }))
            .catch((err: number) => dispatch({
                type: 'RECEIVE_PEOPLE_FAILED'
            }))
    },
    savePerson: (person: Person): AppThunkAction<KnownAction> => (dispatch, getState) => {
        return Api.savePerson(person)
            .then(result => dispatch({
                type: 'PERSON_SAVED',
                personSaved: result
            }))
            .catch((err: number) => dispatch({
                type: 'PERSON_SAVED',
                personSaved: -1
            }))
    },
    clearPeople: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'CLEAR_PEOPLE' })
    }
}

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.
const unloaded: PeopleState = {
    pendingPeople: false,
    people: [],
    personSaved: null
}

export const reducer: Reducer<PeopleState> = (state: PeopleState | undefined, incomingAction: Action): PeopleState => {
    if (state === undefined) return unloaded
    const action = incomingAction as KnownAction

    switch (action.type) {
        case 'REQUEST_PEOPLE':
            return {
                ...state,
                pendingPeople: true
            }
        case 'RECEIVE_PEOPLE':
            let people = action.people
            if (action.append) {
                people = state.people.concat(action.people)
            }
            return {
                ...state,
                pendingPeople: false,
                people: people
            }
        case 'RECEIVE_PEOPLE_FAILED':
            return {
                ...state,
                pendingPeople: false
            }
        case 'PERSON_SAVED':
            return {
                ...state,
                personSaved: action.personSaved
            }
        case 'CLEAR_PEOPLE':
            return {
                ...state,
                people: [],
                personSaved: null
            }
        default:
            return state
    }
}



