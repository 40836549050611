import { UnitPointType } from '../../../../../store/Itinerary'
import { AllPointsRule, StagePointError } from '../../../../../store/StageFile'
import { stagePointError } from './rules'

export const allTcIdsAreUnique: AllPointsRule = (stagePoints) => {
    // All TC IDs must be unique
    const tcPoints = stagePoints.filter((sp) => sp.type.unitPointType === UnitPointType.TimeControl)
    const tcIds = tcPoints.map((sp) => sp.data)
    const duplicateTcIds = tcIds.filter((id, index) => tcIds.indexOf(id) !== index)
    if (duplicateTcIds.length > 0) {
        let errors: StagePointError[] = []
        duplicateTcIds.forEach((id) => {
            const sps = tcPoints.filter((sp) => sp.data === id)
            sps.forEach((sp) => errors.push(stagePointError(sp, `Time Control ID ${id} is not unique`)))
        })
        if (errors.length > 0)
            return errors
    }
}
