import { Box, Button, useMediaQuery, useTheme } from '@mui/material'
import { DateTime } from 'luxon'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { getZoneName } from '../../helper/timeHelper'
import { useInterval } from '../../helper/useInterval'
import { useStickyState } from '../../helper/useStickyState'
import { ApplicationState } from '../../store'
import * as EventStore from '../../store/Event'
import * as GlobalStore from '../../store/Global'

export enum TimeZoneFormat {
    Local = 0,
    Event = 1,
    UTC = 2
}

type Props = GlobalStore.GlobalState
    & typeof GlobalStore.globalActionCreators
    & EventStore.EventState

const Clock: React.FunctionComponent<Props> = ({ requestTime, setTimeZoneOffset, timeZoneOffset, timeZoneName, deltaMs, selectedEvent }) => {
    let timeout: NodeJS.Timeout

    const theme = useTheme()
    const screenLarge = useMediaQuery(theme.breakpoints.up('lg'))
    const screenMedium = useMediaQuery(theme.breakpoints.down('lg'))
    const screenSmall = useMediaQuery(theme.breakpoints.down('md'))

    const [currentSelectedEvent, setCurrentSelectedEvent] = useState<string>('')
    const [currentTime, setCurrentTime] = useState<DateTime>(DateTime.local())
    const [timeZoneFormat, setTimeZoneFormat] = useStickyState<TimeZoneFormat>(TimeZoneFormat.Local, 'clockTimeZoneFormat')

    useInterval(() => {
        requestTime()
    }, 60000)

    useInterval(() => {
        tick()
    }, 100)

    useEffect(() => {
        switch (timeZoneFormat) {
            case TimeZoneFormat.Local:
                setTimeZoneOffset(DateTime.local().offset / 60)
                break
            case TimeZoneFormat.Event:
                if (selectedEvent) {
                    setTimeZoneOffset(selectedEvent.timeZone)
                } else {
                    setTimeZoneOffset(DateTime.local().offset / 60)
                }
                break
            case TimeZoneFormat.UTC:
                setTimeZoneOffset(0)
                break
        }
        timeout = setTimeout(() => {
            requestTime()
        }, 5000)
        return () => {
            clearTimeout(timeout)
        }
    }, [])

    useEffect(() => {
        tick(true)
    }, [timeZoneOffset])

    useEffect(() => {
        if (selectedEvent) {
            if (currentSelectedEvent != '' && currentSelectedEvent != selectedEvent.name) {
                setTimeZoneFormat(TimeZoneFormat.Event)
                setTimeZoneOffset(selectedEvent.timeZone)
            } else if (timeZoneFormat == TimeZoneFormat.Event) {
                setTimeZoneOffset(selectedEvent.timeZone)
            }
            setCurrentSelectedEvent(selectedEvent.name)
        }
    }, [selectedEvent])

    const tick = async (force?: boolean) => {
        let realTime = DateTime.local().plus({ milliseconds: deltaMs }).setZone(timeZoneName)
        if (currentTime.second != realTime.second || force) {
            setCurrentTime(realTime)
        }
    }

    const getClockSize = () => {
        if (screenLarge) return 32
        if (screenMedium) return 26
    }

    return <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', px: 1 }}>
        <div style={{
            fontSize: getClockSize(),
            color: '#fff',
            marginBottom: -1,
            height: screenLarge ? '42px' : '36px'
        }}>
            {currentTime.toFormat('HH')}
            <span>:</span>
            {currentTime.toFormat('mm')}
            <span>:</span>
            {currentTime.toFormat('ss')}
        </div>
        {selectedEvent && <Button
            size='small'
            variant='text'
            sx={{
                borderRadius: 24,
                textTransform: 'none',
                fontSize: 12,
                color: 'rgba(255, 255, 255, 0.7)',
                p: 0,
                whiteSpace: 'nowrap'
            }}
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                switch (timeZoneFormat) {
                    case TimeZoneFormat.Local:
                        selectedEvent ? setTimeZoneFormat(TimeZoneFormat.Event) : setTimeZoneFormat(TimeZoneFormat.UTC)
                        selectedEvent ? setTimeZoneOffset(selectedEvent.timeZone) : setTimeZoneOffset(0)
                        break
                    case TimeZoneFormat.Event:
                        setTimeZoneFormat(TimeZoneFormat.UTC)
                        setTimeZoneOffset(0)
                        break
                    case TimeZoneFormat.UTC:
                        setTimeZoneFormat(TimeZoneFormat.Local)
                        setTimeZoneOffset(DateTime.local().offset / 60)
                        break
                }
            }}
        >
            {timeZoneFormat == TimeZoneFormat.Local && 'Local Time'}
            {timeZoneFormat == TimeZoneFormat.Event && selectedEvent && `Event Time (${getZoneName(selectedEvent.timeZone)})`}
            {timeZoneFormat == TimeZoneFormat.UTC && 'UTC/GMT'}
        </Button>}
    </Box>
}

export default connect(
    (state: ApplicationState) => { return { ...state.global, ...state.event } },
    { ...GlobalStore.globalActionCreators }
)(Clock)
