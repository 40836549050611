import { Howl } from 'howler'
import { isNull } from 'lodash'
import { DateTime } from 'luxon'
import Pubnub from 'pubnub'
import { usePubNub } from 'pubnub-react'
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { Dispatch } from 'redux'
import HazardIcon from '../components/@Global/images/hazard.png'
import HybridIcon from '../components/@Global/images/hybrid.png'
import CustomInfoIcon from '../components/@Global/images/info.png'
import RolloverIcon from '../components/@Global/images/rollover.png'
import SosIcon from '../components/@Global/images/sos.png'
import HazardSounds from '../components/@Global/audio/hazard-sounds.mp3'
import { getSafetyStatusFromCode } from '../components/Events/Hazards/Models'
import { ApplicationState } from '../store'
import { Basic, CompanyEditor, EventViewer, SafetyOfficer, hasAccess } from '../store/Account'
import { ElectricalStatus, Entry, GpsStatus, RacingStatus, SafetyStatus, entryActionCreators } from '../store/Entry'
import { eventActionCreators } from '../store/Event'
import { itineraryActionCreators } from '../store/Itinerary'
import { mapActionCreators } from '../store/Map'
import { BeamTabletTime } from '../store/Times'
import { getInitialValue } from './StorageHelper'
import { keysToCamelCase } from './keysHelper'
import { getLuxonTimeFromString, getLuxonTimeFromTicks } from './timeHelper'
import { globalActionCreators } from '../store/Global'
import { Stack, Typography, IconButton } from '@mui/material'
import { closeSnackbar, enqueueSnackbar } from 'notistack'

const sound = new Howl({
    src: [HazardSounds],
    sprite: {
        'sos': [0, 190, true],
        'rollover': [280, 2600, false],
        'hybrid': [2910, 410, false],
        'comms': [3340, 1300, false],
    }
})

const broadcastChannel = new BroadcastChannel('alarms')

export const usePubNubHook = (showHybridNotification: (details: string) => void, eventId?: number) => {
    const roles = useSelector((state: ApplicationState) => state.identity.roles)
    const nightMode = useSelector((state: ApplicationState) => state.event.selectedEvent?.nightMode)
    const autoNightMode = useSelector((state: ApplicationState) => state.event.selectedEvent?.autoNightMode ?? false)
    const entries = useSelector((state: ApplicationState) => state.entry.entries)
    const stages = useSelector((state: ApplicationState) => state.itinerary.stages)
    const isWrc = useSelector((state: ApplicationState) => state.event.selectedEvent?.isWrc ?? false)
    const deltaMs = useSelector((state: ApplicationState) => state.global.deltaMs)
    const hazardClass = useSelector((state: ApplicationState) => state.event.hazardClass)
    const hazardsUnread = useSelector((state: ApplicationState) => state.event.hazardsUnread)

    const [channels, setChannels] = useState<string[]>([])

    const channelsRef = useRef(channels)
    const nightModeRef = useRef(nightMode)
    const entriesRef = useRef(entries)
    const stagesRef = useRef(stages)
    const hazardsUnreadRef = useRef(hazardsUnread)

    let urlLocation = useLocation()
    const urlLocationRef = useRef(urlLocation.pathname.includes('/map'))

    const pubnub = usePubNub()
    const dispatch: Dispatch<any> = useDispatch()

    useEffect(() => {
        entriesRef.current = entries
        stagesRef.current = stages
        urlLocationRef.current = urlLocation.pathname.includes('/map')
        channelsRef.current = channels
        hazardsUnreadRef.current = hazardsUnread
    })

    useEffect(() => {
        broadcastChannel.onmessage = ({
            data
        }) => {
            if (data == 'stop') {
                sound.stop()
                dispatch(eventActionCreators.setHazardClass(''))
            }
        }
    }, [])

    useEffect(() => {
        if (hazardClass == '') {
            broadcastChannel.postMessage('stop')
            sound.stop()
        }
    }, [hazardClass])

    useEffect(() => {
        if (!eventId || !hasAccess(Basic, roles)) return

        const listener = {
            message: (evt) => {
                // Process each message type as per your existing logic
                if (evt.channel.includes('EventUpdate')) {
                    if (evt.message.Type == 4 && hasAccess(EventViewer, roles)) {
                        processNotification(evt)
                    } else if (evt.message.Type == 3 && urlLocationRef.current == true) {
                        processTrackingUpdate(evt)
                    } else if (evt.message.Type == 5) {
                        processStageUpdate()
                    } else if (evt.message.Type == 6 && hasAccess(EventViewer, roles)) {
                        processHazard(evt)
                    }
                } else if (evt.channel.includes('BeamTabletTime')) {
                    let isToggleOn = window.localStorage.getItem(`beamTabletNotifications-${eventId}`) == 'true'
                    let beamTabletTime = keysToCamelCase(evt.message) as BeamTabletTime
                    if (beamTabletTime.vehicleIdentifier != '0' && isToggleOn)
                        enqueueSnackbar('', {
                            variant: 'beamTabletTime',
                            beamTabletTime: beamTabletTime,
                            persist: true
                        })
                } else if (evt.channel.includes('VehicleUpdate')) {
                    dispatch(entryActionCreators.requestEntries('', '', eventId, null, true))
                }
            },
            status: (s) => {
                console.log(s)
                switch (s.category) {
                    case 'PNNetworkUpCategory':
                        pubnub.subscribe({
                            channels: channelsRef.current
                        })
                        break
                    case 'PNNetworkDownCategory':
                        console.log('PubNub connection lost')
                }
            }
        }

        // Determine channels to subscribe based on eventId, roles, etc.
        let channels = [`EventUpdate${eventId}`, `VehicleUpdate${eventId}`]
        if (roles.includes('CompanyEditor')) {
            channels.push(`BeamTabletTime${eventId}`)
        }

        pubnub.addListener(listener)
        pubnub.subscribe({ channels: channels })

        return () => {
            pubnub.unsubscribeAll()
            pubnub.removeListener(listener)
        }
    }, [pubnub, eventId, roles, dispatch, sound])

    useEffect(() => {
        pubnub.subscribe({
            channels: channels
        })
    }, [channels])

    useEffect(() => {
        nightModeRef.current = nightMode ?? autoNightMode
    }, [nightMode, autoNightMode])

    const processNotification = (evt: Pubnub.MessageEvent) => {
        let showNotification = evt.message.NotificationType == 'Safety' && !hasAccess(CompanyEditor, roles, SafetyOfficer)
            ? false
            : true

        if (!(evt.message.Details as string).includes('Fault') || (!isWrc == true || roles.includes(CompanyEditor))) {
            if (evt.message.NotificationType == 'IncomingMessage')
                sound.play('comms')

            if (evt.message.Details.includes('Dangerous spectator')) {
                sound.play('comms')
                dispatch(mapActionCreators.newDangerousSpectator())
            }

            // FIA Green Light Notification
            if (hasAccess(CompanyEditor, roles, SafetyOfficer) && evt.message.NotificationType == 'Safety') {
                if (sound.playing()) sound.stop()

                sound.play('hybrid')
                showHybridNotification(evt.message.Details)
            }

            if (showNotification && eventId) {
                dispatch(globalActionCreators.setNotificationsUnread(0, eventId, 1))
                displayNotification(evt.message.Details, { icon: CustomInfoIcon, tag: '' })
            }
        }
    }

    const processHazard = (evt: Pubnub.MessageEvent) => {
        let alarmSound: boolean = getInitialValue(`hazardsAlarmSound-${eventId}`, true) as boolean
        let logoUrl = evt.message.HazardCode == 'HAZ'
            ? HazardIcon
            : evt.message.HazardCode == 'FIA'
                ? HybridIcon
                : SosIcon

        if (sound.playing())
            sound.stop()

        if (evt.message.HazardCode == 'SOS') {
            if (alarmSound) sound.play('sos')
            dispatch(eventActionCreators.setHazardClass('sos-flash'))
        } else if ((evt.message.SafetyStatus as SafetyStatus) == SafetyStatus.RolloverHazard || (
            (evt.message.SafetyStatus as SafetyStatus) == SafetyStatus.Hazard && evt.message.MaxGforce > 6
        )) {
            logoUrl = RolloverIcon
            if (alarmSound) sound.play('rollover')
            dispatch(eventActionCreators.setHazardClass('rollover-flash'))
        } else if (evt.message.HazardCode == 'FIA') {
            if (sound.playing()) sound.stop()
            if (alarmSound) sound.play('hybrid')
            dispatch(eventActionCreators.setHazardClass('hybrid-flash'))
        }

        dispatch(eventActionCreators.setHazardsUnread(hazardsUnreadRef.current + 1))
        displayNotification(evt.message.Details, { icon: logoUrl, tag: '' })
        // onNewHazard(eventId)
    }

    const displayNotification = (title: string, options: NotificationOptions) => {
        let showNotificationsValue = getInitialValue(`showNotifications${eventId}`, true)
        if (showNotificationsValue) {
            let time = DateTime.local().toFormat('HH:mm:ss')
            options.tag = `${time}-${title}`
            if ('Notification' in window &&
                'serviceWorker' in navigator &&
                'PushManager' in window) {
                if (Notification.permission === "granted") {
                    new Notification(title, options)
                } else if (Notification.permission !== "denied") {
                    Notification.requestPermission().then(function (permission) {
                        // If the user accepts, let's create a notification
                        if (permission === "granted") {
                            new Notification(title, options)
                        }
                    })
                }
            }
        }
    }

    const processStageUpdate = () => {
        if (eventId)
            dispatch(itineraryActionCreators.requestStages(eventId, false))
    }

    const processTrackingUpdate = (evt: Pubnub.MessageEvent) => {
        let e = entriesRef.current.find(x => x.vehicle.vehicleId == evt.message.VehicleId)
        let messageTimestamp = getLuxonTimeFromTicks(evt.message.Ticks)
        if (e && messageTimestamp > getLuxonTimeFromString(e.lastMessageTimestamp)) {
            let stage = stagesRef.current.find(x => x.locationGroupId == evt.message.LocationGroupId)

            // TEMP: The Ticks value should really be the sent timestamp instead of the time the object got created
            if (evt.message.LastUpdatedTime > 0) {
                console.log(`${evt.message.LastUpdatedTime} - ${e.identifier}`)
                messageTimestamp = DateTime.utc().plus({ milliseconds: deltaMs }).minus({ minutes: evt.message.LastUpdatedTime })
            }

            let newEntry: Entry = {
                ...e,
                batteryVoltage: evt.message.BatteryVoltage,
                bearing: evt.message.Bearing,
                competitionStatus: evt.message.CompetitionStatus,
                distance: evt.message.Distance,
                electricalStatus: evt.message.ElectricalStatus == "Charging Engine On" ? ElectricalStatus.ChargingEngineOn : evt.message.ElectricalStatus == "Charging Engine Off" ? ElectricalStatus.ChargingEngineOff : ElectricalStatus.OnBattery,
                entryFlagStatus: evt.message.EntryFlagStatus,
                gpsStatus: evt.message.GpsStatus == 'Signal' ? GpsStatus.Signal : GpsStatus.NoSignal,
                safetyStatus: getSafetyStatusFromCode(evt.message.HazardCode),
                stageNumber: stage && stage.number ? stage.number : 0,
                racingStatus: evt.message.RacingStatus == 'In Transport' ? RacingStatus.InTransport : RacingStatus.Racing,
                //lastUpdated: '0',
                lastMessageTimestamp: messageTimestamp.toISO() ?? '',
                lat: evt.message.Lat,
                lng: evt.message.Lng,
                speed: evt.message.Speed,
                fiaGreenLight: evt.message.FiaGreenLight
                //ticks: evt.message.Ticks,
            }
            dispatch(entryActionCreators.updateEntry(newEntry))

            if (!isNull(evt.message.NightMode) && nightModeRef.current != evt.message.NightMode) {
                console.log(`Changing night mode. Current: ${nightModeRef.current}, Incoming: ${evt.message.NightMode}`)
                dispatch(eventActionCreators.setNightMode(evt.message.NightMode))
            }
        }


    }
}
