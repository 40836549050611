import * as fs from 'fs'

declare global {
    interface String {
        splitCamelCase(): string
        isEmpty(): boolean
    }
}

String.prototype.splitCamelCase = function (this: string) {
    return this.replace(/([^A-Z])([A-Z])/g, '$1 $2').replace(/Rally Safe/g, 'RallySafe')
}

String.prototype.isEmpty = function (this: string) {
    return !this || this === null || this === '' || this === ' '
}