import { Box } from '@mui/material'
import { CSSProperties } from 'react'

type Props = {
    wrap?: boolean
    column?: boolean
    spaceBetween?: boolean
    padding?: string | number
    style?: CSSProperties
    children?: React.ReactNode
}

export const FlexBox: React.FunctionComponent<Props> = (props) => {
    const { children, wrap, column, spaceBetween, padding, style } = props
    return <Box style={{
        display: 'flex',
        flexWrap: wrap ? 'wrap' : 'initial',
        flexDirection: column ? 'column' : 'initial',
        justifyContent: spaceBetween ? 'space-between' : 'initial',
        padding : padding ? padding : 'initial',
        ...style
    }}>
        {children}
    </Box>
}