import { Action, Reducer } from 'redux'
import { AppThunkAction } from '.'

import * as Api from '../api/Class'

export interface ClassModel {
    classId: number
    name: string
    speedLimit: number
    stageMapId: number | null
    showSplitTimes: boolean
    showStageTimes: boolean
    isCompetitive: boolean
    fiaHybrid: boolean
}

export interface ClassBasic {
    name: string
    speedLimit: number
}

export const blankClass: ClassModel = {
    classId: -1,
    name: '',
    speedLimit: 255,
    stageMapId: null,
    showSplitTimes: true,
    showStageTimes: true,
    isCompetitive: true,
    fiaHybrid: false
}

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface ClassState {
    pendingClasses: boolean
    classes: ClassModel[]
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

interface RequestClasses { type: 'REQUEST_CLASSES' }
interface ReceiveClasses { type: 'RECEIVE_CLASSES', classes: ClassModel[], append: boolean }
interface ReceiveFailed { type: 'RECEIVE_FAILED' }
interface ClassSaved { type: 'CLASS_SAVED' }
interface ClearClasses { type: 'CLEAR_CLASSES' }

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = RequestClasses | ReceiveClasses | ReceiveFailed | ClearClasses | ClassSaved

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const classActionCreators = {
    requestClasses: (skip: number, take: number, searchText: string, order: string, append: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'REQUEST_CLASSES' })
        Api.requestClasses(skip, take, searchText, order)
            .then(result => dispatch({
                type: 'RECEIVE_CLASSES',
                classes: result,
                append: append
            }))
            .catch((err: number) => dispatch({
                type: 'RECEIVE_FAILED'
            }))
    },
    requestEventClasses: (eventId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'REQUEST_CLASSES' })
        return Api.requestEventClasses(eventId)
            .then(result => dispatch({
                type: 'RECEIVE_CLASSES',
                classes: result,
                append: false
            }))
            .catch((err: number) => dispatch({
                type: 'RECEIVE_FAILED'
            }))
    },
    clearClasses: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'CLEAR_CLASSES' })
    }
}

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.
const unloaded: ClassState = {
    pendingClasses: false,
    classes: <ClassModel[]>[]
}

export const reducer: Reducer<ClassState> = (state: ClassState | undefined, incomingAction: Action): ClassState => {
    if (state === undefined) return unloaded
    const action = incomingAction as KnownAction

    switch (action.type) {
        case 'REQUEST_CLASSES':
            return {
                ...state,
                pendingClasses: true
            }
        case 'RECEIVE_CLASSES':
            let classes = action.classes
            if (action.append) {
                classes = state.classes.concat(action.classes)
            }
            return {
                ...state,
                pendingClasses: false,
                classes: classes
            }
        case 'RECEIVE_FAILED':
            return {
                ...state,
                pendingClasses: false
            }
        case 'CLEAR_CLASSES':
            return {
                ...state,
                classes: []
            }
        default:
            return state
    }
}
