import { ListItem, ListItemIcon, ListItemText } from '@mui/material'
import * as React from 'react'
import { NavLink } from 'react-router-dom'

type Props = {
    title: string
    location: string
    icon: JSX.Element
    external?: boolean
}

export const SideMenuLink: React.FunctionComponent<Props> = ({ title, location, icon, external }) => {

    const contents = <ListItem button key={title} sx={{ height: 46 }}>
        <ListItemIcon>
            {icon}
        </ListItemIcon>
        <ListItemText primary={title} />
    </ListItem>

    if (external)
        return (
            <a href={location} target='_blank' rel='noreferrer'
                className={'sideLink'}>
                {contents}
            </a>
        )

    return (
        <NavLink
            to={location} end
            className={({ isActive }) =>
                isActive ? 'sideLinkActive' : 'sideLink'
            }>
            {contents}
        </NavLink>
    )
}