import { Stack, Typography } from '@mui/material'
import * as React from 'react'
import { CSSProperties } from 'react'
import { ManufacturerIcon } from '../../../assets/Icons/ManufacturerIcon'

interface Props {
    make: string
    size: number
    iconOnly?: boolean
    style?: CSSProperties
    alignRight?: boolean
    textShort?: boolean
}

export const ManufacturerDisplay: React.FunctionComponent<Props> = ({ make, size, iconOnly, style, alignRight, textShort }) => {
    if (make === null || make === '') return null

    if (iconOnly) {
        return <ManufacturerIcon size={size} path={make} style={{ ...style }} />
    }

    let makeText = textShort && make.indexOf(' ') != -1 ? make.substring(0, make.indexOf(' ')) : make

    const text = <Typography variant={textShort ? 'body1' : 'body2'}>{makeText}</Typography>
    const icon = <ManufacturerIcon size={size} path={make} style={{ ...style }} />

    return <Stack direction='row' spacing={1.5} alignItems='center' sx={{
        minWidth: '120px',
        overflow: 'hidden',
        p: 0
    }}>
        {alignRight ? text : icon}
        {alignRight ? icon : text}
    </Stack>
}
