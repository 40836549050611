import { FormControl, FormHelperText, TextField } from '@mui/material'
import * as React from 'react'
import { CSSProperties, useEffect, useState } from "react"

interface Props {
    onChange: (value: number | undefined | null) => void
    initialValue: number | undefined | null
    label: string
    style?: CSSProperties
    required?: boolean
    validationText?: string
    minLength?: number
    maxLength?: number
    delay?: boolean
    roundTo?: number
}

export const DecimalEditor: React.FunctionComponent<Props> = (props) => {
    const { roundTo, initialValue, label, style, required, validationText, onChange, delay, minLength, maxLength } = props
    const [value, setValue] = useState<string>(initialValue != undefined ? initialValue.toString() : '')

    useEffect(() => {
        if (initialValue != undefined && initialValue.toString() != value)
            setValue(initialValue?.toString() ?? '')
    }, [initialValue])

    useEffect(() => {
        const delayDebounce = setTimeout(() => {
            onValueChanged(value)
        }, delay ? 800 : 0)

        return () => clearTimeout(delayDebounce)
    }, [value])

    const onValueChanged = (v: string) => {

        if (v.substring(v.length - 1) != '-' && v.substring(v.length - 1) != '.') {
            if (v.substring(v.length - 1) == '0' && v.includes('.'))
                v = v.substring(0, v.length - 1)

            let numValue = roundTo ? parseFloat(parseFloat(v).toFixed(roundTo)) : parseFloat(v)
            if (maxLength != undefined && numValue > maxLength) {
                numValue = maxLength
            } else if (minLength != undefined && numValue < minLength) {
                numValue = minLength
            }
            if (v == '' || isNaN(numValue)) {
                setValue('')
                if (required) {
                    onChange(0)
                } else {
                    onChange(undefined)
                }
            } else {
                onChange(numValue)
                if (numValue.toString() != value)
                    setValue(numValue.toString())
            }
        }
    }

    return <FormControl error={validationText && validationText != '' ? true : false} sx={style}>
        <TextField
            required={required}
            label={label}
            value={value ?? ''}
            onChange={(e) => {
                let f = e.target.value.replace(',', '.')
                setValue(f)
            }}
            variant='outlined'
            size='small'
            sx={style}
        />
        {validationText && <FormHelperText error={validationText != ''}>{validationText}</FormHelperText>}
    </FormControl>
}