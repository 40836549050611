import { Box, CircularProgress, LinearProgress, Stack, SxProps } from '@mui/material'
import { DateTime } from 'luxon'
import { useState, useEffect, ReactNode } from 'react'

type Props = {
    loading?: boolean
    size?: number
    linear?: boolean
    fullSize?: boolean
    removeImmediately?: boolean
    content?: ReactNode
    style?: SxProps
}

let timeout: NodeJS.Timeout

export const Loader: React.FunctionComponent<Props> = ({ content, loading, size, linear, fullSize, removeImmediately, style }) => {
    const [dateLoadingStarted, setDateLoadingStarted] = useState<DateTime | undefined>()

    // Ensure the loading icon remains visible for at least 1 second
    useEffect(() => {
        if (loading != undefined && loading == true) {
            setDateLoadingStarted(DateTime.local())
        }
        if (loading == false && dateLoadingStarted) {
            let diff = DateTime.local().diff(dateLoadingStarted, 'milliseconds')
            if (removeImmediately !== true && diff.milliseconds > 0 && diff.milliseconds < 1000) {
                let wait = 1000 - diff.milliseconds
                timeout = setTimeout(() => {
                    if (loading != undefined) {
                        setDateLoadingStarted(undefined)
                    }
                }, wait)
            } else {
                setDateLoadingStarted(undefined)
            }
        }
        return () => {
            clearTimeout(timeout)
        }
    }, [loading])

    const component = linear ? <LinearProgress sx={{ m: 1, minWidth: '100px', ...style }} /> : <CircularProgress sx={{ m: 1, ...style }} size={size ?? 24} />
    return dateLoadingStarted
        ? fullSize
            ? <Stack sx={{ alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%', zIndex: 10 }}>
                {component}
                {content}
            </Stack>
            : component
        : <Box sx={{ m: 1 }} width={size ?? 24} height={size ?? 24} />
    //     : <Box />
    // ? linear ? <LinearProgress sx={{ m: 1, minWidth: '100px' }} /> : <CircularProgress sx={{ m: 1 }} size={size ?? 24} />
    // : <Box sx={{ width: `${size ?? 24}px` }} />
}