import thunk from 'redux-thunk'
import { applyMiddleware, combineReducers, compose, createStore } from 'redux'
import { History } from 'history'

import * as Global from './Global'
import * as Identity from './Identity'
import * as Event from './Event'
import * as Class from './Class'
import * as Equipment from './Equipment'
import * as Itinerary from './Itinerary'
import * as People from './People'
import * as Unit from './Unit'
import * as Entry from './Entry'
import * as Map from './Map'
import * as Account from './Account'
import * as Championship from './Championship'
import * as StageFileState from './StageFile'
import * as Times from './Times'

// The top-level state object
export interface ApplicationState {
    global: Global.GlobalState
    identity: Identity.IdentityState
    event: Event.EventState
    class: Class.ClassState
    equipment: Equipment.EquipmentState
    itinerary: Itinerary.ItineraryState
    people: People.PeopleState
    unit: Unit.UnitState
    entry: Entry.EntryState
    map: Map.MapState
    account: Account.AccountState
    championship: Championship.ChampionshipState
    stageFile: StageFileState.StageFileState
    times: Times.TimesState
}

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
    global: Global.reducer,
    identity: Identity.reducer,
    event: Event.reducer,
    class: Class.reducer,
    equipment: Equipment.reducer,
    itinerary: Itinerary.reducer,
    people: People.reducer,
    unit: Unit.reducer,
    entry: Entry.reducer,
    map: Map.reducer,
    account: Account.reducer,
    championship: Championship.reducer,
    stageFile: StageFileState.reducer,
    times: Times.reducer
}

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction> {
    (dispatch: (action: TAction) => void, getState: () => ApplicationState): void
}

export function configureStore(history: History, initialState?: ApplicationState) {

    const rootReducer = combineReducers(reducers)

    const enhancers: any[] = []
    const windowIfDefined = typeof window === 'undefined' ? null : window as any
    if (windowIfDefined && windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__) {
        enhancers.push(windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__())
    }

    return createStore(
        rootReducer,
        initialState,
        compose(applyMiddleware(thunk), ...enhancers)
    )
}