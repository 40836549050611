import HelpIcon from '@mui/icons-material/Help'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, List, ListItemButton, ListItemIcon, ListItemText, Paper, PaperProps, Stack, useMediaQuery, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Draggable from 'react-draggable'
import { Document, Page, pdfjs } from 'react-pdf'
import 'react-pdf/dist/Page/AnnotationLayer.css'
import 'react-pdf/dist/Page/TextLayer.css'
import { useLocation } from 'react-router-dom'
import AutoSizer from 'react-virtualized-auto-sizer'
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

function DraggablePaperComponent(props: PaperProps) {
    return (
        <Draggable handle=".draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} elevation={6} style={{ height: '100%' }} />
        </Draggable>
    )
}

function PaperComponent(props: PaperProps) {
    return <Paper {...props} elevation={6} sx={{ height: '100%' }} />
}

export const HelpDialog: React.FunctionComponent = () => {

    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
    const location = useLocation()

    const [open, setOpen] = useState(false)
    const [helpData, setHelpData] = useState<string | undefined>()
    const [numPages, setNumPages] = useState<number | null>(null)
    const [landscape, setLandscape] = useState<boolean>(false)

    const manualName = `${location.pathname
        .replace('/', '')
        .split('/')
        .filter(x => !x.match(/(\d)/))
        .join('-')
        }${location.hash !== '' ? '-' + location.hash.replace('#', '') : ''}`

    useEffect(() => {
        const url = `https://racecontrol.blob.core.windows.net/manuals/${manualName}.pdf`
        fetch(url, { method: 'HEAD', mode: process.env.NODE_ENV === 'development' ? 'no-cors' : 'cors' })
            .then(response => setHelpData(response.ok ? url : undefined))
            .catch(reason => console.log(reason))
    }, [location])

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages)
    }

    return <React.Fragment>
        {
            helpData && <Stack height='100%' justifyContent='flex-end'>
                <Divider />
                <List key='help' disablePadding>
                    <ListItemButton onClick={() => setOpen(true)} key='Help' sx={{ height: 46 }}>
                        <ListItemIcon>
                            <HelpIcon fontSize='small' />
                        </ListItemIcon>
                        <ListItemText>Help</ListItemText>
                    </ListItemButton>
                </List>
            </Stack>
        }
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            fullWidth={true}
            maxWidth={landscape ? false : 'lg'}
            PaperComponent={fullScreen ? PaperComponent : DraggablePaperComponent}
        >
            <DialogTitle sx={{ cursor: 'move' }} className="draggable-dialog-title">
                {`Help`}
            </DialogTitle>
            <DialogContent style={{ height: '100%' }}>
                <AutoSizer>
                    {({ height, width }) => (
                        <Document
                            file={helpData}
                            onLoadSuccess={onDocumentLoadSuccess}
                        >
                            {Array.from(
                                new Array(numPages),
                                (el, index) => (
                                    <Page
                                        width={width}
                                        height={height}
                                        key={`page_${index + 1}`}
                                        pageNumber={index + 1}

                                    />
                                ),
                            )}
                        </Document>
                    )}
                </AutoSizer>
            </DialogContent>
            <DialogActions>
                <Stack direction='row' spacing={1}>
                    <Button onClick={() => setOpen(false)} color='primary'>
                        Close
                    </Button>
                </Stack>
            </DialogActions>
        </Dialog>
    </React.Fragment>
}
