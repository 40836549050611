import CloseIcon from '@mui/icons-material/Close'
import RestoreIcon from '@mui/icons-material/Restore'
import { Badge, Box, CircularProgress, ClickAwayListener, Container, Grow, IconButton, Paper, Popper, Stack, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material'
import { Howl } from 'howler'
import { random } from 'lodash'
import { DateTime } from 'luxon'
import { memo, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { areEqual, FixedSizeList, ListChildComponentProps } from 'react-window'
import { Dispatch } from 'redux'
import { requestHazardEvents } from '../../../api/Hazard'
import { useInterval } from '../../../helper/useInterval'
import { useStickyState } from '../../../helper/useStickyState'
import { useWindowSize } from '../../../helper/useWindowSize'
import { ApplicationState } from '../../../store'
import { identityActionCreators } from '../../../store/Identity'
import { itineraryActionCreators } from '../../../store/Itinerary'
import { BooleanEditor } from '../../Inputs/Editors/BooleanEditor'
import { DaySelect } from '../../Inputs/Selectors/DaySelect'
import { StageSelect } from '../../Inputs/Selectors/StageSelect'
import { Window } from '../../Tables/Window'
import { HazardHistory } from './HazardHistory'
import { HazardsRow } from './HazardsRow'
import { HazardsSettings } from './HazardsSettings'
import { HazardIcon } from './Images/HazardIcon'
import { getDates, happenedOnDay, HazardEvent, isOnStage, wasInLastTwoHours } from './Models'
import VolumeOffIcon from '@mui/icons-material/VolumeOff'
import { eventActionCreators } from '../../../store/Event'

type Props = {
    eventId: number
    onMarkHazardsRead: () => void
}

const HazardsButton: React.FunctionComponent<Props> = ({ eventId, onMarkHazardsRead }) => {
    const timeZoneOffset = useSelector((state: ApplicationState) => state.global.timeZoneOffset)
    const stages = useSelector((state: ApplicationState) => state.itinerary.stages)
    const stagesPending = useSelector((state: ApplicationState) => state.itinerary.stagesPending)
    const timeZone = useSelector((state: ApplicationState) => state.event.selectedEvent?.timeZone)
    const hazardClass = useSelector((state: ApplicationState) => state.event.hazardClass)
    const hazardsUnread = useSelector((state: ApplicationState) => state.event.hazardsUnread)

    const [open, setOpen] = useState<null | HTMLElement>(null)
    const [hazards, setHazards] = useState<HazardEvent[]>([])
    const [filteredHazards, setFilteredHazards] = useState<HazardEvent[]>([])
    const [loading, setLoading] = useState<boolean>(false)
    const [lastLoaded, setLastLoaded] = useState<DateTime>()
    const [hazardsDeleted, setHazardsDeleted] = useStickyState<number[]>([], `hazardsDeleted-${eventId}`)
    const [hazardsDay, setHazardsDay] = useStickyState<string | null>(null, `hazardsDay-${eventId}`)
    const [selectedStage, setSelectedStage] = useStickyState<number | null>(null, `hazardsSelectedStage-${eventId}`)
    const [lastTwoHours, setLastTwoHours] = useStickyState<boolean>(true, `hazardsLastTwoHours`)
    const [relativeTime, setRelativeTime] = useStickyState<boolean>(false, `hazardsRelativeTime`)
    const [height, setHeight] = useState<number>(500)
    const [openHistoryBoxes, setOpenHistoryBoxes] = useState<number[]>([])
    const [initialHistoryBoxPosition, setInitialHistoryBoxPosition] = useState<number[]>([200, 200])
    const [alarmSound, setAlarmSound] = useStickyState<boolean>(true, `hazardsAlarmSound-${eventId}`)

    let location = useLocation()
    const navigate = useNavigate()
    const theme = useTheme()
    const screenExtraSmall = useMediaQuery(theme.breakpoints.down('sm'))
    const screenMedium = useMediaQuery(theme.breakpoints.down('lg'))

    const dispatch: Dispatch<any> = useDispatch()
    const windowSize = useWindowSize()
    const isMapPage = location.pathname.includes('/map')

    useInterval(() => {
        if (open) {
            if (!loading) {
                //	getHazards()
            }
            if (stages.length == 0 && !stagesPending) {
                dispatch(itineraryActionCreators.requestStages(eventId, false))
            }
        }
    }, 7000)

    useInterval(() => {
        if (Boolean(open)) {
            if ((!lastLoaded || lastLoaded.diffNow('seconds').seconds < -6) && !loading) {
                getHazards()
            }
        }
    }, 1000)

    useEffect(() => {
        if (open) {
            dispatch(itineraryActionCreators.requestStages(eventId, false))
            getHazards()
        }
    }, [eventId, open])

    useEffect(() => {
        let f = [...hazards.filter(x => !hazardsDeleted.includes(x.hazardEventId) && happenedOnDay(x, hazardsDay) && isOnStage(x, selectedStage) && wasInLastTwoHours(x, lastTwoHours))]
        setFilteredHazards(f)
    }, [hazards, hazardsDeleted, hazardsDay, selectedStage, lastTwoHours])

    useEffect(() => {
        if (hazardsUnread > 0) {
            getHazards()
        }
    }, [hazardsUnread])

    useEffect(() => {
        if (!isMapPage && (openHistoryBoxes.length > 0 || Boolean(open))) {
            setOpenHistoryBoxes([])
            setOpen(null)
        }
    }, [location])



    const getHazards = () => {
        setLoading(true)
        requestHazardEvents(eventId)
            .then(result => {
                setLastLoaded(DateTime.local())
                let h: HazardEvent[] = []
                result.forEach(x => {
                    let dates = getDates(x, timeZone)
                    dates.sort((a, b) => a.toMillis() > b.toMillis() ? 1 : -1)
                    if (dates[0]) {
                        x.earliestTime = dates[0]
                    }
                    h.push(x)
                })

                h.sort((a, b) => {
                    if (!a.earliestTime || !b.earliestTime) {
                        return 0
                    } else {
                        return a.earliestTime.toMillis() < b.earliestTime.toMillis() ? 1 : -1
                    }
                })
                setHazards(h)
            })
            .catch((err) => {
                console.log(err)
                dispatch(identityActionCreators.getIdentity())
            })
            .finally(() => setLoading(false))
    }

    const onWindowClose = () => {
        setOpen(null)
        onMarkHazardsRead()
    }

    const onHistoryClose = (id: number) => {
        setOpenHistoryBoxes([...openHistoryBoxes.filter(x => x != id)])
    }

    const onHeightChanged = (h) => {
        setHeight(h)
    }

    const onDelete = (h: number) => {
        let deleted = [...hazardsDeleted]
        if (!deleted.includes(h)) {
            deleted.push(h)
            setHazardsDeleted(deleted)
        }
    }

    const onDayChange = (d: DateTime | null) => {
        setHazardsDay(d ? d.toISO() : null)
    }

    const onStageChange = (stage: number | null) => {
        setSelectedStage(stage)
    }

    const onRestore = () => {
        setHazardsDeleted([])
    }

    const onChangeLastTwoHours = (x: boolean) => {
        setLastTwoHours(x)
    }

    const onChangeRelativeTime = (x: boolean) => {
        setRelativeTime(x)
    }

    const onHistoryToggle = (id: number, x: number, y: number) => {
        //	setInitialHistoryBoxPosition([x, y])
        setOpenHistoryBoxes([...openHistoryBoxes.filter(x => x != id), id])
    }

    const onHazardsWindowPositionChange = (position: number[]) => {
        setInitialHistoryBoxPosition([position[0] - 200, position[1] + 50])
    }

    const row = memo((rowProps: ListChildComponentProps) => {
        const { index, style } = rowProps
        let hazard = filteredHazards[index]
        //	let entry = hazard ? entries.find(x => x.entryId == hazard.entryId) : undefined
        let stage = stages.find(x => x.locationGroupId == hazard?.locationGroupId)

        if (hazard && stage) {
            return <HazardsRow
                hazard={hazard}
                stageNumber={stage.number ?? 0}
                style={style}
                timeZoneOffset={timeZoneOffset}
                onDelete={onDelete}
                relativeTime={relativeTime}
                onHistoryToggle={onHistoryToggle}
            />
        } else {
            return null
        }
    }, areEqual)

    const historyBoxes = filteredHazards.filter(x => openHistoryBoxes.some(y => y == x.hazardEventId))
        .map(hazard => {
            //	let entry = hazard ? entries.find(x => x.entryId == hazard.entryId) : undefined
            let stage = stages.find(x => x.locationGroupId == hazard?.locationGroupId)
            if (stage) {
                return <HazardHistory
                    key={hazard.hazardEventId}
                    hazardEvent={hazard}
                    //	entry={entry}
                    stage={stage}
                    onWindowClose={onHistoryClose}
                    initialHistoryBoxPosition={[initialHistoryBoxPosition[0], random(80, 150)]}
                />
            }
        })

    const hazardsWindow = open ?
        <Window
            title={'Hazards'}
            uniqueId={'hazards'}
            loading={loading}
            minWidth={645}
            onClose={() => onWindowClose()}
            height={height}
            heightChanged={(h) => onHeightChanged(h)}
            onPositionChange={onHazardsWindowPositionChange}
            headerContent={<Box sx={{ ml: 1 }}>
                <BooleanEditor
                    value={alarmSound}
                    label='Play Alert Sounds'
                    onChange={(x) => setAlarmSound(x ?? false)}
                />
            </Box>}
        >
            <Stack
                direction='row'
                justifyContent='space-between'
                alignItems='center'
                px={1}
                py={1.5}
            >
                <Stack
                    direction='row'
                    alignItems="center"
                    spacing={3}
                    justifyContent='flex-start'
                >
                    <StageSelect
                        stages={stages}
                        initialValue={selectedStage}
                        onChange={onStageChange}
                        allowBlank
                        allowAll
                    />
                    <DaySelect
                        hazardEvents={hazards}
                        value={hazardsDay ? DateTime.fromISO(hazardsDay) : null}
                        onChange={onDayChange}
                    />
                </Stack>
                <Stack
                    direction='row'
                    spacing={1}
                    justifyContent='flex-end'
                >
                    {hazardsDeleted.length > 0 &&
                        <Tooltip placement='top' title={'Restore all hazards'}>
                            <IconButton
                                onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                    onRestore()
                                }}
                                size='medium'
                            >
                                <RestoreIcon />
                            </IconButton>
                        </Tooltip>}
                    <HazardsSettings
                        lastTwoHours={lastTwoHours}
                        onChangeLastTwoHours={onChangeLastTwoHours}
                        relativeTime={relativeTime}
                        onChangeRelativeTime={onChangeRelativeTime}
                    />
                </Stack>
            </Stack>
            <Stack
                direction='row'
                pr={'21px'}
            >
                <Typography sx={{ fontSize: 13, textAlign: 'center', width: 52 }}>

                </Typography>
                <Typography sx={{ minWidth: 13, alignSelf: 'center', fontSize: 14, flex: 1, paddingLeft: 1 }}>
                    Vehicle
                </Typography>
                <Typography sx={{ fontSize: 13, width: 56, alignSelf: 'center' }} />
                <Typography sx={{ fontSize: 13, textAlign: 'center', width: 136 }}>
                    Stage / Distance Current Status
                </Typography>
                <Typography sx={{ fontSize: 13, width: 78, textAlign: 'center', alignSelf: 'center', px: 1 }}>
                    Hazard Time
                </Typography>
                <Typography sx={{ fontSize: 13, width: 78, textAlign: 'center', alignSelf: 'center', px: 1 }}>
                    SOS Time
                </Typography>
                <Typography sx={{ fontSize: 13, width: 78, textAlign: 'center', alignSelf: 'center', px: 1 }}>
                    OK Time
                </Typography>
                <Typography sx={{ fontSize: 11, width: 80, textAlign: 'right', alignSelf: 'flex-end' }}>
                </Typography>
            </Stack>
            {filteredHazards.length > 0
                && <FixedSizeList
                    height={height}
                    itemCount={filteredHazards.length}
                    itemSize={56}
                    width={'100%'}
                    style={{ right: '-3px' }}
                >
                    {row}
                </FixedSizeList>}
        </Window> : null

    return (
        <div>
            {historyBoxes}
            {(hazardClass == 'sos-flash') && <IconButton
                onClick={() => {
                    dispatch(eventActionCreators.setHazardClass(''))
                }}
            >
                <VolumeOffIcon />
            </IconButton>}
            <IconButton
                onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                    if (!isMapPage) {
                        navigate(`/events/${eventId}/map`)
                    }
                    setHazards([])
                    getHazards()
                    onMarkHazardsRead()
                    setOpen(event.currentTarget)
                    dispatch(eventActionCreators.setHazardClass(''))
                    dispatch(eventActionCreators.setHazardsUnread(0))
                }}
                size={screenMedium ? 'medium' : 'large'}
            >
                <Badge badgeContent={hazardsUnread} color="error">
                    <HazardIcon className={hazardClass} background={'#fff'} style={{ width: 26, height: 26 }} />
                </Badge>
            </IconButton>
            {windowSize.width && windowSize.width <= 551 && open
                ? <Popper
                    open={Boolean(open)}
                    anchorEl={open}
                    placement="bottom-end"
                    disablePortal
                >
                    {({ TransitionProps }) => (
                        <ClickAwayListener
                            onClickAway={() => {
                                setOpen(null)
                            }}
                        >
                            <Grow in={Boolean(open)} {...TransitionProps}>
                                <Paper style={{
                                    transformOrigin: 'top right',
                                    width: screenExtraSmall && windowSize.width ? windowSize.width : 500
                                }} elevation={8}>
                                    <div style={{ padding: '12px 14px 28px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                        <Typography style={{ float: 'left', fontSize: 24, marginTop: -2 }} variant={'h5'}>Hazards</Typography>
                                        {loading && <CircularProgress size={22} />}
                                        <IconButton
                                            onClick={() => onWindowClose()}
                                            style={{ width: 32, height: 32, margin: '2px 8px 2px 2px' }}
                                            size="large">
                                            <CloseIcon style={{ color: '#bdbdbd' }} />
                                        </IconButton>
                                    </div>
                                    {hazards.length > 0
                                        ? <FixedSizeList
                                            height={400}
                                            width={screenExtraSmall ? '100%' : 560}
                                            itemSize={52}
                                            itemCount={hazards.length}
                                            style={{
                                                //	width: theme.spacing(40),
                                                overflow: 'auto',
                                            }}>
                                            {row}
                                        </FixedSizeList>
                                        : <Container>
                                            {!loading && <Typography>No Hazards</Typography>}
                                        </Container>
                                    }
                                </Paper>
                            </Grow>
                        </ClickAwayListener>
                    )}
                </Popper>
                : hazardsWindow}
        </div >
    )
}

export default memo(HazardsButton)