import { DateTime } from "luxon"
import { memo, useState } from "react"
import { useSelector } from 'react-redux'
import { ApplicationState } from '../store'
import { TimingPrecision, getCurrentRealTime, getStringFromDuration } from './timeHelper'
import { useInterval } from "./useInterval"

type Props = {
    time: DateTime
    colorize?: boolean
    refreshInterval?: number
    exact?: boolean
}

const RelativeTime: React.FunctionComponent<Props> = ({ time, colorize, refreshInterval, exact }) => {
    const deltaMs = useSelector((state: ApplicationState) => state.global.deltaMs)

    const [second, setSecond] = useState<number>(0)

    const interval = 10000//Math.floor(Math.random() * (6000 - 3000 + 1)) + 3000
    let ago = getCurrentRealTime(deltaMs).diff(time, ['seconds'])

    useInterval(() => {
        let now = DateTime.now().second
        if (now != second) {
            setSecond(DateTime.now().second)
        }
    }, refreshInterval ?? interval)

    const getColor = () => {
        let agoValue = rangePercentage(ago.seconds, 0, 3600) - 100
        let colorValue = (Math.abs(agoValue) / 100) * 120

        return `hsl(${colorValue}, 100%, 57%)`
    }

    let text = exact ? '00:00.0' : 'Never'
    let color = colorize ? '#999' : 'inherit'

    if (time && time > DateTime.local().minus({ years: 20 })) {
        text = time.toRelative({ style: 'short', base: getCurrentRealTime(deltaMs) }) ?? ''
        if (exact && ago.seconds < 3600) text = getStringFromDuration(ago, TimingPrecision.Tenth, true)
        if (exact && ago.seconds > 3600 && ago.seconds < 86400) text = getStringFromDuration(ago, TimingPrecision.Tenth, true)
        if (exact && ago.seconds > 86400) text = getStringFromDuration(ago, TimingPrecision.Tenth, true, true)
        if (colorize) color = getColor()
    }

    return <span style={{ color: color }}>{text}</span>
}

export const rangePercentage = (input: number, range_min: number, range_max: number, range_2ndMax?: number) => {

    var percentage = ((input - range_min) * 100) / (range_max - range_min)

    if (percentage > 100) {

        if (typeof range_2ndMax !== 'undefined') {
            percentage = ((range_2ndMax - input) * 100) / (range_2ndMax - range_max)
            if (percentage < 0) {
                percentage = 0
            }
        } else {
            percentage = 100
        }

    } else if (percentage < 0) {
        percentage = 0
    }

    return percentage
}

export default memo(RelativeTime, (p, n) => {
    return p.time.toMillis() == n.time.toMillis()
})