import { stagePointError } from '.'
import { StagePoint, UnitPointType } from '../../../../../store/Itinerary'
import { GroupedStagePointRule, SFStagePoint } from '../../../../../store/StageFile'

export const onlyOneFlyingFinishInStage: GroupedStagePointRule = (stagePoints) => {
    // Must only occur once per stage number – SF
    let flyingFinishes: SFStagePoint[] = []
    stagePoints.forEach(stagePoint => {
        if (stagePoint.type.unitPointType == UnitPointType.FlyingFinish) {
            if (flyingFinishes.push(stagePoint) > 1) {
                return stagePointError(stagePoint, `There must only be one FlyingFinish per stage - Lines: ${flyingFinishes[0].order} and ${flyingFinishes[1].order} are both FlyingFinishes`)
            }
        }
    })
    return undefined
}