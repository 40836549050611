import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { Stack, TextField } from '@mui/material'
import { isEmpty } from 'lodash'
import { useSnackbar } from 'notistack'
import * as React from 'react'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { createEvent } from '../../api/Event'
import { ApplicationState } from '../../store'
import { BlankEventBasic, EventBasic } from '../../store/Event'
import { BooleanEditor } from '../Inputs/Editors/BooleanEditor'
import { DecimalEditor } from '../Inputs/Editors/DecimalEditor'
import { CountrySelect } from '../Inputs/Selectors/CountrySelect'
import { EditDialog } from '../Inputs/Dialogs/EditDialog'
import { DomainSelect } from '../Inputs/Selectors/DomainSelect'
import { getLuxonTimeFromString } from '../../helper/timeHelper'

interface Props {
    open: boolean
    onClose: (eventId?: number) => void
}

export const EventCreateDialog: React.FunctionComponent<Props> = ({ open, onClose }) => {
    const roles = useSelector((state: ApplicationState) => state.identity.roles)

    const [eventModel, setEventModel] = useState<EventBasic>(BlankEventBasic)

    const { enqueueSnackbar, closeSnackbar } = useSnackbar()

    const onEventSave = () => {
        createEvent(eventModel)
            .then(r => {
                enqueueSnackbar('Event created successfully', { variant: 'success' })
                r.json()
                    .then((eventId: number) => {
                        onClose(eventId)
                    })
            }).catch((error: Error) => {
                enqueueSnackbar(error.message, { variant: 'error', })
                onClose()
            })
    }

    const isValid = () => {
        return !isEmpty(eventModel.name)
            && !isEmpty(eventModel.countryCode)
            && !isEmpty(eventModel.location)
    }

    return (

        <EditDialog
            model={eventModel}
            modelName={'Event'}
            onSave={onEventSave}
            open={open}
            create
            onClose={onClose}
            saveDisabled={!isValid()}
        >
            <Stack flexWrap='wrap' flexDirection='row' justifyContent='space-evenly' width='100%'>
                <Stack minWidth={300} spacing={3} m={1}>
                    <TextField
                        label='Event Name'
                        size='small'
                        value={eventModel.name}
                        onChange={(x) => setEventModel({
                            ...eventModel,
                            name: x.target.value
                        })}
                    />
                    <CountrySelect
                        value={eventModel.countryCode}
                        onChange={(x) => setEventModel({
                            ...eventModel,
                            countryCode: x
                        })}
                    />
                    <TextField
                        label='Location (city/town)'
                        size='small'
                        value={eventModel.location ?? ''}
                        onChange={(x) => setEventModel({
                            ...eventModel,
                            location: x.target.value
                        })}
                    />
                </Stack>
                <LocalizationProvider dateAdapter={AdapterLuxon}>
                    <Stack minWidth={300} spacing={3} m={1}>
                        <DecimalEditor
                            label='Time Zone Offset'
                            required
                            initialValue={eventModel.timeZone}
                            onChange={(x) => {
                                setEventModel({
                                    ...eventModel,
                                    timeZone: x ?? 0
                                })
                            }}
                        />
                        <DateTimePicker
                            ampm={false}
                            label="Start"
                            value={eventModel.start ? getLuxonTimeFromString(eventModel.start) : undefined}
                            onChange={(e) => {
                                setEventModel({
                                    ...eventModel,
                                    start: e.toISO({ includeOffset: false })
                                })
                            }}
                            onError={console.log}
                            format='yyyy/MM/dd HH:mm:ss'
                            slotProps={{ textField: { size: 'small' } }}
                        />
                        <DateTimePicker
                            ampm={false}
                            label="Finish"
                            value={eventModel.finish ? getLuxonTimeFromString(eventModel.finish) : undefined}
                            onChange={(e) => {
                                setEventModel({
                                    ...eventModel,
                                    finish: e.toISO({ includeOffset: false })
                                })
                            }}
                            onError={console.log}
                            format='yyyy/MM/dd HH:mm:ss'
                            slotProps={{ textField: { size: 'small' } }}
                        />
                    </Stack>
                </LocalizationProvider>
            </Stack>
            <Stack direction='row' flexWrap='wrap' justifyContent='space-evenly' width='100%' mt={3}>
                <BooleanEditor
                    value={eventModel.isTest}
                    label='Test'
                    onChange={(x) => setEventModel({
                        ...eventModel,
                        isTest: x ?? false
                    })}
                />
                <BooleanEditor
                    value={eventModel.isWrc}
                    label='WRC'
                    onChange={(x) => setEventModel({
                        ...eventModel,
                        isWrc: x ?? false
                    })}
                />
            </Stack>
            <Stack direction='row' justifyContent='space-evenly'>
                <DomainSelect
                    value={eventModel.domain}
                    onChange={(e) => {
                        setEventModel({
                            ...eventModel,
                            domain: e
                        })
                    }}
                />
            </Stack>
        </EditDialog>

    )
}