import * as Core from '.'

export interface IdentityModel {
    username: string
    roles: string[]
    claims: string[]
}

export interface UserDetail {
    accountId: string
    userName: string
    email: string
}

export interface UpdateUserDetail extends UserDetail {
    currentPassword: string
    newPassword: string
    repeatPassword: string
}

interface LoginModel {
    username: string
    password: string
}

export interface RoleBasic {
    roleId: string
    name: string
}

export interface TempUserDetail {
    accountId: string
    userName: string
    password: string
    roles: RoleBasic[]
}

export function login(username: string, password: string): Promise<Response> {
    return Core.post<LoginModel>('/identity/login', { username, password })
}

export function get(): Promise<IdentityModel> {
    return Core.get<IdentityModel>('/identity')
}

export function logout(): Promise<{}> {
    return Core.postEmpty('/identity/logout')
}

export function forgotPassword(email: string) {
    return Core.post(`/identity/forgotpassword`, { Email: email })
}

export function resetPassword(userId: string, token: string, password: string) {
    return Core.post(`/identity/resetPassword`, { UserId: userId, Token: token, Password: password })
}

export function getUserDetail(): Promise<UserDetail> {
    return Core.get<UserDetail>('/identity/user-detail')
}

export function updateUserDetail(model: UpdateUserDetail): Promise<UpdateUserDetail> {
    return Core.post<UpdateUserDetail>('/identity/update-user-detail', model)
}
