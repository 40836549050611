import NightlightIcon from '@mui/icons-material/Nightlight'
import { Box, FormControl, InputLabel, Link, MenuItem, Select, Stack, Typography, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import Tooltip from '@mui/material/Tooltip'
import { isUndefined } from 'lodash'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { eventsFromOtherYears } from '../../api/Event'
import { ApplicationState } from '../../store'
import { Domain, EventYear } from '../../store/Event'
import { PhaseText } from '../Events/PhaseText'
import { Memory } from './Memory'
import { CompanyEditor, hasAccess } from '../../store/Account'

interface Props {
    pathName: string
}

const style: React.CSSProperties = {
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
    lineHeight: 1.1,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    fontFamily: 'Roboto Condensed, sans-serif',
    fontWeight: 'bold',
    //  color: 'rgba(255, 255, 255, 0.9)'
    color: 'hsl(0deg 0% 97%)'
}

export const PageTitle: React.FunctionComponent<Props> = (props) => {
    const { pathName } = props
    const selectedEvent = useSelector((state: ApplicationState) => state.event.selectedEvent)
    const selectedEventId = useSelector((state: ApplicationState) => state.event.selectedEventId)
    const showDebug = useSelector((state: ApplicationState) => state.map.showDebug)
    const roles = useSelector((state: ApplicationState) => state.identity.roles)

    const theme = useTheme()
    const screenLarge = useMediaQuery(theme.breakpoints.up('lg'))
    const screenMedium = useMediaQuery(theme.breakpoints.up('md'))
    const screenSmall = useMediaQuery(theme.breakpoints.down('md'))
    const screenExtraSmall = useMediaQuery(theme.breakpoints.down('sm'))
    const navigate = useNavigate()

    const [otherEvents, setOtherEvents] = useState<EventYear[]>([])

    const getNameSize = () => {
        if (screenLarge) return 27
        if (screenMedium) return 20
        if (screenSmall) return 18
    }

    const getSubHeadingSize = () => {
        if (screenLarge) return '16px'
        if (screenMedium) return '14px'
        if (screenSmall) return '12px'
    }

    useEffect(() => {
        if (selectedEventId && hasAccess(CompanyEditor, roles))
            eventsFromOtherYears(selectedEventId)
                .then(e => {
                    setOtherEvents(e)
                })
        else
            setOtherEvents([])
    }, [selectedEventId, roles])

    if (selectedEvent == null)
        return pathName.includes('login')
            ? null
            : <Typography variant="h5" color="inherit" sx={{ ...style, ml: 1 }}>
                Race Control
            </Typography>

    const { phase, name, start, nightMode, autoNightMode, isWrc, isTest, domain, resultsUrl } = selectedEvent

    const currentYear = start ? moment(start).year() : 0

    if (name && !isUndefined(phase)) {
        return <Stack direction='row' alignItems='center' spacing={2} style={{ marginLeft: !screenExtraSmall ? 16 : 0, flexGrow: 1 }}
            sx={{ '& .MuiTypography-root, & #year-select': { fontSize: getSubHeadingSize() } }}
        >
            <Stack spacing={0.2}>
                <Stack direction='row' spacing={1} alignSelf='flex-start'>
                    <Typography style={{ ...style, fontSize: getNameSize(), display: '-webkit-box' }}>
                        {name}
                    </Typography>
                    {(nightMode ?? autoNightMode) && <Tooltip title='Night mode'><NightlightIcon sx={{ color: '#fff176' }} /></Tooltip>}
                </Stack>
                <Stack direction='row' color="inherit" style={{ ...style, fontWeight: 'normal', color: 'rgba(255, 255, 255, 0.7)', fontFamily: 'Roboto', }}>
                    <Stack direction='row' alignItems='center' mb='-4px'>
                        {
                            otherEvents.length <= 1
                                ? <Typography variant='button'>
                                    {moment(start).year()}
                                </Typography>
                                : <FormControl>
                                    <Select
                                        labelId='year-label'
                                        id='year-select'
                                        size='small'
                                        variant='standard'
                                        sx={{
                                            color: 'inherit',
                                            marginBottom: '-3px',
                                            marginRight: '-6px',
                                            '& .MuiSvgIcon-root': {
                                                display: 'none'
                                            },
                                            '& .MuiSelect-standard': {
                                                paddingRight: '8px !important'
                                            }
                                        }}
                                        value={currentYear}
                                        onChange={(v) => {
                                            let newEvent = otherEvents.find(x => x.year == v.target.value)
                                            if (newEvent && newEvent.eventId != selectedEventId)
                                                navigate(`/events/${newEvent.eventId}/details#overview`)
                                        }}
                                    >
                                        {otherEvents.map(x => <MenuItem key={x.eventId} value={x.year}>{x.year}</MenuItem>)}
                                    </Select>
                                </FormControl>
                        }
                        <Box mx={1}> · </Box>
                        <PhaseText phase={phase} />
                        {resultsUrl.length > 0 && resultsUrl != 'NotFound' ? <Stack direction='row' alignItems='center'><Box mx={1}> · </Box><Link href={resultsUrl} underline="hover" target='_blank' color='rgb(41, 182, 246)'>
                            {'RESULTS'}
                        </Link></Stack> : null}
                        {selectedEvent.championships.length > 0 && <Stack direction='row' alignItems='center'><Box mx={1}> · </Box><Typography>{selectedEvent.championships.map(x => x.code).join(', ')}</Typography></Stack>}
                        {isTest && <Stack direction='row' alignItems='center'><Box mx={1}> · </Box><Typography>TEST</Typography></Stack>}
                        {(!isUndefined(domain) && !screenSmall && domain == Domain.RaceSafeH2O) && <Stack direction='row' alignItems='center'><Box mx={1}> · </Box><Typography>RaceSafe H2O</Typography></Stack>}
                        {showDebug && <Stack direction='row' alignItems='center'><Box mx={1}> · </Box><Memory /></Stack>}
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
    } else if (pathName.includes('login')) return null
    return <Typography variant="h5" color="inherit" sx={{ ...style, ml: 1 }}>
        Race Control
    </Typography>
}
