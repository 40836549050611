import { stagePointWarning } from '.'
import {  UnitPointType } from '../../../../../store/Itinerary'
import { GroupedStagePointRule, SFStagePoint, StagePointError } from '../../../../../store/StageFile'

export const locationIsUnique: GroupedStagePointRule = (stagePoints) => {
    stagePoints = stagePoints.filter(s => s.type.unitPointType != UnitPointType.TimeControl)
    const stagePointErrors: StagePointError[] = []
    const result: SFStagePoint[] = [];
    const map = new Map();
    for (const stagePoint of stagePoints) {
        let latLng = `${stagePoint.latitude} ${stagePoint.longitude}`
        if (!map.has(latLng)) {
            map.set(latLng, true);    // set any value to Map
            result.push(stagePoint);
        }
    }

    let difference = stagePoints.filter(x => !result.find(y => y == x));
    difference.forEach(stagePoint => {
        stagePointErrors.push(stagePointWarning(stagePoint, `There should not be 2 points in the same stage with the same location`))
    })

    return stagePointErrors.length > 0 ? stagePointErrors : undefined
}