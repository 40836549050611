import { camelCase } from "lodash"

export function keysToCamelCase(obj) {
    if (obj == null)
        return null
    if (obj instanceof Array) {
        for (var i in obj) {
            obj[i] = keysToCamelCase(obj[i])
        }
    }
    if (!(typeof (obj) === "object") || typeof (obj) === "string" || typeof (obj) === "number" || typeof (obj) === "boolean") {
        return obj
    }
    var keys = Object.keys(obj)
    var n = keys.length
    var lowKey
    while (n--) {
        var key = keys[n]

        if (key === (lowKey = camelCase(key)))
            continue

        obj[lowKey] = keysToCamelCase(obj[key])
        delete obj[key]
    }
    return (obj)
}
