import { FunctionComponent, LazyExoticComponent, Suspense } from 'react'
import { RouteProps } from 'react-router'
import { Loader } from './components/@Global/Loader'

type LazyRouteProps = RouteProps & {
    component: LazyExoticComponent<FunctionComponent<{}>>
}

const LazyRoute: React.FC<LazyRouteProps> = ({ component: Component }) => {

    return <Suspense fallback={
        // Full screen loader
        <Loader fullSize={true} loading={true} size={32} />
    }>
        <Component />
    </Suspense>
}

export default LazyRoute
