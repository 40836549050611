import { stagePointError } from '.'
import { UnitPointType } from '../../../../../store/Itinerary'
import { GroupedStagePointRule, SFStagePoint } from '../../../../../store/StageFile'

export const onlyOneTransitInStage: GroupedStagePointRule = (stagePoints) => {
    // Must only occur once per stage number – TP/TS
    let transits: SFStagePoint[] = []
    stagePoints.forEach(stagePoint => {
        if (stagePoint.type.unitPointType == UnitPointType.TransitPoint || stagePoint.type.unitPointType == UnitPointType.TransitStart) {
            if (transits.push(stagePoint) > 1) {
                return stagePointError(stagePoint, `There must only be one TransitPoint/TransitStart per stage - Lines: ${transits[0].order} and ${transits[1].order} are both Transits`)
            }
        }
    })
    return undefined
}