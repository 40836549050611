import { useRef, useEffect } from "react"

export function useInterval(callback, delay, runInstantly?) {
  const savedCallback = useRef<any>(null)

  useEffect(() => {
    savedCallback.current = callback
  })

  useEffect(() => {
    if (runInstantly)
      savedCallback.current()
  }, [])

  useEffect(() => {
    function tick() {
      savedCallback.current()
    }

    let id = setInterval(tick, delay)
    return () => clearInterval(id)
  }, [delay])
}