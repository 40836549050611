import { AppThunkAction } from "."
import moment from 'moment'
import * as GlobalApi from '../api/Global'
import { getInitialNumberValue, getInitialValue } from "../helper/StorageHelper"

export interface GlobalState {
    readonly pageTitle: string
    drawerOpen: boolean
    timeRequestStarted: number
    timeResponse: string
    deltaMs: number
    timeZoneName: string
    timeZoneOffset: number
    notificationsUnread: number
    hideFrame?: boolean
}

const initialState: GlobalState = {
    pageTitle: '',
    drawerOpen: false,
    timeRequestStarted: 0,
    timeResponse: '',
    deltaMs: 0,
    timeZoneName: 'local',
    timeZoneOffset: 0,
    notificationsUnread: getInitialNumberValue('notificationsUnread') ?? 0
}

interface SetPageTitle { type: 'SET_PAGE_TITLE', pageTitle: string }
interface SetDrawerOpen { type: 'SET_DRAWER_OPEN', drawerOpen: boolean }
interface RequestTimeAction { type: 'REQUEST_TIME' }
interface ReceiveTimeAction { type: 'RECEIVE_TIME', time: string }
interface SetTimeZoneOffset { type: 'SET_TIMEZONE_OFFSET', offset: number }
interface SetNotificationsUnread { type: 'SET_NOTIFICATIONS_UNREAD', count: number, eventId: number, increaseBy?: number }
interface SetHideFrame { type: 'SET_HIDE_FRAME', hideFrame: boolean }

export type KnownAction = SetHideFrame | SetPageTitle | SetDrawerOpen | RequestTimeAction | ReceiveTimeAction | SetTimeZoneOffset | SetNotificationsUnread

export const globalActionCreators = {
    setPageTitle: (pageTitle: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'SET_PAGE_TITLE', pageTitle: pageTitle })
    },
    setDrawerOpen: (drawerOpen: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'SET_DRAWER_OPEN', drawerOpen: drawerOpen })
    },
    requestTime: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'REQUEST_TIME' })
        GlobalApi.requestTime()
            .then(result => dispatch({
                type: 'RECEIVE_TIME',
                time: result
            }))
    },
    setTimeZoneOffset: (offset: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'SET_TIMEZONE_OFFSET', offset })
    },
    setNotificationsUnread: (count: number, eventId: number, increaseBy?: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'SET_NOTIFICATIONS_UNREAD', count, eventId, increaseBy })
    },
    setHideFrame: (hideFrame: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'SET_HIDE_FRAME', hideFrame })
    }
}

export function reducer(
    state = initialState,
    action: KnownAction
): GlobalState {
    switch (action.type) {
        case 'SET_PAGE_TITLE':
            return {
                ...state,
                pageTitle: action.pageTitle
            }
        case 'SET_DRAWER_OPEN':
            return {
                ...state,
                drawerOpen: action.drawerOpen
            }
        case 'REQUEST_TIME':
            return {
                ...state,
                timeRequestStarted: moment.utc().valueOf()
            }
        case 'RECEIVE_TIME':
            let networkLatency = (moment.utc().valueOf() - state.timeRequestStarted) / 2
            let realTime = moment.utc(action.time).add(networkLatency, 'milliseconds')
            let delta = realTime.valueOf() - moment.utc().valueOf()
            return {
                ...state,
                timeResponse: action.time,
                deltaMs: delta,
                timeRequestStarted: 0
            }
        case 'SET_TIMEZONE_OFFSET':
            var zoneName = `UTC${action.offset > -0.5 ? '+' : ''}`
            zoneName += Math.floor(action.offset)

            if (action.offset % 1 == 0.5) {
                zoneName += ':30'
            } else if (action.offset % 1 == 0.75) {
                zoneName += ':45'
            }
            return {
                ...state,
                timeZoneOffset: action.offset,
                timeZoneName: zoneName
            }
        case 'SET_NOTIFICATIONS_UNREAD':
            let count = action.increaseBy != undefined ? state.notificationsUnread + action.increaseBy : action.count
            window.localStorage.setItem(`notificationsUnread-${action.eventId}`, count.toString())
            return {
                ...state,
                notificationsUnread: count
            }
        case 'SET_HIDE_FRAME':
            return {
                ...state,
                hideFrame: action.hideFrame
            }
        default:
            return state
    }
}
