import AddIcon from '@mui/icons-material/Add'
import { Autocomplete, Box, Chip, Fab, FormControl, InputLabel, MenuItem, Select, Stack, TextField } from '@mui/material'
import { DateTime } from 'luxon'
import * as React from 'react'
import { ReactElement, useEffect, useState } from 'react'
import { requestEventsList } from '../../../api/Event'
import '../../../helper/stringExtensions'
import { ChampionshipEvent } from '../../../store/Championship'
import { EventBasic } from '../../../store/Event'

type Props = {
    selectedEvents: EventBasic[] | ChampionshipEvent[]
    onAddEvent: (event: EventBasic | ChampionshipEvent) => void
    onDeleteEvent: (event: EventBasic | ChampionshipEvent) => void
    showCount?: boolean
    label?: string
}

const currentYear = DateTime.now().year

export const EventAssignMultiSelect: React.FunctionComponent<Props> = ({ label, selectedEvents, showCount, onAddEvent, onDeleteEvent }) => {

    const [selectedEvent, setSelectedEvent] = useState<EventBasic | null>(null)
    const [events, setEvents] = useState<EventBasic[]>([])
    const [selectedYear, setSelectedYear] = useState<number>(currentYear)

    const downloadData = async () => {
        requestEventsList(selectedYear)
            .then(result => {
                result.sort((a, b) => {
                    return DateTime.fromISO(a.start).toMillis() - DateTime.fromISO(b.start).toMillis()
                })
                setEvents(result)
            })
    }

    useEffect(() => {
        downloadData()
    }, [])

    useEffect(() => {
        setEvents([])
        downloadData()
    }, [selectedYear])

    const getYears = () => {
        let years: ReactElement[] = []
        for (let i = 2015; i <= currentYear + 1; i++) {
            years.push(<MenuItem key={i} value={i}>{i}</MenuItem>)
        }
        years.reverse()
        return years
    }

    return (
        <Box>
            <InputLabel sx={{ mb: 2 }}>{label ?? 'Assign Events'}</InputLabel>
            {(selectedEvents.length == 0 || selectedEvents.length != events.length) &&
                <Stack direction='row' spacing={2} alignItems='center'>
                    <FormControl>
                        <InputLabel>Year</InputLabel>
                        <Select
                            label='Year'
                            value={selectedYear}
                            size='small'
                            sx={{ width: '100px' }}
                            onChange={(e) => {
                                setSelectedYear(e.target.value as number)
                            }}
                        >
                            {getYears()}
                        </Select>
                    </FormControl>
                    <Autocomplete
                        sx={{ width: '350px' }}
                        options={events}
                        autoHighlight
                        size={'small'}
                        value={selectedEvent}
                        groupBy={(option) => DateTime.fromISO(option.start).toFormat('LLLL')}
                        onChange={(e, v) => { setSelectedEvent(v ?? null) }}
                        getOptionLabel={(x) => x ? `${x.name}` : ''}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label='Event'
                                inputProps={{
                                    ...params.inputProps,
                                }}
                            />
                        )}
                    />
                    <Fab
                        color='primary'
                        onClick={() => {
                            if (selectedEvent) {
                                onAddEvent(selectedEvent)
                                setSelectedEvent(null)
                            }
                        }}
                        size={'small'}
                    >
                        <AddIcon fontSize={'small'} />
                    </Fab>
                </Stack>
            }
            <ul style={{
                display: 'flex',
                justifyContent: 'left',
                flexWrap: 'wrap',
                listStyle: 'none',
                padding: 8,
                margin: 0
            }}>
                {selectedEvents.map((event, i) => {
                    return (
                        <li key={event.eventId}>
                            <Chip
                                label={`${showCount ? (i + 1) + '. ' : ''}${event.name} - ${DateTime.fromISO(event.start).toFormat("MMM y")}`}
                                onDelete={(x) => onDeleteEvent(event)}
                                style={{ margin: 4, background: '#3f3f3f' }}
                            />
                        </li>
                    )
                })}
            </ul>
        </Box>
    )
}