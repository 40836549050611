import { stagePointError } from '.'
import { UnitPointType } from '../../../../../store/Itinerary'
import { AdjacentStagePointRule } from '../../../../../store/StageFile'

export const pointFollowingFlyingFinishIsValid: AdjacentStagePointRule = (currentPoint, nextPoint, currentStage, stages) => {
    // SF – can be followed by BS, WP, SS, TS (A-B timing), TP (A-A timing), TC, QS, BP, L1
    if (currentPoint.type.unitPointType == UnitPointType.FlyingFinish) {
        switch (nextPoint.type.unitPointType) {
            //       case UnitPointType.BoatStart:
            case UnitPointType.Waypoint:
            case UnitPointType.StageStart:
            case UnitPointType.TransitStart:
            case UnitPointType.TransitPoint:
            case UnitPointType.TimeControl:
            case UnitPointType.QuietZoneStart:
            case UnitPointType.BuoyPoint:
                //       case UnitPointType.StartLinePoint1:
                break
            default:
                // Can be followed by Other point if last stage
                if (!(currentStage?.order == stages?.sort((a, b) => b.order - a.order)[0].order && nextPoint.type.unitPointType == UnitPointType.Other)) {
                    return stagePointError(nextPoint, `SF must be followed by WP/SS/TS/TP/TC/QS - ${nextPoint.type.code} is invalid`)
                }
        }
    }
}