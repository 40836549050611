import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import AccountTreeIcon from '@mui/icons-material/AccountTree'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import LoginIcon from '@mui/icons-material/Login'
import LogoutIcon from '@mui/icons-material/Logout'
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts'
import PersonIcon from '@mui/icons-material/Person'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Paper, PaperProps, Stack, useMediaQuery, useTheme } from '@mui/material'
import * as React from 'react'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Dispatch } from 'redux'
import { logout } from '../../api/Identity'
import { ApplicationState } from '../../store'
import { eventActionCreators } from '../../store/Event'
import { identityActionCreators } from '../../store/Identity'
import AutoSizer from 'react-virtualized-auto-sizer'
import { Document, Page, pdfjs } from 'react-pdf'
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

export const UserButton: React.FunctionComponent = () => {
    const username = useSelector((state: ApplicationState) => state.identity.username)

    const theme = useTheme()
    const screenSmall = useMediaQuery(theme.breakpoints.down('md'))

    const [openDownloadMenu, setOpenDownloadMenu] = useState<null | HTMLElement>(null)
    const [dialogOpen, setDialogOpen] = useState<boolean>(false)
    const [siteMapData, setSiteMapData] = useState<string>()
    const [siteMapActive, setSiteMapActive] = useState<boolean>(false)
    const [numPages, setNumPages] = useState<number | null>(null)

    const navigate = useNavigate()


    const dispatch: Dispatch<any> = useDispatch()
    const isPublic = username == 'PublicUser'

    const onLogout = async (goToLogin?: boolean) => {
        dispatch(eventActionCreators.clearSelectedEvent())
        window.localStorage.setItem('phaseRange', '')
        window.localStorage.setItem(`recentlySelectedEvents${username}`, '')
        window.localStorage.setItem('eventStatusToggles', '')
        window.localStorage.setItem('eventSelectedChampionships', '')
        window.localStorage.setItem('eventSelectedCountries', '')
        dispatch(identityActionCreators.logout())
        await logout()
        dispatch(identityActionCreators.getIdentity())
        if (goToLogin)
            navigate('/login')
    }



    const button = !screenSmall
        ? <Button
            variant='outlined'
            color={'secondary'}
            startIcon={<AccountCircleIcon />}
            endIcon={!screenSmall ? <KeyboardArrowDownIcon /> : null}
            style={{
                borderRadius: 24,
                textTransform: 'none'
            }}
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                setOpenDownloadMenu(event.currentTarget)
            }}
        >
            {!screenSmall ? username : ''}
        </Button>
        : <IconButton
            style={{
                borderRadius: 24,
                textTransform: 'none'
            }}
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                setOpenDownloadMenu(event.currentTarget)
            }}
            size="large">
            <PersonIcon style={{ width: 28, height: 28 }} />
        </IconButton>

    return (
        isPublic
            ? <Button
                variant='contained'
                color={'primary'}
                startIcon={<LoginIcon />}
                sx={{
                    borderRadius: 24
                }}
                onClick={() => {
                    onLogout(true)
                }}
            >
                Login
            </Button>
            : <Box>
                {button}
                <Menu
                    anchorEl={openDownloadMenu}
                    keepMounted
                    open={Boolean(openDownloadMenu)}
                    onClose={() => setOpenDownloadMenu(null)}
                    sx={{ px: 1 }}
                >
                    <MenuItem sx={{ minWidth: '148px' }} onClick={() => {
                        setOpenDownloadMenu(null)
                        navigate('/account-settings')
                    }}>
                        <ListItemIcon>
                            <ManageAccountsIcon fontSize='small' />
                        </ListItemIcon>
                        <ListItemText>Account Settings</ListItemText>
                    </MenuItem>
                    <MenuItem onClick={() => {

                        setSiteMapActive(true)
                        fetch(`https://racecontrol.blob.core.windows.net/manuals/site-map.pdf`)
                            .then(x => {
                                if (x.ok) {
                                    setDialogOpen(true)
                                    x.blob()
                                        .then(y => setSiteMapData(window.URL.createObjectURL(y)))
                                }
                                else
                                    setSiteMapData(undefined)
                            })
                    }}>
                        <ListItemIcon>
                            <AccountTreeIcon fontSize='small' />
                        </ListItemIcon>
                        <ListItemText>Site Map</ListItemText>
                    </MenuItem>
                    <Dialog
                        open={dialogOpen}
                        onClose={() => setDialogOpen(false)}
                        fullWidth={true}
                        maxWidth={false}
                        PaperComponent={PaperComponent}
                    >
                        <DialogTitle sx={{ cursor: 'move' }} className="draggable-dialog-title">
                            {`Site Map`}
                        </DialogTitle>
                        <DialogContent style={{ height: '100%' }}>
                            <AutoSizer>
                                {({ height, width }) => (
                                    <Document
                                        file={siteMapData}
                                        onLoadSuccess={({ numPages }) => setNumPages(numPages)}
                                    >
                                        {Array.from(
                                            new Array(numPages),
                                            (el, index) => (
                                                <Page
                                                    width={width}
                                                    height={height}
                                                    key={`page_${index + 1}`}
                                                    pageNumber={index + 1}

                                                />
                                            ),
                                        )}
                                    </Document>
                                )}
                            </AutoSizer>
                        </DialogContent>
                        <DialogActions>
                            <Stack direction='row' spacing={1}>
                                <Button onClick={() => setDialogOpen(false)} color='primary'>
                                    Close
                                </Button>
                            </Stack>
                        </DialogActions>
                    </Dialog>
                    <MenuItem onClick={() => onLogout(true)}>
                        <ListItemIcon>
                            <LogoutIcon fontSize='small' />
                        </ListItemIcon>
                        <ListItemText>Log out</ListItemText>
                    </MenuItem>
                </Menu>
            </Box>
    )
}


function PaperComponent(props: PaperProps) {
    return <Paper {...props} elevation={6} sx={{ height: '100%' }} />
}