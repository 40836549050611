import * as Core from '.'
import { Unit, MoMessage, LiteMessage, UnitProfile, UnitFaultModel, UnitFaultCode, UnitNoteModel, UnitHistoryModel, UnitListModel, MessageType, UnitBasic, UnitVersions, RadioType } from '../store/Unit'
import { DateTime } from 'luxon'
import { toServerFormatString } from '../helper/timeHelper'
import { AssignUnitsModel } from '../components/Events/Entries/AssignUnits/AssignUnitsPage'

export function requestUnits(skip: number, take: number, searchText: string, order: string): Promise<Unit[]> {
    return Core.get<Unit[]>(`/unit/table?skip=${skip}&take=${take}&search=${searchText}&order=${order}`)
}

export function requestUnitList(eventId?: number): Promise<number[]> {
    return Core.get<number[]>(`/unit/list?eventId=${eventId}`)
}

export function requestUnassignedUnits(eventId?: number): Promise<UnitBasic[]> {
    return Core.get<UnitBasic[]>(`/unit/unassigned-list?eventId=${eventId}`)
}

export function requestMessages(skip: number, take: number, orderDesc: boolean, showTracking: boolean, channel: string, eventId?: number, unit?: number | null, messageType?: MessageType): Promise<MoMessage[]> {
    return Core.get<MoMessage[]>(`/unit/messages?skip=${skip}&take=${take}&orderDesc=${orderDesc}&showTracking=${showTracking}&channel=${channel}&eventId=${eventId}&unit=${unit}&messageType=${messageType}`)
}

export function requestMessagesBasic(unit: number, channel: string, start?: DateTime, end?: DateTime): Promise<MoMessage[]> {
    let url = ''
    if (start && end)
        url = `/unit/messages-basic?unit=${unit}&channel=${channel}&start=${toServerFormatString(start)}&end=${toServerFormatString(end)}`
    return Core.get<MoMessage[]>(url)
}

export function requestLiteMessages(skip: number, take: number, orderDesc: boolean, type: number, entryId: number, eventId?: number): Promise<LiteMessage[]> {
    let url = `/unit/litemessages?skip=${skip}&take=${take}&orderDesc=${orderDesc}&type=${type}&entryId=${entryId}&eventId=${eventId}`
    return Core.get(url)
}

export function requestUnitProfiles(): Promise<UnitProfile[]> {
    let url = `/unit/unit-profiles`
    return Core.get(url)
}

export function requestUnitProfile(unitProfileId: number): Promise<UnitProfile> {
    let url = `/unit/unit-profile?unitProfileId=${unitProfileId}`
    return Core.get(url)
}

export function requestUnitFaultTable(skip: number, take: number, orderDesc: boolean, faultCode: number, lastScanLocation: string, unit: number | null): Promise<UnitFaultModel[]> {
    return Core.get<UnitFaultModel[]>(`/unit/unit-faults-table?skip=${skip}&take=${take}&orderDesc=${orderDesc}&faultCode=${faultCode}&lastScanLocation=${lastScanLocation}&unit=${unit}`)
}

export const createFault = (model: UnitFaultModel): Promise<Response> => {
    return Core.post(`/unit/create-fault`, model)
}

export const requestUnitFaultCode = (): Promise<UnitFaultCode[]> => {
    return Core.get<UnitFaultCode[]>(`/unit/unit-fault-types`)
}

export function requestUnitNoteTable(skip: number, take: number, unit: number | null): Promise<UnitNoteModel[]> {
    return Core.get<UnitNoteModel[]>(`/unit/unit-notes-table?skip=${skip}&take=${take}&unit=${unit}`)
}

export const createNote = (model: UnitNoteModel): Promise<Response> => {
    return Core.post(`/unit/create-note`, model)
}

export function requestUnitHistoryTable(skip: number, take: number, unit: number | null): Promise<UnitHistoryModel[]> {
    return Core.get<UnitHistoryModel[]>(`/unit/unit-history-table?skip=${skip}&take=${take}&unit=${unit}`)
}

export function requestUnitProfileTable(skip: number, take: number, search: string): Promise<UnitProfile[]> {
    let url = `/unit/unit-profile-table?skip=${skip}&take=${take}&search=${search}`
    return Core.get<UnitProfile[]>(url)
}

export const createUnitProfile = (unitProfile: UnitProfile): Promise<Response> => {
    return Core.post(`/unit/create-unit-profile`, unitProfile)
}

export const updateUnitProfile = (unitProfile: UnitProfile): Promise<Response> => {
    return Core.post(`/unit/update-unit-profile`, unitProfile)
}

export const deleteUnitProfile = (id: number): Promise<Response> => {
    return Core.del(`/unit/delete-unit-profile`, id)
}

export function requestUnitListTable(skip: number, take: number, unitStatus: number, order: string, search: string, unit: number | null, unitRangeEnd: number | null, radioType: RadioType | number): Promise<UnitListModel[]> {
    let url = `/unit/unit-list-table?skip=${skip}&take=${take}&unitStatus=${unitStatus}&order=${order}&search=${search}&unit=${unit}&unitRangeEnd=${unitRangeEnd}&radioType=${radioType != -1 ? radioType : null}`
    return Core.get<UnitListModel[]>(url)
}

export const updateUnitList = (unit: UnitListModel): Promise<Response> => {
    return Core.post(`/unit/update-unit-list`, unit)
}

export const autoAssignUnits = (model: AssignUnitsModel) => {
    return Core.post(`/unit/auto-assign-units`, model)
}

export function requestUnitVersionsTable(order: string): Promise<UnitVersions[]> {
    let url = `/unit/unit-versions-table?order=${order}`
    return Core.get<UnitVersions[]>(url)
}

export const addUnitVersion = (unitVersion: UnitVersions): Promise<Response> => {
    return Core.post(`/unit/create-unit-version`, unitVersion)
}

export const updateUnitVersion = (unitVersion: UnitVersions): Promise<Response> => {
    return Core.post(`/unit/update-unit-version`, unitVersion)
}

export const deleteUnitVersion = (unitVersionId: number | null, count: number): Promise<Response> => {
    return Core.del(`/unit/delete-unit-version?unitVersionId=${unitVersionId}&count=${count}`)
}

export const countMessages = (date: Date, orderDesc: boolean, channel: string, showTracking: boolean, unit: number, messageType?: MessageType): Promise<number> => {
    return Core.get<number>(`/unit/messages-count?from=${date.toISOString()}&orderDesc=${orderDesc}&showTracking=${showTracking}&channel=${channel}&unit=${unit}&messageType=${messageType}`)
}