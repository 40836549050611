import { Stack, Typography } from '@mui/material'
import { green, amber, red, grey } from '@mui/material/colors'
import { isUndefined } from 'lodash'
import * as React from 'react'
import { useSelector } from 'react-redux'
import { ApplicationState } from '../../store'
import { EventEditor, hasAccess } from '../../store/Account'
import { EventBasic, EventPhase, EventStatus } from '../../store/Event'

interface Props {
    phase: EventPhase
    status?: EventStatus
    isTest?: boolean
}

export const PhaseText: React.FunctionComponent<Props> = ({ phase, status, isTest }) => {
    const roles = useSelector((state: ApplicationState) => state.identity.roles)

    const getPhase = () => {
        let color = 'rgb(117,117,117)'
        switch (phase) {
            case EventPhase.Planning: {
                color = 'rgb(174, 213, 129)'
                break
            }
            case EventPhase.Building: {
                color = 'rgb(129,199,132)'
                break
            }
            case EventPhase.Preparation: {
                color = 'rgb(79,195,247)'
                break
            }
            case EventPhase.Live: {
                color = 'rgb(229,57,53)'
                break
            }
            case EventPhase.Post: {
                color = 'rgb(189,189,189)'
                break
            }
        }
        return <Typography variant='button' sx={{ lineHeight: 1.5 }} fontSize={16} color={color}>{EventPhase[phase]}</Typography>
    }

    const getStatusColor = () => {
        if (!isUndefined(isTest) && isTest) {
            return grey[300]
        }

        switch (status) {
            case EventStatus.Confirmed:
                return green[500]
            case EventStatus.Postponed:
                return amber[500]
            case EventStatus.Cancelled:
                return red[500]
            default:
                return grey[500]
        }
    }

    const statusText = !isUndefined(isTest) && isTest
        ? 'Test'
        : !isUndefined(status)
            ? EventStatus[status]
            : ''

    return isUndefined(status)
        ? getPhase()
        : <Stack>
            {getPhase()}
            {hasAccess(EventEditor, roles) && <Typography variant='button' fontSize={15} sx={{ lineHeight: 1.5 }} color={getStatusColor()}>{statusText}</Typography>}
        </Stack>
}