import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Stack, TextField } from '@mui/material'
import { isEmpty } from 'lodash'
import { useSnackbar } from 'notistack'
import * as React from 'react'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { createEvent } from '../../api/Event'
import { ApplicationState } from '../../store'
import { BlankEventBasic, EventBasic } from '../../store/Event'
import { BooleanEditor } from '../Inputs/Editors/BooleanEditor'
import { DecimalEditor } from '../Inputs/Editors/DecimalEditor'
import { CountrySelect } from '../Inputs/Selectors/CountrySelect'
import { EditDialog } from '../Inputs/Dialogs/EditDialog'
import { DomainSelect } from '../Inputs/Selectors/DomainSelect'
import { getLuxonTimeFromString } from '../../helper/timeHelper'
import { EventAssignMultiSelect } from '../Inputs/Selectors/EventAssignMultiSelect'
import { Loader } from '../@Global/Loader'

interface Props {
    open: boolean
    onClose: () => void
}

export const EventSummariesDialog: React.FunctionComponent<Props> = ({ open, onClose }) => {

    const [selectedEvents, setSelectedEvents] = useState<EventBasic[]>([])
    const [loading, setLoading] = useState(false)

    const onPrint = () => {
        console.log(selectedEvents)

        fetch(`${document.location != null ? document.location.origin : ''}/Report/generate-event-summaries`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ EventIds: selectedEvents.map(x => x.eventId) })
        }).then(response => {
            const contentDisposition = response.headers.get("content-disposition")
            const fileNameMatch = contentDisposition ? /filename="?([^"]*)"?;/g.exec(contentDisposition) : undefined
            const fileName = fileNameMatch && fileNameMatch.length > 1 ? fileNameMatch[1] : undefined
            response.blob()
                .then(blob => {
                    let url = window.URL.createObjectURL(blob)
                    let a = document.createElement('a')
                    a.href = url
                    a.download = fileName ?? ''
                    a.click()
                    setLoading(false)
                    onClose()
                })
        })
    }

    return <Dialog
        open={open}
        onClose={onClose}
        fullWidth={true}
    >
        <DialogTitle id="responsive-dialog-title">{"Event Summaries"}</DialogTitle>
        <DialogContent>
            <Loader loading={loading} />
            <EventAssignMultiSelect
                label='Select Event(s)'
                selectedEvents={selectedEvents}
                onAddEvent={x => setSelectedEvents(y => [...y, x as EventBasic])}
                onDeleteEvent={(x) => setSelectedEvents(y => y.filter(y => y != x))}
            />
        </DialogContent>
        <DialogActions>
            <Button onClick={onClose} color="primary">
                Close
            </Button>
            <Button autoFocus onClick={onPrint} variant='contained' color="primary" disabled={loading || selectedEvents.length == 0}>
                Print
            </Button>
        </DialogActions>
    </Dialog>

}
