import React, { useEffect } from "react"
import { useDispatch, useSelector } from 'react-redux'
import {
    Navigate, useLocation, useParams
} from "react-router-dom"
import { ApplicationState } from "../../store"
import { eventActionCreators } from "../../store/Event"
import { Dispatch } from 'redux'
import { Role, hasAccess } from "../../store/Account"

type Props = {
    children: JSX.Element
    allowPublic?: boolean
    role?: Role
}

export const RequireAuth: React.FunctionComponent<Props> = ({ children, allowPublic, role }) => {
    const roles = useSelector((state: ApplicationState) => state.identity.roles)
    const isAuthenticated = useSelector((state: ApplicationState) => state.identity.isAuthenticated)
    const selectedEventId = useSelector((state: ApplicationState) => state.event.selectedEventId)

    const dispatch: Dispatch<any> = useDispatch()
    const location = useLocation()
    let eventId = parseInt(useParams<{ id: string }>().id ?? '')

    useEffect(() => {
        if (eventId > 0 && eventId != selectedEventId) {
            dispatch(eventActionCreators.setSelectedEventId(eventId))
        }
    }, [eventId])

    if (isAuthenticated == null) return null

    if ((!isAuthenticated && !allowPublic) || (role && !hasAccess(role, roles))) {
        return <Navigate to="/login" state={{ from: `${location.pathname}${location.hash}` }} replace />
    }

    return children
}
