import { Divider, ListItem, Stack, Tooltip, Typography } from "@mui/material"
import { styled } from '@mui/material/styles'
import { blue, orange, red } from "@mui/material/colors"
import InfoIcon from '@mui/icons-material/Info'
import WarningIcon from '@mui/icons-material/Warning'
import { DateTime } from 'luxon'
import { useSelector } from "react-redux"
import RelativeTime from "../../../helper/RelativeTime"
import { getLuxonTimeFromString } from "../../../helper/timeHelper"
import { ApplicationState } from "../../../store"
import { Notification, NotificationType } from '../../../store/Event'
import HybridIcon from '../../@Global/images/hybrid.png'

const PREFIX = 'NotificationRow'

const classes = {
    listItem: `${PREFIX}-listItem`,
    divider: `${PREFIX}-divider`
}

const Root = styled('div')((
    {
        theme
    }
) => ({
    [`& .${classes.listItem}`]: {
        display: 'flex',
        flexDirection: 'column',
        padding: '4px 14px',
        background: 'transparent'
    },

    [`& .${classes.divider}`]: {
        margin: '2px 0',
    }
}))

type Props = {
    notification: Notification
    style: React.CSSProperties
}

const iconStyle = { display: 'inline-block', verticalAlign: 'sub', fontSize: 18 }

export const NotificationRow: React.FunctionComponent<Props> = (props) => {
    const { notification, style } = props

    const timeZone = useSelector((state: ApplicationState) => state.event.selectedEvent?.timeZone)

    return (
        <Root key={notification.notificationId} style={{ ...style }}>
            <ListItem alignItems="flex-start" className={classes.listItem}>
                <Stack direction='row' justifyContent='space-between' width='100%'>
                    <Stack direction='row' spacing={1} alignItems='center'>
                        {notification.type == NotificationType.Error && <WarningIcon htmlColor={red[500]} style={iconStyle} />}
                        {notification.type == NotificationType.Warning && <WarningIcon htmlColor={orange[500]} style={iconStyle} />}
                        {notification.type == NotificationType.Notification && <InfoIcon htmlColor={blue[500]} style={iconStyle} />}
                        {notification.type == NotificationType.Safety && <img src={HybridIcon} style={{ width: 18, height: 18 }} />}
                        <Typography style={{ display: 'inline-block' }} gutterBottom>{notification.source}</Typography>
                    </Stack>
                    {notification.timestamp && (
                        <Tooltip title={getLuxonTimeFromString(notification.timestamp, timeZone).toFormat('HH:mm:ss EEEE, LLL dd, yyyy')}>
                            <Typography variant="caption" color="textSecondary">
                                <RelativeTime time={getLuxonTimeFromString(notification.timestamp, timeZone)} />
                            </Typography>
                        </Tooltip>
                    )}
                </Stack>
                <Typography gutterBottom variant="body2" marginTop='2px'>
                    <span dangerouslySetInnerHTML={{ __html: notification.details }} />
                </Typography>
            </ListItem>
            <Divider className={classes.divider} />
        </Root>
    )
}
