import { isNull, isUndefined } from 'lodash'
import { DateTime } from 'luxon'
import { Action, Reducer } from 'redux'
import { AppThunkAction } from '.'
import * as Api from '../api/Entry'
import { isHighG } from '../components/Events/Hazards/Models'
import { getLuxonTimeFromString } from '../helper/timeHelper'

export enum RacingStatus {
    InTransport = 0,
    Racing = 1
}

export const getRacingStatusText = (r: RacingStatus) => {
    return r == RacingStatus.InTransport ? 'In Transport' : 'Racing'
}

export const getRacingStatusColor = (r: RacingStatus) => {
    return r == RacingStatus.InTransport ? '#bdbdbd' : '#2fda2f'
}

export enum CompetitionStatus {
    Competing = 0,
    Withdrawn
}

export enum ElectricalStatus {
    ChargingEngineOn = 0,
    ChargingEngineOff = 1,
    OnBattery = 2
}

export const getElectricalStatusText = (electricalStatus: ElectricalStatus, type?: VehicleType) => {
    switch (electricalStatus) {
        case ElectricalStatus.ChargingEngineOn:
            return isLite(type) ? 'Charging' : 'Charging Engine On'
        case ElectricalStatus.ChargingEngineOff:
            return isLite(type) ? 'Charging' : 'Charging Engine Off'
        case ElectricalStatus.OnBattery:
            return 'On Battery'
        default:
            'Unknown'
    }
}

export enum GpsStatus {
    NoSignal = 0,
    Signal = 1
}

export const getGpsStatusText = (g: GpsStatus) => {
    return g == GpsStatus.NoSignal ? 'GPS Bad' : 'GPS Good'
}

export enum VehicleType {
    Competition = 0,
    EventVehicle,
    RecoveryVehicle,
    Sweep,
    Zero,
    DoubleZero,
    TripleZero,
    Beam,
    FIV,
    RouteNote,
    Recce,
    Tour,
    Chrono,
    CourseCheck,
    // ↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑
    // Unit modes go above this line, and must be synchronised
    // between Event Manager and unit code.

    // Non-unit modes go below this line.
    // ↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓
    RSLite,
    RecceLite
}

export enum SafetyStatus {
    NoHazard = 0,
    Hazard = 1,
    RolloverHazard = 2,
    Ok = 3,
    RolloverOk = 4,
    AutomaticSos = 5,
    ManualSos = 6,
    RolloverSos = 7,
    ManualSosFire = 8,
    ManualSosMedical = 9,
    Slow = 10,
    TransmitOvertake = 11,
    ReceiveOvertake = 12,
    FivOnCourse = 13,
    RoadBlocked = 14,
    IsolationResistance = 100,
    BatteryTemperature = 101,
    Police = 65280,
    Mechanical = 65281,
    ConfirmSos = 65282
}

export interface HazardHistoryModel {
    hazardId: number
    entryID: number
    eventId: number
    hazardType: SafetyStatus
    lat: number
    long: number
    source: string
    timestamp: string
    distance: number | null
    gforce: number | null
    vehicle: string
    stageNumber: number | null
    stageName: string
    speed: number | null
}

export const getSafetyStatusText = (s: SafetyStatus, gForce?: number | null) => {
    if (!isNull(gForce) && !isUndefined(gForce) && isHighG(gForce) && s == SafetyStatus.Hazard) {
        return 'High-g Hazard'
    }

    switch (s) {
        case SafetyStatus.NoHazard: return 'No Hazard'
        case SafetyStatus.Hazard: return 'Hazard'
        case SafetyStatus.RolloverHazard: return 'Rollover Hazard'
        case SafetyStatus.Ok: return 'OK'
        case SafetyStatus.RolloverOk: return 'Rollover OK'
        case SafetyStatus.AutomaticSos: return 'Auto SOS'
        case SafetyStatus.ManualSos: return 'Manual SOS'
        case SafetyStatus.RolloverSos: return 'Rollover SOS'
        case SafetyStatus.ManualSosFire: return 'SOS Fire'
        case SafetyStatus.ManualSosMedical: return 'SOS Medical'
        case SafetyStatus.Slow: return 'Slow'
        case SafetyStatus.TransmitOvertake: return 'Transmit Overtake'
        case SafetyStatus.ReceiveOvertake: return 'Receive Overtake'
        case SafetyStatus.FivOnCourse: return 'FIV On Course'
        case SafetyStatus.RoadBlocked: return 'OK Road Blocked'
        case SafetyStatus.IsolationResistance: return 'HY Iso Resistance'
        case SafetyStatus.BatteryTemperature: return 'HY Battery Temp'
        case SafetyStatus.Police: return 'Police'
        case SafetyStatus.Mechanical: return 'Mechanical'
        case SafetyStatus.ConfirmSos: return 'Confirm SOS'
        default: return ''
    }
}

export enum EntryFlagStatus {
    NotActive,
    Sent,
    Received,
    Acknowledged
}

export const getEntryFlagStatusText = (e: EntryFlagStatus) => {
    if (e == EntryFlagStatus.NotActive)
        return 'Not Active'
    return EntryFlagStatus[e]
}

export const isHazard = (status: SafetyStatus) => {
    return status == SafetyStatus.Hazard || status == SafetyStatus.RolloverHazard
}

export const isSOS = (status: SafetyStatus) => {
    return status == SafetyStatus.ManualSos || status == SafetyStatus.ManualSosFire || status == SafetyStatus.ManualSosMedical || status == SafetyStatus.ConfirmSos || status == SafetyStatus.RolloverSos || status == SafetyStatus.AutomaticSos
}

export const isOk = (status: SafetyStatus) => {
    return status == SafetyStatus.Ok || status == SafetyStatus.RolloverOk
}

export const isCourseCar = (entry: Entry) => {
    const type = entry.vehicle?.type
    return !isCompetitionVehicle(entry) && type != VehicleType.Beam && type != VehicleType.Tour
}

export const isCompetitionVehicle = (entry: Entry) => {
    const type = entry.vehicle?.type
    return [VehicleType.Competition, VehicleType.Recce].includes(type) || (isLite(type) && entry.isCompetitive)
}

export const isLite = (type?: VehicleType) => {
    if (type == undefined)
        return false
    return [VehicleType.RSLite, VehicleType.RecceLite, VehicleType.CourseCheck].includes(type)
}


export const personExists = (p: Person | null | undefined, needsName: boolean) => {
    if (isNull(p) || isUndefined(p))
        return false
    if (needsName && (isNull(p.firstName) || isUndefined(p.firstName) || p.firstName.isEmpty()))
        return false
    return true
}

export const getPersonFullName = (p: Person | null) => {
    if (!p || !p.firstName) return ''
    if (!p.surname)
        return p.firstName
    return `${p.firstName} ${p.surname}`
}

export const getPersonAbbvName = (p: Person | null) => {
    if (!p || !p.firstName) return ''
    if (!p.surname)
        return p.firstName
    return `${p.firstName.substring(0, 1)}. ${p.surname}`
}

export interface Person {
    personId: number
    countryCode: string
    firstName: string
    surname: string
}

export const blankPerson: Person = {
    personId: -1,
    countryCode: '',
    firstName: '',
    surname: ''
}

export interface Team {
    teamId: number
    name: string
}

export interface Vehicle {
    vehicleId: number
    driver: Person | null
    driverId: number | null
    navigator: Person | null
    navigatorId: number | null
    type: VehicleType
    make: string | null
    weight: number | null
    team: Team | null
}

export interface Entry {
    entryId: number
    vehicleId: number
    eventId: number
    identifier: string
    classId: number | null
    classText: string
    unitSerialNumber: number | null
    code: number | null
    competitionStatus: CompetitionStatus
    distance: number
    vehicle: Vehicle
    safetyStatus: SafetyStatus
    racingStatus: RacingStatus
    isUnitActive: boolean
    lat: number
    lng: number
    bearing: number
    speed: number
    lastUpdated: string
    entryFlagStatus: EntryFlagStatus
    ticks: number
    lastSatMessageTimestamp: string
    lastGsmMessageTimestamp: string
    lastMessageTimestamp: string
    stageNumber: number
    validSetup: boolean
    electricalStatus: ElectricalStatus
    batteryVoltage: number
    gpsStatus: GpsStatus
    platform: string
    fiaGreenLight?: boolean
    tamper: boolean
    isCompetitive: boolean
}

export interface EntrySra {
    entryId: number
    sraId: string
    identifier: string
    unitSerialNumber?: number
}

export interface SetupInfo {
    entryId: number
    unitVersion: string
    fileChecksum: string
    carNumber: number
}

export interface Withdrawal {
    withdrawalId: number
    withdrawalStageNumber: number
    enteredTime: string
    enteredBy: string
    notes: string
    rejoinedTime?: string
    rejoinedStageNumber?: number
    identifier: string
    driverName: string
    driverCountryCode: string
}

export const blankVehicle: Vehicle = {
    vehicleId: -1,
    driver: null,
    driverId: null,
    navigator: null,
    navigatorId: null,
    type: VehicleType.EventVehicle,
    make: '',
    weight: null,
    team: null
}

export const blankEntry: Entry = {
    entryId: -1,
    eventId: -1,
    identifier: '',
    classId: null,
    classText: '',
    unitSerialNumber: null,
    code: null,
    competitionStatus: CompetitionStatus.Competing,
    distance: 0,
    vehicle: blankVehicle,
    vehicleId: -1,
    racingStatus: RacingStatus.InTransport,
    isUnitActive: false,
    lat: -1,
    lng: -1,
    bearing: -1,
    speed: -1,
    safetyStatus: SafetyStatus.NoHazard,
    lastUpdated: '',
    entryFlagStatus: EntryFlagStatus.NotActive,
    ticks: -1,
    lastSatMessageTimestamp: DateTime.local().minus({ years: 25 }).toISO() ?? '',
    lastGsmMessageTimestamp: DateTime.local().minus({ years: 25 }).toISO() ?? '',
    lastMessageTimestamp: DateTime.local().minus({ years: 25 }).toISO() ?? '',
    stageNumber: 0,
    validSetup: false,
    electricalStatus: ElectricalStatus.OnBattery,
    batteryVoltage: 0,
    gpsStatus: GpsStatus.NoSignal,
    platform: '',
    tamper: false,
    isCompetitive: true
}

export interface PreEntryUsers {
    userId: string
    userName: string
}

export const BlankPreEntryUsers: PreEntryUsers = {
    userId: '',
    userName: '',
}

export interface TeamCommsMap {
    userId: string
    entryId: number
    identifier: string
}

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface EntryState {
    entriesPending: boolean
    entries: Entry[]
    entriesLoaded: boolean
    goToEntry?: Entry
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

interface RequestEntries { type: 'REQUEST_ENTRIES' }
interface ReceiveEntries { type: 'RECEIVE_ENTRIES', entries?: Entry[], forceUpdate?: boolean }
interface EntriesLoaded { type: 'ENTRIES_LOADED', entriesLoaded: boolean }
interface UpdateEntry { type: 'UPDATE_ENTRY', entry: Entry }
interface ClearEntries { type: 'CLEAR_ENTRIES' }

interface ReceiveEntriesFailed { type: 'RECEIVE_ENTRIES_FAILED' }
interface GetEntryLocation { type: 'GET_ENTRY_LOCATION', entryId: number }

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = GetEntryLocation | EntriesLoaded | RequestEntries | ReceiveEntries | UpdateEntry | ClearEntries | ReceiveEntriesFailed

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const entryActionCreators = {
    requestEntries: (searchText: string, order: string, eventId: number, classId: number | null, forceUpdate?: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'REQUEST_ENTRIES' })
        return Api.requestEntries(searchText, order, eventId, classId)
            .then(result => {
                dispatch({
                    type: 'RECEIVE_ENTRIES',
                    entries: result,
                    forceUpdate: forceUpdate
                })
                dispatch({
                    type: 'ENTRIES_LOADED',
                    entriesLoaded: true
                })
            })
            .catch((err: number) => {
                dispatch({
                    type: 'RECEIVE_ENTRIES'
                })
                dispatch({
                    type: 'RECEIVE_ENTRIES_FAILED'
                })
            })
    },
    updateEntry: (entry: Entry): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({
            type: 'UPDATE_ENTRY',
            entry: entry
        })
    },
    entriesLoaded: (entriesLoaded: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({
            type: 'ENTRIES_LOADED',
            entriesLoaded: entriesLoaded
        })
    },
    clearEntries: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'CLEAR_ENTRIES' })
    },
    getEntryLocation: (entryId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'GET_ENTRY_LOCATION', entryId: entryId })
    }
}

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.
const unloaded: EntryState = {
    entriesPending: false,
    entries: <Entry[]>[],
    entriesLoaded: false
}

export const reducer: Reducer<EntryState> = (state: EntryState | undefined, incomingAction: Action): EntryState => {
    if (state === undefined) return unloaded
    const action = incomingAction as KnownAction

    switch (action.type) {
        case 'REQUEST_ENTRIES':
            return {
                ...state,
                entriesPending: true
            }
        case 'RECEIVE_ENTRIES':
            if (action.entries) {
                return {
                    ...state,
                    entriesPending: false,
                    entries: action.forceUpdate
                        ? [...action.entries]
                        : action.entries.map(x => {
                            let exisitng = state.entries.find(y => y.entryId == x.entryId)
                            if (exisitng && getLuxonTimeFromString(exisitng.lastMessageTimestamp) > getLuxonTimeFromString(x.lastMessageTimestamp)) {
                                return exisitng
                            } else {
                                return x
                            }
                        })
                }
            }
            return {
                ...state,
                entriesPending: false
            }
        case 'ENTRIES_LOADED':
            return state.entriesLoaded != action.entriesLoaded
                ? {
                    ...state,
                    entriesLoaded: action.entriesLoaded
                } : state
        case 'UPDATE_ENTRY':
            let e = [...state.entries.filter(x => x.entryId != action.entry.entryId)]

            return {
                ...state,
                entries: [...e, action.entry]
            }
        case 'CLEAR_ENTRIES':
            return {
                ...state,
                entries: []
            }
        case 'RECEIVE_ENTRIES_FAILED':
            return {
                ...state
            }
        case 'GET_ENTRY_LOCATION':
            let entry = state.entries.find(x => x.entryId == action.entryId)
            if (entry) {
                return {
                    ...state,
                    goToEntry: entry
                }
            }
            return state
        default:
            return state
    }
}
