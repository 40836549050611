import { Autocomplete, Box, Stack, TextField, Typography } from "@mui/material"
import { style } from "@mui/system"
import * as React from 'react'
import { CountryFlagIcon } from "../../../assets/Icons/CountryFlagIcon"
import { Country, countryList } from "../../@Global/CountryList"

interface Props {
    onChange: (code: string) => void
    value: string
    style?: React.CSSProperties
    required?: boolean
}

export const CountrySelect: React.FunctionComponent<Props> = (props) => {
    const { onChange, value, required } = props

    return <Autocomplete
        sx={{ ...style }}
        options={countryList}
        autoHighlight
        size={'small'}
        defaultValue={countryList.find(x => x.code == value)}
        getOptionLabel={(option: Country) => option.name}
        onChange={((x, v) => onChange(v?.code ?? ''))}
        renderOption={(props, option) => (
            <Box component="li" {...props}>
                <Stack
                    spacing={1}
                    alignItems='center'
                    direction='row'
                >
                    <CountryFlagIcon size={32} code={option.code} />
                    <Typography>{option.name}</Typography>
                </Stack>
            </Box>
        )}
        renderInput={(params) => (
            <TextField
                {...params}
                label='Country'
                placeholder='Choose a Country'
                required={required}
                InputLabelProps={{
                    shrink: true
                }}
                inputProps={{
                    ...params.inputProps
                }}
            />
        )}
    />
}